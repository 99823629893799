import { useContext, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'

import { ThemeContext } from '../context/ThemeContextProvider'
import BillySDK from '../sdk/sdk'

function usePlaceQuery(placeId, params = null) {
    const { setTheme } = useContext(ThemeContext)

    const {
        isLoading,
        error,
        isError,
        data: place,
    } = useQuery({
        retry: 2,
        refetchOnWindowFocus: true,
        refetchInterval: 60 * 3 * 1000,
        queryKey: ['place', { placeId }],
        enabled: !!placeId,
        queryFn: () => BillySDK.getPlaceById(placeId, params),
    })

    useEffect(() => {
        if (place?.data) {
            setTheme({ ...place.data.theming })
        }
    }, [place])

    return {
        isLoading,
        error,
        isError,
        place: place?.data,
    }
}

export default usePlaceQuery
