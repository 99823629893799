import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FaMinus, FaPlus } from 'react-icons/fa'
import classNames from 'classnames'
import { motion } from 'framer-motion'

import CircularButton from '../CircularButton/CircularButton'

import './quantityselector.scss'

interface IQuantitySelectorProperties {
    type: string
    amount: number
    range?: {
        min: number
        max: number
    }
    className?: string
    onQuantityChange?: (quantity: number) => void
}

const QuantitySelector: FC<IQuantitySelectorProperties> = ({ type, amount, range, className, onQuantityChange }) => {
    const { t } = useTranslation()
    return (
        <div
            className={classNames(
                'c-quantity-selector',
                {
                    'c-quantity-selector-small': type === 'small',
                    'c-quantity-selector-mini': type === 'mini',
                },
                className
            )}>
            <CircularButton
                aria-label={t('general.button.decrease.label')}
                type='button'
                className='c-button--amount'
                disabled={amount === (range?.min ?? 0)}
                onClick={() => {
                    onQuantityChange?.(-1)
                }}>
                <FaMinus />
            </CircularButton>

            <motion.p
                key={amount}
                initial={{ scale: 0.9 }}
                animate={{ scale: 1.15 }}>
                {Math.max(amount, range?.min ?? 0)}
            </motion.p>

            <CircularButton
                aria-label={t('general.button.increase.label')}
                type='button'
                disabled={amount >= (range?.max ?? Number.POSITIVE_INFINITY)}
                className='c-button--amount'
                onClick={() => {
                    onQuantityChange?.(1)
                }}>
                <FaPlus />
            </CircularButton>
        </div>
    )
}

export default QuantitySelector
