import { createContext, useState } from 'react'

export const DrawerMenuContext = createContext({
    drawerMenuOpen: undefined,
    setDrawerMenuOpen: () => undefined,
    headerRef: undefined,
    setHeaderRef: () => undefined,
})

function DrawerMenuContextProvider({ children }) {
    const [drawerMenuOpen, setDrawerMenuOpen] = useState(false)
    const [headerRef, setHeaderRef] = useState(false)

    return (
        <DrawerMenuContext.Provider value={{ drawerMenuOpen, setDrawerMenuOpen, headerRef, setHeaderRef }}>
            {children}
        </DrawerMenuContext.Provider>
    )
}

export default DrawerMenuContextProvider
