import { useQuery } from '@tanstack/react-query'

import BillySDK from '../sdk/sdk'
import { isNotNullOrUndefined } from '../structures/Guards/guards.utils.ts'

function useEventGroupQuery(eventGroupId) {
    const {
        isLoading,
        isError,
        data: eventGroup,
    } = useQuery({
        retry: 2,
        refetchOnWindowFocus: true,
        staleTime: 60 * 1000,
        refetchInterval: 60 * 3 * 1000,
        queryKey: ['eventGroup', { eventGroupId }],
        enabled: isNotNullOrUndefined(eventGroupId),
        queryFn: () => BillySDK.getEventGroupById(eventGroupId, null),
    })

    return {
        isLoading,
        isError,
        eventGroup: eventGroup?.data,
    }
}

export default useEventGroupQuery
