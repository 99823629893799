import { breadcrumbsIntegration, init } from '@sentry/react'

import { isNotNullOrUndefined } from '../../structures/Guards/guards.utils'

const initSentry = () => {
    init({
        dsn: import.meta.env.REACT_APP_SENTRY_DSN,
        environment: import.meta.env.REACT_APP_ENVIRONMENT,
        sampleRate: import.meta.env.REACT_APP_SENTRY_SAMPLERATE,
        ignoreErrors: [
            'top.GLOBALS',
            'NetworkError',
            'Network Error',
            'Request failed with status code 401',
            'Request failed with status code 404',
            'Request failed with status code 422',
            'Request failed with status code 403',
            'timeout of 0ms exceeded',
            'timeout of 60000ms exceeded',
            'Request aborted',
            "Unexpected token '<'",
            "Cannot read property 'style' of null",
            'Loading CSS chunk',
            'Unable to preload CSS',
            "Can't find variable: dpg_snowplow",
            "ReferenceError: Can't find variable: dpg_snowplow",
            'Uncaught ReferenceError: dpg_snowplow is not defined',
            'AxiosError: Request failed with status code 412',
            "SyntaxError: Failed to execute 'querySelector' on 'Document'",
        ],
        denyUrls: [/extensions\//i, /^chrome:\/\//i, /^chrome-extension:\/\//i],
        beforeSend(event, hint) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const error: any = hint.originalException

            if (
                isNotNullOrUndefined(error) &&
                isNotNullOrUndefined(error.message) &&
                (/network error/i.test(error.message) ||
                    /request failed with status code 401/i.test(error.message) ||
                    /request failed with status code 404/i.test(error.message) ||
                    /request failed with status code 422/i.test(error.message) ||
                    /request failed with status code 403/i.test(error.message) ||
                    /timeout of 0ms exceeded/i.test(error.message) ||
                    /dpg_snowplow/i.test(error.message) ||
                    /msdiscoverchatavailable/i.test(error.message) ||
                    /request aborted/i.test(error.message) ||
                    /unable to preload/i.test(error.message) ||
                    /axioserror: request failed with status code 412/i.test(error.message) ||
                    /syntaxerror: failed to execute 'queryselector' on 'document'/i.test(error.message) ||
                    /object captured as promise rejection with keys: message, response, status/i.test(error.message))
            ) {
                return null
            }

            return event
        },
        integrations: [
            breadcrumbsIntegration({
                // beacon: true,
                console: import.meta.env.REACT_APP_ENVIRONMENT !== 'local',
                dom: true,
                fetch: true,
                history: true,
                sentry: true,
                xhr: true,
            }),
        ],
    })
}

export default initSentry
