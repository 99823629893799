import { FC, ReactNode } from 'react'
import classNames from 'classnames'
import { motion, Variants } from 'framer-motion'

import './kioskinstructionspanel.scss'

interface IKioskInstructionsPanelProperties {
    children?: ReactNode | null
    className?: string
}

const KioskInstructionsPanel: FC<IKioskInstructionsPanelProperties> = ({ children = null, className }) => (
    <motion.div
        exit={{ y: 600 }}
        transition={
            {
                duration: 0.02,
                type: 'spring',
                dampening: 1050,
                stiffness: 90,
                mass: 0.2,
            } as unknown as Variants
        }
        className={classNames('c-kiosk-intro--trigger', className)}>
        <div>{children}</div>
    </motion.div>
)

export default KioskInstructionsPanel
