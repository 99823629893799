import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import AdditionValue from 'src/components/02_molecules/AdditionValue/AdditionValue'
import { EventContext } from 'src/context/EventContextProvider'
import { IAdditionValue, IChosenAdditions, ISelectedAddition } from 'src/hooks/useValidateAdditions'
import AdditionType from 'src/structures/Enums/AdditionType'
import CheckoutMethod from 'src/structures/Enums/CheckoutMethod.enum'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'
import IAddition from 'src/structures/Interfaces/IAddition'

import './comboproduct.scss'

interface IComboProductProperties {
    addition: IAddition
    isOrderable: boolean
    onValues: (values: IAdditionValue) => void
}

const ComboProduct: FC<IComboProductProperties> = ({ addition, isOrderable, onValues }) => {
    const { t } = useTranslation()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { event } = useContext(EventContext) as any

    const [values, setValues] = useState<ISelectedAddition>({ id: addition.id.toString(), values: [] })
    const [maxAdditionsAmountReached, setMaxAdditionsAmountReached] = useState(false)

    const totalSelectedAmounts = useMemo(
        () => values.values.reduce((totalAmount, value) => (totalAmount += value.amount), 0),
        [values, addition]
    )

    const isValid = useMemo(
        () => totalSelectedAmounts >= addition.selected_amount_min,
        [totalSelectedAmounts, addition.selected_amount_min]
    )

    useEffect(() => {
        onValues?.({ ...values, type: AdditionType.SUB_PRODUCT, isValid })
    }, [values, isValid, totalSelectedAmounts, addition, isValid])

    useEffect(() => {
        setMaxAdditionsAmountReached(
            totalSelectedAmounts >= (addition.selected_amount_max < 0 ? 500 : addition.selected_amount_max)
        )
    }, [totalSelectedAmounts, addition])

    const handleAdditionChange = useCallback(
        (productId: number, amount: number) => {
            setValues((previousState) => {
                if (!addition.multiselect) {
                    previousState.values = [{ id: productId, amount }]
                } else {
                    const optionIndex = previousState.values.findIndex((valueProduct) => valueProduct.id === productId)

                    if (optionIndex === -1 && !maxAdditionsAmountReached) {
                        previousState.values.push({ id: productId, amount })
                    } else {
                        previousState.values = previousState.values.filter(
                            (valueProduct) => valueProduct.id !== productId
                        )
                    }
                }

                return { ...previousState }
            })
        },
        [addition, maxAdditionsAmountReached]
    )

    const handleAdditionAmountChange = useCallback(
        (productId: number, amount: number) => {
            setValues((previousState) => {
                const update = previousState.values.find((value) => value.id.toString() === productId.toString())
                if (isNotNullOrUndefined(update)) {
                    update.amount = amount
                }

                return { ...previousState }
            })
        },
        [addition]
    )

    const handleAdditionAdditionsChange = useCallback(
        (productId: number, additions: IChosenAdditions) => {
            if (isNotNullOrUndefined(additions)) {
                setValues((previousState) => {
                    const update = previousState.values.find((value) => value.id.toString() === productId.toString())
                    if (isNotNullOrUndefined(update)) {
                        update.additions = additions
                    }

                    return { ...previousState }
                })
            }
        },
        [addition]
    )

    if (isNotNullOrUndefined(addition?.sub_products) && addition.sub_products.length > 0) {
        return (
            <div className='c-combo-products'>
                <div className='c-combo-products-header'>
                    <h3 className='c-combo-products-title'>{addition.name}</h3>
                    {isNotNullOrUndefined(addition.description) ? (
                        <p className='c-combo-products-description'>{addition.description}</p>
                    ) : null}
                    {isValid ? null : (
                        <p className='c-combo-products-error'>
                            {t('addition.minimum.error', { minimum: addition.selected_amount_min })}
                        </p>
                    )}
                </div>
                <div className={classNames('c-combo-products-items', { 'has-error': !isValid })}>
                    {addition.sub_products.map((product) => (
                        <AdditionValue
                            key={product.id}
                            value={product}
                            amount={values.values.find((item) => item.id === product.id)?.amount ?? 1}
                            active={isNotNullOrUndefined(values.values.find((item) => item.id === product.id)) ?? false}
                            maxAdditionsAmountReached={maxAdditionsAmountReached}
                            additionId={addition.id}
                            isMultiSelect={addition.multiselect}
                            multiMax={addition.multimax}
                            isOrderable={event?.checkout_method.id !== CheckoutMethod.MENU_ONLY && isOrderable}
                            onChange={handleAdditionChange}
                            onAmountChange={handleAdditionAmountChange}
                            onAdditionAdditionsChange={handleAdditionAdditionsChange}
                        />
                    ))}
                </div>
            </div>
        )
    }

    return null
}

export default ComboProduct
