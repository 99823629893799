import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { removePendingOrder, setPendingOrder } from '../redux/actions/actionBuilders'
import BillySDK from '../sdk/sdk'
import OrderableType from '../structures/Enums/OrderableType.enum.ts'

const usePendingOrder = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const queryClient = useQueryClient()
    const storedPendingOrder = useSelector((state) => state.orders)

    const cancelOrder = useMutation({
        retry: 0,
        refetchOnWindowFocus: false,
        onError: () => null,
        mutationFn: (orderId) => BillySDK.deleteOrderById(orderId),
    })

    const cancelOrderGroup = useMutation({
        retry: 0,
        refetchOnWindowFocus: false,
        onError: () => null,
        mutationFn: (orderGroupId) => BillySDK.deleteOrderGroupById(orderGroupId),
    })

    const set = (orderable) => {
        if (orderable) {
            dispatch(
                setPendingOrder({
                    id: orderable.id,
                    path: location.pathname,
                    eventId: orderable.event?.id ?? null,
                    type: orderable.type,
                })
            )
        }
    }

    const get = useCallback(() => storedPendingOrder.pendingOrder, [storedPendingOrder])
    const remove = useCallback(() => dispatch(removePendingOrder()), [dispatch, removePendingOrder])

    const cancelAndRemove = async () => {
        const order = get()
        if (order?.type === OrderableType.ORDER) {
            await cancelByOrderId(order.id)
        }
        if (order?.type === OrderableType.ORDER_GROUP) {
            await cancelByOrderGroupId(order.id)
        }
    }

    const cancelByOrderId = async (orderId) => {
        await cancelOrder
            .mutateAsync(orderId)
            .catch(() => {})
            .finally(() => {
                queryClient.removeQueries({ queryKey: ['order'], exact: true })
                queryClient.removeQueries({ queryKey: ['orderStatus'], exact: true })
                remove()
            })
    }

    const cancelByOrderGroupId = async (orderGroupId) => {
        await cancelOrderGroup
            .mutateAsync(orderGroupId)
            .catch(() => {})
            .finally(() => {
                queryClient.removeQueries({ queryKey: ['order'], exact: true })
                queryClient.removeQueries({ queryKey: ['orderStatus'], exact: true })
                remove()
            })
    }

    return {
        setPendingOrder: set,
        getPendingOrder: get,
        removePendingOrder: remove,
        cancelPendingOrderByOrderId: cancelByOrderId,
        cancelPendingOrderByOrderGroupId: cancelByOrderGroupId,
        cancelAndRemovePendingOrder: cancelAndRemove,
    }
}

export default usePendingOrder
