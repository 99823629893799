import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'src/components/01_atoms/Button/Button'
import PromotionProductInput from 'src/components/02_molecules/PromotionProductInput/PromotionProductInput'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'
import IProduct from 'src/structures/Interfaces/IProduct'

import './promotionproductselectform.scss'

interface IPromotionProductSelectFormProperties {
    id: number
    color?: string | undefined | null
    promoProducts: Array<IProduct>
    multiSelect: boolean
    multiMax: number
    initialPromoProductValue: number | null | undefined
    onSubmit: (values: ISelectedPromotionProducts) => void
}

export interface ISelectedPromotionProduct {
    id: number
    amount: number
}
export type ISelectedPromotionProducts = Array<ISelectedPromotionProduct>

const PromotionProductSelectForm: FC<IPromotionProductSelectFormProperties> = ({
    id,
    color,
    promoProducts,
    multiSelect = false,
    multiMax = 1,
    initialPromoProductValue,
    onSubmit = () => {},
}) => {
    const { t } = useTranslation()
    const [isSubmitting] = useState(false)

    const defaultValues: ISelectedPromotionProducts = useMemo(() => {
        if (isNotNullOrUndefined(initialPromoProductValue)) {
            return [{ id: initialPromoProductValue, amount: 1 }]
        }
        if (
            promoProducts.length === 1 &&
            isNotNullOrUndefined(promoProducts) &&
            isNotNullOrUndefined(promoProducts[0]?.id)
        ) {
            return [{ id: promoProducts[0]?.id, amount: 1 }]
        }
        return []
    }, [initialPromoProductValue, promoProducts])

    const [values, setValues] = useState<ISelectedPromotionProducts>(defaultValues)

    const [amountReached, setAmountReached] = useState(false)

    const totalSelectedAmounts = useMemo(
        () => values.reduce((totalAmount, value) => (totalAmount += value.amount), 0),
        [values]
    )

    const handleChange = useCallback((productId: number) => {
        setValues((previousState) => {
            if (!multiSelect) {
                previousState = [{ id: productId, amount: 1 }]
            } else {
                const optionIndex = previousState.findIndex((value) => value.id === productId)
                if (optionIndex === -1 && !amountReached) {
                    previousState.push({ id: productId, amount: 1 })
                } else {
                    previousState = previousState.filter((value) => value.id !== productId)
                }
            }

            return [...previousState]
        })
    }, [])

    const handleAmountChange = useCallback((valueId: number, amount: number) => {
        setValues((previousState) => {
            const update = previousState.find((value) => value.id === valueId)
            if (isNotNullOrUndefined(update)) {
                update.amount = amount
            }

            return [...previousState]
        })
    }, [])

    const handleSubmit = useCallback(() => {
        onSubmit?.(values)
    }, [onSubmit, values])

    useEffect(() => {
        setAmountReached(totalSelectedAmounts >= multiMax)
    }, [totalSelectedAmounts, multiMax])

    return (
        <div className='c-promo-product-select-container'>
            <div className='c-promo-products'>
                {promoProducts.map((promoProduct) => (
                    <PromotionProductInput
                        key={promoProduct.id}
                        value={{ name: promoProduct.name, id: promoProduct.id }}
                        isMultiSelect={multiSelect}
                        promotionId={id ?? 1}
                        multiMax={multiMax}
                        maxAmountReached={amountReached}
                        onChange={handleChange}
                        onAmountChange={handleAmountChange}
                        amount={values.find((item) => item.id === promoProduct.id)?.amount ?? 1}
                        active={isNotNullOrUndefined(values.find((item) => item.id === promoProduct.id)) ?? false}
                    />
                ))}
            </div>
            <div>
                <Button
                    onClick={handleSubmit}
                    type='button'
                    disabled={isSubmitting || values.length === 0 || !amountReached}
                    variant='dark'
                    style={isNotNullOrUndefined(color) ? { background: color } : {}}
                    label={
                        isSubmitting
                            ? t('button.form.disabled.cta')
                            : t('promotions.add_to_basket.cta', 'Add to basket')
                    }
                />
            </div>
        </div>
    )
}

export default PromotionProductSelectForm
