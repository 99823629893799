import { CLOSE_COOKIES, OPEN_COOKIES } from '../actions/cookiesModalActions'

export default function cookiesReducer(
    state = {
        isOpen: false,
    },
    { type }
) {
    switch (type) {
        case OPEN_COOKIES:
            state = {
                isOpen: true,
            }
            return state

        case CLOSE_COOKIES:
            state = {
                isOpen: false,
            }
            return state

        default:
            return state
    }
}
