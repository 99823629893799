import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

function Language({ onClick, language }) {
    const { i18n } = useTranslation()

    const handleClick = useCallback(() => {
        onClick(language.lang)
    }, [onClick, language])

    return (
        <button
            className={classNames('c-language', { 'c-language--active': i18n.languages[0] === language.lang })}
            onClick={handleClick}>
            <span>{language.lang}</span>
        </button>
    )
}

export default Language
