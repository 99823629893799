import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { NumberFormatter } from 'src/constants/helpers'
import { isNotNullOrUndefined, isNullOrEmpty } from 'src/structures/Guards/guards.utils'

import { EventContext } from '../../context/EventContextProvider'

import './currency.scss'

interface ICurrencyProperties {
    currencyCode?: string | null
    currencySymbol?: string | null
    amount: number
    className?: string
}

const Currency: FC<ICurrencyProperties> = ({ amount, currencyCode = null, currencySymbol = null, className }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { event } = useContext<any>(EventContext)
    const { t, i18n } = useTranslation()

    let currency = event?.currency_code ?? 'EUR'
    let symbol = event?.currency_symbol_url ?? null

    if (!isNullOrEmpty(currencyCode)) {
        currency = currencyCode
        symbol = currencySymbol
    }

    if (isNotNullOrUndefined(symbol) && isNotNullOrUndefined(currency) && currency === 'XXX') {
        return (
            <div className={classNames('c-currency', className)}>
                <img
                    src={symbol}
                    alt={t('currency.custom.image.alt')}
                />
                <span>{NumberFormatter.customCurrency(i18n.languages[0]).format(amount)}</span>
            </div>
        )
    }

    return (
        <div className={classNames('c-currency', className)}>
            <span>{NumberFormatter.currency(currency, i18n.languages[0]).format(amount)}</span>
        </div>
    )
}

export default Currency
