import { FC } from 'react'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

import './producttags.scss'

interface IProductTagsProperties {
    tags: Array<string> | null
}

const ProductTags: FC<IProductTagsProperties> = ({ tags = null }) => {
    if (isNotNullOrUndefined(tags) && tags.length > 0) {
        return (
            <div className='c-product-tags'>
                {tags.map((tag, index) => (
                    <div
                        className='c-product-tag'
                        key={`${tag}-${index}`}>
                        <div className='c-product-tag-inner'>{tag}</div>
                    </div>
                ))}
            </div>
        )
    }

    return null
}

export default ProductTags
