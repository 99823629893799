import { Component } from 'react'
import { BiErrorCircle } from 'react-icons/bi'
import { captureException } from '@sentry/react'

import withRedux from '../../redux/withRedux'
import RoutePath from '../../structures/Enums/RoutePath.enum.ts'
import Container from '../03_organisms/Container/Container.tsx'

import './errorboundary.scss'

const mapStateToProps = (state) => {
    const kiosk = state.kiosk
    return { kiosk }
}
class ErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hasError: false,
        }

        this.recoverPath = RoutePath.HOMEPAGE
        if (props.kiosk?.kioskMode === true) {
            this.recoverPath = props.kiosk.token ? `/kiosk/${props.kiosk.token}` : RoutePath.HOMEPAGE
        }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error }
    }

    componentDidCatch(error) {
        if (error.name !== 'ChunkLoadError') {
            captureException(error, {
                tags: {
                    level: 'critical',
                    initiator: 'Error Boundary',
                },
            })
        }

        window.scrollTo(0, 0)
    }

    render() {
        const { error, hasError } = this.state

        if (hasError && error && error.name === 'ChunkLoadError') {
            return (
                <Container
                    id='error-boundary-container'
                    fullWidth
                    animated={false}>
                    <section className='error-boundary'>
                        <div className='error-boundary-header'>
                            <img
                                alt='Logo'
                                src='/brand/billy/billy_big.svg'
                            />

                            <h3>has been updated!</h3>
                        </div>

                        <div className='error-boundary-content'>
                            <h4>Please refresh to see the latest version.</h4>
                            <button
                                onClick={() => window.location.reload()}
                                className='c-button c-button-dark'>
                                Refresh
                            </button>
                        </div>
                    </section>
                </Container>
            )
        }

        if (hasError && error) {
            return (
                <Container
                    id='error-boundary-container'
                    fullWidth
                    animated={false}>
                    <section className='error-boundary'>
                        <div className='error-boundary-header severe'>
                            <div className='icon'>
                                <BiErrorCircle />
                            </div>
                            <h3>Something went terribly wrong</h3>
                        </div>

                        <div className='error-boundary-content'>
                            <h4>You just encounterd an error, no worries, our team is looking in to it!</h4>
                            <button
                                onClick={() => {
                                    window.location.href = this.recoverPath
                                }}
                                className='c-button c-button-dark'>
                                Go back
                            </button>
                        </div>
                    </section>
                </Container>
            )
        }

        return this.props.children
    }
}

export default withRedux(ErrorBoundary, mapStateToProps)
