import { FC } from 'react'
import StrikeThrough from 'src/components/common/StrikeThrough'
import Currency from 'src/components/currency/Currency'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

interface IProductPriceProperties {
    price: number
    compareAtPrice?: number
}

const ProductPrice: FC<IProductPriceProperties> = ({ price, compareAtPrice = null }) => (
    <>
        {isNotNullOrUndefined(compareAtPrice) && !Number.isNaN(compareAtPrice) ? (
            <StrikeThrough>
                <Currency amount={compareAtPrice} />
            </StrikeThrough>
        ) : null}
        <Currency amount={price} />
    </>
)

export default ProductPrice
