import { useCallback, useState } from 'react'
import AdditionType from 'src/structures/Enums/AdditionType'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'
import IAddition from 'src/structures/Interfaces/IAddition'

export interface ISelectedAdditionValue {
    id: number
    amount: number
    additions?: IChosenAdditions
}

export interface ISelectedAddition {
    id: string
    values: Array<ISelectedAdditionValue>
}

export interface IAdditionValue extends ISelectedAddition {
    isValid: boolean
    type: AdditionType
}

export interface ISelectedAdditions {
    isValid: boolean
    values: Array<IAdditionValue>
}

export interface IChosenAdditionValue {
    id: number
    name: string
    price_int: number
    amount: number
    additions?: IChosenAdditions
}
export interface IChosenAdditions {
    [key: string]: {
        addition: {
            name: string
            type: AdditionType
        }
        values: Array<IChosenAdditionValue>
    }
}

const useValidateAdditions = (productAdditions: Array<IAddition> | undefined) => {
    const [isValid, setIsValid] = useState<boolean>(true)
    const [totalAdditionsPrice, setTotalAdditionsPrice] = useState<number>(0)
    const [selectedAdditions, setSelectedAdditions] = useState<IChosenAdditions>({})

    const validateAdditions = useCallback(
        (values: ISelectedAdditions) => {
            if (!isNotNullOrUndefined(values) || !isNotNullOrUndefined(productAdditions)) return {}

            const { isValid: valuesAreValid, values: chosenAdditions } = values
            let totalPrice = 0

            setIsValid(valuesAreValid)

            const chosenProductAdditions = productAdditions.reduce((accumulator, addition) => {
                const chosenAddition = chosenAdditions.find((group) => group.id.toString() === addition.id.toString())

                if (isNotNullOrUndefined(chosenAddition)) {
                    const additionValuesOrSubProducts =
                        chosenAddition.type === AdditionType.ADDITION
                            ? addition.values ?? []
                            : addition.sub_products ?? []

                    const additionValues = additionValuesOrSubProducts.reduce((itemAccumulator, value) => {
                        const chosenValue = chosenAddition.values.find(
                            (chosenGroupValue) => chosenGroupValue.id.toString() === value.id.toString()
                        )
                        if (isNotNullOrUndefined(chosenValue)) {
                            totalPrice += value.price_int * (chosenValue.amount ?? 1)

                            if (isNotNullOrUndefined(chosenValue?.additions)) {
                                for (const key in chosenValue.additions) {
                                    if (
                                        isNotNullOrUndefined(chosenValue.additions) &&
                                        (chosenValue?.additions?.[key]?.values?.length ?? 0) > 0
                                    ) {
                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                        for (const item of chosenValue.additions[key]!.values ?? []) {
                                            totalPrice += (item.price_int ?? 0) * (item.amount ?? 1)
                                        }
                                    }
                                }
                            }

                            itemAccumulator.push({
                                ...value,
                                amount: chosenValue.amount,
                                additions: chosenValue.additions,
                            })
                        }
                        return itemAccumulator
                    }, [] as Array<IChosenAdditionValue>)

                    accumulator[addition.id] = {
                        addition: { name: addition.name, type: chosenAddition.type },
                        values: additionValues,
                    }
                }

                return accumulator
            }, {} as IChosenAdditions)

            setTotalAdditionsPrice(totalPrice)
            setSelectedAdditions(chosenProductAdditions)
            return chosenProductAdditions
        },
        [productAdditions]
    )

    return {
        isValid,
        totalAdditionsPrice,
        selectedAdditions,
        validateAdditions,
    }
}

export default useValidateAdditions
