import billySDK from '../../sdk/sdk'
import { UPDATE_TOKENS } from '../actions/tokenActions'

export default function tokensReducer(state = {}, { type, payload }) {
    switch (type) {
        case UPDATE_TOKENS:
            const axiosInstance = billySDK.getAxiosInstance()
            if (payload.access_token !== undefined) {
                axiosInstance.defaults.headers.common = Object.assign(axiosInstance.defaults.headers.common, {
                    Authorization: `Bearer ${payload.access_token}`,
                })
            } else {
                delete axiosInstance.defaults.headers.common.Authorization
            }

            return { ...state, access_token: payload.access_token }
        default:
            return state
    }
}
