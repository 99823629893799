import { useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { motion } from 'framer-motion'

import QrScanhelp from '../../../components/QrScanHelp/QrScanhelp'
import { popIn } from '../../../constants/Animations.ts'
import { KioskContext } from '../../../context/KioskContextProvider'
import usePayment from '../../../hooks/usePayment.ts'
import { closeModal, openModal } from '../../../redux/actions/actionBuilders'
import ModalType from '../../../structures/Enums/ModalType.ts'
import OrderableType from '../../../structures/Enums/OrderableType.enum.ts'

import './modal.scss'

function PayByQrCodeModal({ data }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { setIsIdleTimerEnabled } = useContext(KioskContext)
    const { cancelPayment } = usePayment(data?.orderId ?? null)

    const onCancelPayment = useCallback(async () => {
        await cancelPayment(data.orderType ?? OrderableType.ORDER)
            .then(async () => dispatch(closeModal()))
            .catch(() =>
                dispatch(
                    openModal({
                        type: ModalType.ERROR_MODAL,
                    })
                )
            )
    }, [data?.orderId, data?.orderType])

    useEffect(() => {
        setIsIdleTimerEnabled(false)
        return () => setIsIdleTimerEnabled(true)
    }, [])

    return (
        <>
            <motion.div
                className='c-modal--item'
                variants={popIn}
                initial='hidden'
                animate='visible'
                exit='exit'>
                <div className='c-modal--item-content c-modal--pay-with-qr'>
                    <img
                        className='qr-code'
                        src={data.qrUrl}
                    />
                    <QrScanhelp
                        label={t('kiosk.scanhelp')}
                        className='qr-help'
                    />
                </div>
            </motion.div>
            <button
                onClick={onCancelPayment}
                className='qr-cancel-payment'>
                {t('button.change_payment_method.cta')}
            </button>
        </>
    )
}

export default PayByQrCodeModal
