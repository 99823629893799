import { createContext, useState } from 'react'

export const HeaderContext = createContext()

function HeaderContextProvider({ children }) {
    const [isHeaderHidden, setIsHeaderHidden] = useState(false)
    const [isBackButtonHidden, setIsBackButtonHidden] = useState(false)
    const [isHeaderDisabled, setIsHeaderDisabled] = useState(false)

    return (
        <HeaderContext.Provider
            value={{
                isHeaderHidden,
                setIsHeaderHidden,
                isBackButtonHidden,
                setIsBackButtonHidden,
                isHeaderDisabled,
                setIsHeaderDisabled,
            }}>
            {children}
        </HeaderContext.Provider>
    )
}

export default HeaderContextProvider
