import { FC, useCallback } from 'react'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import Additions from 'src/components/03_organisms/Additions/Additions'
import useValidateAdditions, { IChosenAdditions, ISelectedAdditions } from 'src/hooks/useValidateAdditions'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'
import IAdditionValue from 'src/structures/Interfaces/IAdditionValue'

import AdditionValueLabel from '../../01_atoms/AdditionValueLabel/AdditionValueLabel'
import AdditionValueQuantitySelector from '../../01_atoms/AdditionValueQuantitySelector/AdditionValueQuantitySelector'

import './additionvalue.scss'

interface IAdditionValueProperties {
    value: IAdditionValue
    amount: number
    isOrderable: boolean
    isMultiSelect: boolean
    additionId: number
    multiMax: number
    maxAdditionsAmountReached: boolean
    active: boolean
    onChange: (valueId: number, amount: number) => void
    onAmountChange?: (valueId: number, amount: number) => void
    onAdditionAdditionsChange?: (valueId: number, additions: IChosenAdditions) => void
}

const AdditionValue: FC<IAdditionValueProperties> = ({
    maxAdditionsAmountReached,
    isOrderable,
    value,
    additionId,
    multiMax,
    isMultiSelect = false,
    active,
    onChange,
    onAmountChange,
    onAdditionAdditionsChange,
    amount = 1,
}) => {
    const disabled = !active && maxAdditionsAmountReached && isMultiSelect

    const { validateAdditions } = useValidateAdditions(value.additions)

    const handleChange = useCallback(() => {
        onChange(value.id, 1)
    }, [onChange, value.id])

    const handleAmountChange = useCallback(
        (newAmount: number) => {
            onAmountChange?.(value.id, Number.isNaN(newAmount) ? 1 : newAmount)
        },
        [onAmountChange, value.id]
    )

    const handleAdditionsChange = useCallback(
        (additionsState: ISelectedAdditions) => {
            if (isNotNullOrUndefined(additionsState)) {
                // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
                onAdditionAdditionsChange?.(value.id, validateAdditions(additionsState))
            }
        },
        [onAdditionAdditionsChange, value.id, validateAdditions]
    )

    if (!isOrderable) {
        return (
            <div
                key={value.id}
                className='c-product--addition-value'>
                <AdditionValueLabel value={value} />
            </div>
        )
    }

    return (
        <div
            key={value.id}
            className={classNames('c-product--addition-value', {
                'is-active': active,
                'is-disabled': disabled,
            })}>
            <div>
                <div className='c-product--addition-value-container'>
                    <div className='c-product--addition-value-inner'>
                        <label htmlFor={`${additionId.toString()}-${value.id.toString()}`}>
                            <AdditionValueLabel value={value} />

                            <input
                                id={`${additionId.toString()}-${value.id.toString()}`}
                                type={!isMultiSelect ? 'radio' : 'checkbox'}
                                name={!isMultiSelect ? additionId.toString() : value.id.toString()}
                                onChange={handleChange}
                                checked={active}
                                disabled={disabled}
                            />
                            <label className={!isMultiSelect ? 'radio' : 'checkbox'} />
                        </label>
                    </div>
                    {multiMax > 1 && active ? (
                        <AdditionValueQuantitySelector
                            range={{
                                min: 1,
                                max: maxAdditionsAmountReached ? 1 : multiMax,
                            }}
                            value={amount}
                            onChange={handleAmountChange}
                        />
                    ) : null}
                </div>
                <AnimatePresence>
                    {active &&
                    'additions' in value &&
                    isNotNullOrUndefined(value.additions) &&
                    value.additions.length > 0 ? (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0, height: 0 }}
                            className='c-product-combo-additions'>
                            <Additions
                                isOrderable
                                additions={value.additions}
                                onAdditionsChange={handleAdditionsChange}
                            />
                        </motion.div>
                    ) : null}
                </AnimatePresence>
            </div>
        </div>
    )
}

export default AdditionValue
