import classNames from 'classnames'

import { BRAND_BILLY, BRAND_TADA } from '../../structures/Enums/Brands.enum.ts'

import './poweredby.scss'

function PoweredBy({ brandId = BRAND_BILLY.identifier, isStaticPositioned = false }) {
    let poweredByImagePath = '/brand/billy/powered_by_billy.svg'
    if (brandId === BRAND_TADA.identifier) poweredByImagePath = '/brand/tada/powered_by_tada.svg'

    return (
        <div className={classNames('c-powered-by-branding', { 'is-static': isStaticPositioned })}>
            <img src={poweredByImagePath} />
        </div>
    )
}

export default PoweredBy
