import { FC, ReactNode } from 'react'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

import './modalcontrols.scss'

interface IModalControlsProperties {
    children: ReactNode
    alert?: string | null
}

const ModalControls: FC<IModalControlsProperties> = ({ children, alert }) => (
    <div className='modalcontrols'>
        {isNotNullOrUndefined(alert) ? (
            <div className='modalcontrols-alert'>
                <span>{alert}</span>
            </div>
        ) : null}
        <div className='modalcontrols-inner'>{children}</div>
    </div>
)

export default ModalControls
