import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { motion } from 'framer-motion'

import { popIn } from '../../../constants/Animations.ts'
import * as actionBuilders from '../../../redux/actions/actionBuilders'
import withRedux from '../../../redux/withRedux'

import './modal.scss'

const mapStateToProps = () => ({})

class ErrorModal extends Component {
    constructor(props) {
        super(props)
        this.onConfirm = this.onConfirm.bind(this)
    }

    onConfirm() {
        if (this.props.data && this.props.data.onConfirm) this.props.data.onConfirm()
        this.props.closeModal()
    }

    render() {
        return (
            <motion.div
                className='c-modal--item'
                variants={popIn}
                initial='hidden'
                animate='visible'
                exit='exit'>
                <div className='c-modal--item-content c-modal--error'>
                    <h3
                        className='c-modal--item-name'
                        style={{ marginTop: '10px' }}>
                        {this.props.data && this.props.data.title
                            ? this.props.data.title
                            : this.props.t('error.fatal.default.title')}
                    </h3>
                    <p className='c-modal--item-text'>
                        {this.props.data && this.props.data.message
                            ? this.props.data.message
                            : this.props.t('error.fatal.default.body')}
                    </p>
                    <button
                        className='c-button c-button-dark'
                        style={{ marginBottom: 0 }}
                        onClick={this.onConfirm}>
                        OK
                    </button>
                </div>
            </motion.div>
        )
    }
}

export default withTranslation()(withRedux(ErrorModal, mapStateToProps, actionBuilders))
