import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { FaTimes } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

import { useScreenHeight } from '../../../components/common/withScreenHeight'
import StarnetCard from '../../../components/StarnetCard/StarnetCard'
import { slideInBottom } from '../../../constants/Animations.ts'
import { closeModal } from '../../../redux/actions/actionBuilders'

import './modal.scss'

function StarnetPaymentModal({ data }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const screenHeight = useScreenHeight()

    const { t } = useTranslation()

    const onSuccess = useCallback((cardnumber) => {
        data.onSuccess?.(cardnumber)
        dispatch(closeModal())
    }, [])

    const onTopUpCard = useCallback((cardNumber) => {
        if (data.order?.id) {
            dispatch(closeModal())
            navigate(`/starnettopups?orderref=${data.order.id}&cardtype=starnet&cardnumber=${cardNumber}`, {
                state: { from: `/orders/${data.order.id}` },
            })
        }
    }, [])

    return (
        <motion.div
            layout='position'
            style={{ maxHeight: `${parseInt(screenHeight * 0.9, 10)}px` }}>
            <motion.div
                className='c-modal--item c-modal--cashless'
                variants={slideInBottom}
                initial='hidden'
                animate='visible'
                exit='exit'>
                <div className='c-fake-modal-backdrop'>
                    <div className='c-close-modal-header'>
                        <button
                            aria-label={t('general.button.close.label')}
                            onClick={() => dispatch(closeModal())}
                            className='c-button-close-modal-small'>
                            <FaTimes />
                        </button>
                    </div>
                    <motion.div className='c-modal--item-content'>
                        <StarnetCard
                            order={data.order}
                            venueId={data.venueId}
                            onSuccess={onSuccess}
                            onTopUpCard={onTopUpCard}
                        />
                    </motion.div>
                </div>
            </motion.div>
        </motion.div>
    )
}

export default StarnetPaymentModal
