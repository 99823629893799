import { useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { useFormikContext } from 'formik'

import InputFeedback from '../../elements/InputFeedback'
import InputHelp from '../../elements/InputHelp'

import './codeinput.scss'

function CodeInput({
    field: { name, value, onChange, onBlur },
    form: { errors, touched },
    id,
    disabled,
    className,
    fields = 4,
    label,
    feedbackStyle,
    description,
    autoFocus = true,
    codeType = 'numeric',
    autoComplete = 'one-time-code',
    onIsValidCode = () => undefined,
}) {
    const BACKSPACE_KEY = 8
    const E_KEY = 69

    const inputs = []
    const inputRefs = useRef([])
    const formik = useFormikContext()
    const [fullCodeValue, setFullCodeValue] = useState('')

    const onHandleKeyUp = useCallback((e) => {
        const target = parseInt(e.target.getAttribute('id'), 10)
        const prevTarget = inputRefs.current[target - 1]

        if (e.keyCode === E_KEY && codeType === 'numeric') {
            e.preventDefault()
            return false
        }

        if (e.keyCode === BACKSPACE_KEY) {
            if (prevTarget) {
                prevTarget.focus()
                prevTarget.select()
            }
        }
    }, [])

    const onHandleChange = useCallback(
        (e) => {
            const target = inputRefs.current[parseInt(e.target.getAttribute('id'), 10)]
            const nextTarget = inputRefs.current[parseInt(e.target.getAttribute('id'), 10) + 1]
            let value = String(e.target.value)

            if (codeType === 'numeric') {
                value = value.replace(/[^\d]/g, '')
            }

            if (target) {
                target.value = value
            }

            if (value !== '' && nextTarget) {
                nextTarget.focus()
                nextTarget.select()
            }

            const fullValue = []
            inputRefs.current.forEach((item) => {
                fullValue.push(item.value)
            })
            setFullCodeValue(fullValue.join(''))
        },
        [inputRefs]
    )

    if (!Number.isNaN(parseInt(fields, 10))) {
        for (let i = 0; i < parseInt(fields, 10); i += 1) {
            inputs.push(
                <input
                    ref={(el) => (inputRefs.current[i] = el)}
                    key={i}
                    id={i}
                    disabled={disabled}
                    name={`input-code-field-${i}`}
                    type='text'
                    onChange={onHandleChange}
                    onKeyUp={onHandleKeyUp}
                    className={classNames('c-code-field', className)}
                    autoComplete={i === 0 ? autoComplete : 'off'}
                    maxLength='1'
                    inputMode='numeric'
                />
            )
        }
    }

    useEffect(() => {
        if (inputRefs?.current?.length > 0 && autoFocus === true) {
            inputRefs.current[0].focus()
        }
    }, [inputRefs, autoFocus])

    useEffect(() => {
        if (parseInt(fullCodeValue?.length, 10) === parseInt(fields, 10)) {
            formik.setFieldValue(name, fullCodeValue)
            onIsValidCode(true)
        } else {
            onIsValidCode(false)
        }
    }, [fullCodeValue])

    return (
        <div className='c-code-input-wrapper'>
            {label ? <label>{label}</label> : null}
            <div className='c-code-input'>{inputs.map((input) => input)}</div>

            <input
                id={id}
                value={value || ''}
                disabled={disabled}
                name={name}
                type='hidden'
                className={classNames('c-code-field', className)}
                autoComplete={autoComplete}
                maxLength={String(fields)}
                inputMode='text'
                onChange={onChange}
                onBlur={onBlur}
            />

            {touched[name] ? (
                <div style={feedbackStyle}>
                    <InputFeedback error={errors[name]} />
                </div>
            ) : null}

            {description ? <InputHelp content={description} /> : null}
        </div>
    )
}

export default CodeInput
