import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { motion } from 'framer-motion'

import { popIn } from '../../../constants/Animations.ts'
import * as actionBuilders from '../../../redux/actions/actionBuilders'
import withRedux from '../../../redux/withRedux'

import './modal.scss'

const mapStateToProps = () => ({})

class ConfirmModal extends Component {
    constructor(props) {
        super(props)
        this.onConfirm = this.onConfirm.bind(this)
        this.onReject = this.onReject.bind(this)
    }

    onConfirm() {
        if (this.props.data.onConfirm) this.props.data.onConfirm()
        this.props.closeModal()
    }

    onReject() {
        if (this.props.data.onReject) this.props.data.onReject()
        this.props.closeModal()
    }

    render() {
        return (
            <motion.div
                className='c-modal--item'
                variants={popIn}
                initial='hidden'
                animate='visible'
                exit='exit'>
                <div className='c-modal--item-content c-modal--error'>
                    <h3 className='c-modal--item-name'>
                        {this.props.data.title ? this.props.data.title : this.props.t('modal.confirm.title')}
                    </h3>
                    {this.props.data.message && <p className='c-modal--item-text'>{this.props.data.message}</p>}
                    <div className='c-modal--confirm-buttons'>
                        <button
                            className='c-button c-button-dark'
                            onClick={this.onReject}>
                            {this.props.t('modal.confirm.answer.no')}
                        </button>
                        <button
                            className='c-button c-button-dark'
                            onClick={this.onConfirm}>
                            {this.props.t('modal.confirm.answer.yes')}
                        </button>
                    </div>
                </div>
            </motion.div>
        )
    }
}

export default withTranslation()(withRedux(ConfirmModal, mapStateToProps, actionBuilders))
