import Recommendation from './Recommendation'

import './recommendations.scss'

function Recommendations({ recommendations, onRecommendationClick }) {
    if (recommendations?.length > 0) {
        return recommendations.map((group, index) => (
            <div
                key={index}
                className='c-recommendation-group'>
                <div className='c-recommendation-group-header'>
                    {group.name && <h3 className='c-recommendation-group-title'>{group.name}</h3>}
                    {group.description && <p className='c-recommendation-group-description'>{group.description}</p>}

                    <div className='c-recommendation-list'>
                        {group.products?.map((product, _index) => (
                            <Recommendation
                                key={_index}
                                product={product}
                                onRecommendationClick={onRecommendationClick}
                            />
                        ))}
                    </div>
                </div>
            </div>
        ))
    }
    return null
}

export default Recommendations
