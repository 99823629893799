import { Suspense, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, useFormikContext } from 'formik'

import { UserContext } from '../../../../context/UserAndTokenContextProvider'
import lazyImport from '../../../00_fundament/LazyImport.ts'

const PhoneInputField = lazyImport(async () => import('../../elements/PhoneInputField'))

function PhoneField({ label, disabled, defaultCountry = 'BE' }) {
    const { user } = useContext(UserContext)
    const formik = useFormikContext()
    const { t } = useTranslation()

    return (
        <Suspense fallback={<div />}>
            <Field
                component={PhoneInputField}
                name='phone'
                id='phone'
                type='tel'
                label={label ?? (user.phone ? t('input.phone.label') : t('input.phone.label_extended'))}
                defaultCountry={defaultCountry}
                placeHolder={t('input.phone.placeholder')}
                disabled={formik.isSubmitting || disabled}
                className='c-input-phone'
            />
        </Suspense>
    )
}

export default PhoneField
