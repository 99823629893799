import { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaTimes } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { motion } from 'framer-motion'

import Confetti from '../../../components/01_atoms/Confetti/Confetti.tsx'
import PromotionProductSelectForm from '../../../components/03_organisms/PromotionProductSelectForm/PromotionProductSelectForm.tsx'
import ScrollPane from '../../../components/common/ScrollPane'
import { useScreenHeight } from '../../../components/common/withScreenHeight'
import PromoRegisterForm from '../../../components/forms/PromoRegisterForm'
import PromoValidationForm from '../../../components/forms/PromoValidationForm'
import PoweredBy from '../../../components/PoweredBy/PoweredBy'
import { slideInBottom } from '../../../constants/Animations.ts'
import { UserContext } from '../../../context/UserAndTokenContextProvider'
import useTimeout from '../../../hooks/useTimeout'
import { closeModal } from '../../../redux/actions/actionBuilders'
import { BRAND_TADA } from '../../../structures/Enums/Brands.enum.ts'

import './modal.scss'

function PromoModal({ data }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { user } = useContext(UserContext)
    const screenHeight = useScreenHeight()

    const [exploding, setExploding] = useState(false)
    const [otpGenerated, setOtpGenerated] = useState(false)

    const [chosenPromoProducts, setChosenPromoProducts] = useState()

    const isMultiSelect =
        typeof data.promo.rules?.[0]?.config.requiresIdenticalProducts === 'boolean'
            ? !data.promo.rules?.[0]?.config.requiresIdenticalProducts
            : false

    const onClose = useCallback(() => {
        dispatch(closeModal())
    }, [])

    const onSuccessfulGenerate = useCallback((chosenProducts) => {
        setChosenPromoProducts(chosenProducts)
        setOtpGenerated(true)
    }, [])

    const onSuccessfulVerification = useCallback(() => {
        setExploding(true)
    }, [])

    useTimeout(() => data?.onSuccess?.(chosenPromoProducts), exploding ? 1800 : null)

    const renderPromoForm = useCallback(() => {
        if (
            data.promo.promotionSource.verification_type === 'otp' &&
            (user?.phoneVerified === null || typeof user?.phoneVerified === 'undefined')
        ) {
            if (otpGenerated) {
                return (
                    <PromoValidationForm
                        onSuccessfulVerify={onSuccessfulVerification}
                        color={data.promo.color}
                    />
                )
            }
            return (
                <PromoRegisterForm
                    id={data.promo.id}
                    onSuccessfulGenerate={onSuccessfulGenerate}
                    color={data.promo.color}
                    promoProducts={data.promo.products}
                    initialPromoProductValue={data.defaultPromoProductId}
                    multiSelect={isMultiSelect}
                    multiMax={
                        data.promo.rules?.[0]?.config?.requiresIdenticalProducts === true
                            ? 1
                            : data.promo.rules?.[0]?.config?.n ?? 1
                    }
                />
            )
        }

        return (
            <PromotionProductSelectForm
                id={data.promo.id}
                color={data.promo.color}
                promoProducts={data.promo.products}
                initialPromoProductValue={data.defaultPromoProductId}
                multiSelect={isMultiSelect}
                multiMax={
                    data.promo.rules?.[0]?.config?.requiresIdenticalProducts === true
                        ? 1
                        : data.promo.rules?.[0]?.config?.n ?? 1
                }
                onSubmit={(products) => {
                    setChosenPromoProducts(products)
                    setExploding(true)
                }}
            />
        )
    }, [data, otpGenerated, exploding])

    if (data?.promo) {
        return (
            <div style={{ maxHeight: `${parseInt(screenHeight * 0.9, 10)}px` }}>
                <motion.div
                    className='c-modal--item c-modal--promo'
                    variants={slideInBottom}
                    initial='hidden'
                    animate='visible'
                    exit='exit'>
                    <div className='c-fake-modal-backdrop'>
                        <div className='c-close-modal-header'>
                            <button
                                aria-label={t('general.button.close.label')}
                                onClick={onClose}
                                className='c-button-close-modal-small'>
                                <FaTimes />
                            </button>
                        </div>

                        <ScrollPane
                            style={{
                                maxHeight: `${parseInt(screenHeight * 0.9, 10)}px`,
                                overflow: exploding ? 'hidden' : 'auto',
                            }}
                            className='c-modal--item-content'>
                            <div className='c-modal-promo-wrapper'>
                                <div
                                    className='c-modal-promo-image'
                                    style={{ background: data.promo.color }}>
                                    {data.promo.coverImage ? (
                                        <img
                                            src={data.promo.coverImage}
                                            alt={data.promo.name}
                                        />
                                    ) : null}
                                </div>
                                <div className='c-modal-promo-content withProductSelector'>
                                    <motion.div
                                        initial={{ scale: 0.95, y: 50 }}
                                        animate={{ scale: 1, y: 0, transition: { delay: 0.15 } }}
                                        className='c-modal-promo-inner'>
                                        <div
                                            className='c-modal-promo-head'
                                            style={{ paddingBottom: data.promo.description ? '10px' : '30px' }}>
                                            <p
                                                className='c-modal-promo-promoname'
                                                style={
                                                    data.promo.color && {
                                                        color: `#${String(data.promo.color).replace('#', '')}`,
                                                    }
                                                }>
                                                {data.promo.name}
                                            </p>
                                            {data.promo.isTaDa ? (
                                                <PoweredBy
                                                    brandId={BRAND_TADA.identifier}
                                                    isStaticPositioned
                                                />
                                            ) : (
                                                <div style={{ height: '12px' }} />
                                            )}
                                        </div>
                                        <div className='c-modal-promo-forms'>
                                            <div className='c-modal-promo-form'>{renderPromoForm()}</div>

                                            <button
                                                disabled={exploding}
                                                onClick={onClose}
                                                className='c-modal-promo-deny'>
                                                {t('general.button.close.label')}
                                            </button>

                                            {exploding ? (
                                                <div className='success-explosion'>
                                                    <Confetti y={1} />
                                                </div>
                                            ) : null}
                                        </div>
                                    </motion.div>
                                    <p className='c-modal-promo-legal'>{t('legal.alcohol.plus18')}</p>
                                </div>
                            </div>
                        </ScrollPane>
                    </div>
                </motion.div>
            </div>
        )
    }

    return null
}

export default PromoModal
