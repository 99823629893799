import { createContext, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

import useEventQuery from '../hooks/useEventQuery'
import RoutePath from '../structures/Enums/RoutePath.enum.ts'
import { isNotNullOrUndefined } from '../structures/Guards/guards.utils.ts'

import { ThemeContext } from './ThemeContextProvider'
import { VenueContext } from './VenueContextProvider.tsx'

export const EventContext = createContext({
    isLoading: true,
    isFetching: undefined,
    setIsLoading: () => undefined,
    event: undefined,
    // eslint-disable-next-line no-unused-vars
    setEvent: (event) => undefined,
    // eslint-disable-next-line no-unused-vars
    fetchEventById: (id) => undefined,
    error: undefined,
})

function EventContextProvider({ children }) {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const { setTheme } = useContext(ThemeContext)
    const [id, setId] = useState(undefined)

    const selfScanMatchPath = useMatch(RoutePath.SELF_SCAN)
    const eventMatchPath = useMatch(RoutePath.EVENT)
    const eventGroupEventMatchPath = useMatch(`${RoutePath.EVENT_GROUP_EVENT}/*`)
    const shouldFetchVenue =
        isNotNullOrUndefined(selfScanMatchPath) ||
        isNotNullOrUndefined(eventMatchPath) ||
        isNotNullOrUndefined(eventGroupEventMatchPath)

    const { isLoading, error, isError, event, isFetching } = useEventQuery(id)

    const { fetchVenueById } = useContext(VenueContext)
    useLayoutEffect(() => {
        async function getVenue() {
            if (isNotNullOrUndefined(event?.venue?.id) && shouldFetchVenue === true) {
                await fetchVenueById(event.venue.id)
            }
        }
        // eslint-disable-next-line no-void
        void getVenue()
    }, [fetchVenueById, shouldFetchVenue, event?.venue?.id])

    const fetchEventById = (id) => {
        queryClient.invalidateQueries({ queryKey: ['event'], refetchType: 'none' })
        setId(id)
    }

    const setEvent = ({ id }) => fetchEventById(id)

    useEffect(() => {
        if (error?.status === 404) navigate(RoutePath.HOMEPAGE, { replace: true })
    }, [error])

    useLayoutEffect(() => {
        if (isNotNullOrUndefined(event)) {
            setTheme({
                ...(event.theming ?? {}),
                ...{
                    allowsGuestUsers: event.allows_guest_users || false,
                    placePath: event.venue?.place?.id ? `/places/${event.venue.place.id}` : undefined,
                    venuePath: event.venue?.id ? `/venues/${event.venue.id}` : undefined,
                    venueScreenDisabled: event.venue?.has_multiple_events === false,
                    customDomain: event.venue?.custom_domain,
                    customTags: event.venue?.custom_tags,
                },
            })
        }
    }, [event])

    const providerData = useMemo(
        () => ({ isLoading, isFetching, fetchEventById, event, setEvent, isError, error }),
        [isLoading, event, isError, error]
    )

    return <EventContext.Provider value={providerData}>{children}</EventContext.Provider>
}

export default EventContextProvider
