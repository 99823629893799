import { CSSProperties, FC, ReactNode } from 'react'
import classNames from 'classnames'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

import RubberBand from '../../common/RubberBand'

import './container.scss'

interface IContainerProperties {
    id?: string
    className?: string
    style?: CSSProperties
    fullWidth?: boolean
    emptyView?: boolean
    rubberBand?: boolean
    smallRubberBand?: boolean
    animated?: boolean
    children?: ReactNode
}

const Container: FC<IContainerProperties> = ({
    id,
    className,
    style,
    fullWidth,
    emptyView,
    rubberBand,
    smallRubberBand,
    animated,
    children,
}) => {
    const containerClassName = classNames(
        'c-container',
        {
            'c-container--full-width': fullWidth,
            'c-container--empty-view': emptyView,
            'c-container--animated': animated,
        },
        className
    )

    const container = (
        <div
            id={id}
            className={containerClassName}
            style={style}>
            {children}
        </div>
    )

    return isNotNullOrUndefined(rubberBand) && rubberBand ? (
        <RubberBand
            transparent={null}
            setheight={null}
            small={smallRubberBand}>
            {container}
        </RubberBand>
    ) : (
        container
    )
}

export default Container
