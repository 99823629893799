import * as Yup from 'yup'

import i18n from '../../i18next-config'
import CheckoutMethod from '../../structures/Enums/CheckoutMethod.enum.ts'

export let REGISTER_SCHEMA
export let LOGIN_SCHEMA
export let TERMS_SCHEMA
export let PHONE_SCHEMA
export let PAYMENT_TABLE_SCHEMA
export let PAYMENT_PICKUP_SCHEMA
export let generateOrderSchema
export let REQUEST_PASSWORD_RESET_SCHEMA
export let EDIT_PROFILE
export let PASSWORD_RESET_SCHEMA
export let CREDITS_SCHEMA
export let VOUCHER_SCHEMA

i18n.on('initialized languageChanged', () => {
    REGISTER_SCHEMA = Yup.object().shape({
        firstname: Yup.string().required(i18n.t('validation.name.required')),
        name: Yup.string().required(i18n.t('validation.lastname.required')),
        email: Yup.string().email().required(i18n.t('validation.email.required')),
        password: Yup.string().required(i18n.t('validation.password.required')),
        has_agreed: Yup.string().required(i18n.t('validation.terms_and_conditions.required')),
    })

    LOGIN_SCHEMA = Yup.object().shape({
        email: Yup.string().email().required(i18n.t('validation.email.required')),
        password: Yup.string().required(i18n.t('validation.password.required')),
    })

    TERMS_SCHEMA = Yup.object().shape({
        has_agreed: Yup.string().required(i18n.t('validation.terms_and_conditions.required')),
    })

    PHONE_SCHEMA = Yup.object().shape({
        phone: Yup.string().required(i18n.t('validation.phone.required')),
    })

    PAYMENT_TABLE_SCHEMA = Yup.object().shape({
        phone: Yup.string().required(i18n.t('validation.phone.required')).nullable(),
        table_number: Yup.number()
            .required(i18n.t('validation.table_number.required'))
            .typeError(i18n.t('validation.table_number.wrong_format'))
            .nullable(),
    })

    PAYMENT_PICKUP_SCHEMA = Yup.object().shape({
        phone: Yup.string().required(i18n.t('validation.phone.required')).nullable(),
    })

    generateOrderSchema = (hasTableNumber, tableNumberType, sendsSms, checkoutMethod, customFields, isguestuser) => {
        // NOSONAR
        /// ^(33[1-9][0-9]{8})|(324[0-9]{8})|(316[0-9]{8})$/i
        const shape = {
            phone: sendsSms
                ? Yup.string()
                      .required(i18n.t('validation.phone.required'))
                      .trim()
                      // .matches(/^(33[1-9][0-9]{8})|(324[0-9]{8})|(316[0-9]{8})$/i, i18n.t('validation.phone.wrong_format'))
                      .nullable()
                : Yup.string().notRequired().default(undefined),
            table_number: hasTableNumber
                ? Yup.number()
                      .required(i18n.t('validation.table_number.required'))
                      .typeError(i18n.t('validation.table_number.wrong_format'))
                      .test('no 04xxx', i18n.t('validation.table_number.wrong_format'), (value) =>
                          value ? !/^(04\d{3})$/i.test(`0${value.toString()}`) : false
                      ) // to string removes leading zero, so add one
                      .nullable()
                : Yup.number().notRequired().default(undefined),
        }

        if (tableNumberType === 'alphanumeric') {
            shape.table_number = hasTableNumber
                ? Yup.string()
                      .matches(/^[a-z0-9]+$/i, i18n.t('validation.table_number.wrong_format'))
                      .required(i18n.t('validation.table_number.required'))
                      .typeError(i18n.t('validation.table_number.wrong_format'))
                      .nullable()
                : Yup.string().nullable()
        }

        if (tableNumberType === 'any') {
            shape.table_number = hasTableNumber
                ? Yup.string()
                      .required(i18n.t('validation.table_number.required'))
                      .typeError(i18n.t('validation.table_number.wrong_format'))
                      .nullable()
                : Yup.string().nullable()
        }

        // table_number is a POS table so it can be whatever it wants
        if (checkoutMethod === CheckoutMethod.TABLE_SERVICE_POS) {
            shape.table_number = Yup.string().required(i18n.t('validation.table_number.required'))
        }

        // if PWR has a table_nr in QR-code => Put it in a hidden field. Not Required.
        if (
            checkoutMethod === CheckoutMethod.PICKUP_WHEN_READY_WITH_SMS ||
            checkoutMethod === CheckoutMethod.PICKUP_WHEN_READY_WITHOUT_SMS
        ) {
            shape.table_number = Yup.string().nullable().notRequired()
        }

        if (checkoutMethod === CheckoutMethod.TAKE_OUT) {
            shape.takeout_date = Yup.string().required(i18n.t('validation.takeout_date.required'))
            shape.takeout_hour = Yup.string().required(i18n.t('validation.takeout_hour.required'))
        }

        if (checkoutMethod === CheckoutMethod.DELIVERY) {
            shape.address = Yup.string().required(i18n.t('validation.address.required'))

            shape.route = Yup.string().required(i18n.t('validation.route.required'))
            shape.street_number = Yup.string().required(i18n.t('validation.street_number.required'))
            shape.postal_code = Yup.string().required(i18n.t('validation.postal_code.required'))
            shape.locality = Yup.string().required(i18n.t('validation.locality.required'))
            shape.country = Yup.string().required(i18n.t('validation.country.required'))

            shape.takeout_date = Yup.string().required(i18n.t('validation.takeout_date.required'))
            shape.takeout_hour = Yup.string().required(i18n.t('validation.takeout_hour.required'))
        }

        if (customFields && customFields.length > 0) {
            customFields.forEach((item) => {
                if (item.required === true) {
                    if (item.type === 'checkbox' || item.type === 'opt-in') {
                        shape[`custom-field-${item.id}`] = Yup.bool().oneOf([true], i18n.t('validation.email.required'))
                    } else if (item.type === 'number') {
                        shape[`custom-field-${item.id}`] = Yup.number()
                            .required(i18n.t('validation.custom_number.required'))
                            .typeError(i18n.t('validation.custom_number.wrong_format'))
                            .nullable()
                    } else {
                        shape[`custom-field-${item.id}`] = Yup.string().required(i18n.t('validation.email.required'))
                    }
                } else {
                    shape[`custom-field-${item.id}`] = Yup.string().nullable()
                }
            })
        }

        if (isguestuser) {
            shape.firstname = Yup.string().required(i18n.t('validation.name.required'))
        }

        return Yup.object().shape(shape)
    }

    CREDITS_SCHEMA = Yup.object().shape({
        use_credits: Yup.bool().nullable(),
    })

    VOUCHER_SCHEMA = Yup.object().shape({
        voucher: Yup.string().required(i18n.t('validation.voucher.required')),
    })

    REQUEST_PASSWORD_RESET_SCHEMA = Yup.object().shape({
        email: Yup.string().email().required(i18n.t('validation.email.required')),
    })

    EDIT_PROFILE = Yup.object().shape({
        firstname: Yup.string().required(i18n.t('validation.name.required')),
        name: Yup.string().required(i18n.t('validation.lastname.required')),
        phone: Yup.string()
            .required(i18n.t('validation.phone.required'))
            .trim()
            .matches(/^(33[1-9][0-9]{8})|(324[0-9]{8})|(316[0-9]{8})$/i, i18n.t('validation.phone.wrong_format')), // NOSONAR
    })

    PASSWORD_RESET_SCHEMA = Yup.object().shape({
        token: Yup.string(),
        password: Yup.string().required(i18n.t('validation.password.required')),
        passwordconfirm: Yup.string()
            .oneOf([Yup.ref('password'), null], i18n.t('validation.password_confirm.no_match'))
            .required(i18n.t('validation.password_confirm.required')),
    })
})
