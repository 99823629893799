import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaPlus } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

import { divideByHundred } from '../../constants/helpers'
import { openModal } from '../../redux/actions/actionBuilders'
import ModalType from '../../structures/Enums/ModalType.ts'
import { isNotNullOrUndefined } from '../../structures/Guards/guards.utils.ts'
import ProductPrice from '../02_molecules/ProductPrice/ProductPrice.tsx'
import CircularButton from '../CircularButton/CircularButton.tsx'

import './crosssellproduct.scss'

function CrossSellProductItem({ eventId, product, type, ruleId }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const basket = useSelector((state) => state.basket)

    const [amountInBasket, setAmountInBasket] = useState(0)

    const getBasketAmountByProductId = (data, id) => {
        let amount = 0
        for (const key in data) {
            if (data[key].originalId === id || data[key].id === id) {
                amount += data[key].amount
            }
        }
        return amount
    }

    const onProductClick = useCallback(() => {
        const productData = { ...product }
        productData.is_cross_sell = true
        if (type) productData.type = type
        if (eventId) productData.event_id = eventId
        if (ruleId) productData.type_reference_id = ruleId
        dispatch(
            openModal({
                type: ModalType.ADD_TO_BASKET_MODAL,
                data: {
                    eventId,
                    product: productData,
                    isOrderable: true,
                    amount: 1,
                },
            })
        )
    }, [product, dispatch, openModal, eventId, type, ruleId])

    useEffect(() => {
        const basketProducts = basket[eventId]?.products ?? null
        if (isNotNullOrUndefined(basketProducts)) {
            setAmountInBasket(getBasketAmountByProductId(basketProducts, product.id))
        }
    }, [basket, eventId])

    return (
        <div
            className='c-crosssellproduct'
            onClick={onProductClick}>
            <div className='c-crosssellproduct-inner'>
                {amountInBasket > 0 ? (
                    <div className='c-crosssellproduct-amount'>
                        <span>{`${amountInBasket}x`}</span>
                    </div>
                ) : null}
                {product.images?.small ? (
                    <div className='c-crosssellproduct-image'>
                        <img
                            loading='lazy'
                            src={product.images.small}
                        />
                    </div>
                ) : null}

                <div className='c-crosssellproduct-content'>
                    <p className='u-text-bold'>{product.name}</p>
                    <ProductPrice price={divideByHundred(product.price_int)} />
                </div>
            </div>

            <div className='c-crosssellproduct-control'>
                <CircularButton
                    aria-label={t('general.button.add.label')}
                    className='c-recommendation-button'>
                    <FaPlus />
                </CircularButton>
            </div>
        </div>
    )
}

export default CrossSellProductItem
