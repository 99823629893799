import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, Form, Formik } from 'formik'
import Button from 'src/components/01_atoms/Button/Button'
import CodeInput from 'src/components/forms/payment/code/CodeInput'
import * as Yup from 'yup'

interface ILoyaltyRegistrationOtpFormProperties {
    onSuccessfullOTP: (code: string) => void
}

interface IFormValues {
    code: string
}

const LoyaltyRegistrationOtpForm: FC<ILoyaltyRegistrationOtpFormProperties> = ({ onSuccessfullOTP }) => {
    const { t } = useTranslation()

    const [isValidCode, setIsValidCode] = useState<boolean>(false)

    const validationSchema = Yup.object({
        code: Yup.string().required(t('validation.phone.required')),
    })

    const initialValues: IFormValues = {
        code: '',
    }

    const handleSubmit = useCallback(
        (values: IFormValues) => {
            onSuccessfullOTP?.(values.code)
        },
        [onSuccessfullOTP]
    )

    const onIsValidCode = useCallback((isValid: boolean) => {
        setIsValidCode(isValid)
    }, [])

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            {({ isSubmitting, handleChange, handleBlur }) => (
                <Form>
                    <div className='code-container'>
                        <Field
                            fields='6'
                            key='code'
                            component={CodeInput}
                            name='code'
                            id='code'
                            autoFocus
                            onChange={handleChange}
                            disabled={isSubmitting}
                            onBlur={handleBlur}
                            onIsValidCode={onIsValidCode}
                            autoComplete='one-time-code'
                        />
                    </div>

                    <div className='loyaltyregistration-cta'>
                        <Button
                            disabled={isSubmitting || !isValidCode}
                            type='submit'
                            label="Let's go!"
                            variant='dark'
                        />
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default LoyaltyRegistrationOtpForm
