import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { FaBars, FaTimes } from 'react-icons/fa'

import { KioskContext } from '../../context/KioskContextProvider'

function MenuIcon({ handleClick, isOpen }) {
    const { i18n } = useTranslation()
    const { kioskMode } = useContext(KioskContext)

    const drawerTrigger = kioskMode ? (
        <div className='c-kiosk-language-trigger'>
            <p>{i18n.languages[0]}</p>
        </div>
    ) : (
        <FaBars />
    )

    const closeButton = kioskMode ? (
        <div className='c-kiosk-language-trigger'>
            <p>
                <FaTimes />
            </p>
        </div>
    ) : (
        <FaTimes />
    )

    return (
        <span
            className='c-header--icon c-menu--icon'
            onClick={handleClick}>
            {isOpen ? closeButton : drawerTrigger}
        </span>
    )
}

export default MenuIcon
