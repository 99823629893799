import { CLOSE_ALL_MODALS, CLOSE_MODAL, OPEN_MODAL } from '../actions/modalActions'

export default function modalReducer(
    state = {
        activeModals: [],
    },
    { type, payload }
) {
    switch (type) {
        case OPEN_MODAL:
            const temp = state.activeModals
            temp.push({
                type: payload.type,
                data: payload.data,
            })

            state = {
                ...state,
                activeModals: temp,
            }

            return state

        case CLOSE_MODAL:
            if (state.activeModals) {
                const temp = state.activeModals
                temp.pop()

                state = {
                    ...state,
                    activeModals: temp,
                }
            }

            return state

        case CLOSE_ALL_MODALS:
            if (state.activeModals) {
                const temp = state.activeModals
                temp.splice(0, temp.length)

                state = {
                    ...state,
                    activeModals: temp,
                }
            }

            return state

        default:
            return state
    }
}
