import PromoRecommendation from './PromoRecommendation'

import './promorecommendation.scss'

function PromoRecommendations({ product, promotions }) {
    if (promotions?.length > 0) {
        return (
            <div className='c-promo-recommendation-list'>
                <div className='inner'>
                    {promotions.map((promotion) => (
                        <PromoRecommendation
                            key={promotion.id}
                            promotion={promotion}
                            product={product}
                        />
                    ))}
                </div>
            </div>
        )
    }
    return null
}

export default PromoRecommendations
