import { FC, useContext } from 'react'
import ConfettiBoom, { ConfettiProps } from 'react-confetti-boom'
import { ThemeContext } from 'src/context/ThemeContextProvider'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

// Destructure and set default values based on the mode
const Confetti: FC<ConfettiProps> = ({
    mode = 'boom',
    particleCount = 600,
    shapeSize = 18,
    colors,
    ...properties // This will allow you to pass other props that might be needed
}) => {
    // Defaults specific to 'boom' mode
    const boomDefaults = {
        effectCount: Number.POSITIVE_INFINITY,
        launchSpeed: 2,
        spreadDeg: 45,
        effectInterval: 0,
        y: 1,
    }

    const { theme } = useContext(ThemeContext)

    return (
        <ConfettiBoom
            mode={mode}
            particleCount={particleCount}
            shapeSize={shapeSize}
            colors={
                isNotNullOrUndefined(colors)
                    ? colors
                    : [`#${theme?.color ?? 'ff577f'}`, '#ff884b', '#ffd384', '#fff9b0']
            }
            {...(mode === 'boom' ? boomDefaults : {})} // Apply boom-specific defaults if mode is 'boom'
            {...properties}
        />
    )
}

export default Confetti
