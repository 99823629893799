import { ButtonHTMLAttributes, FC, ReactNode } from 'react'
import classNames from 'classnames'

import './circularbutton.scss'

interface ICircularButtonProperties extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: ReactNode
    className: string
    disabled: boolean
    onClick: () => void
}

const CircularButton: FC<ICircularButtonProperties> = ({
    children,
    className,
    disabled = false,
    onClick,
    ...properties
}) => (
    <button
        onMouseDown={(event) => {
            event.preventDefault()
        }}
        disabled={disabled}
        className={classNames('c-circular-button', className)}
        onClick={onClick}
        {...properties}>
        {children}
    </button>
)

export default CircularButton
