enum ModalType {
    QUANTITY_SELECTOR_MODAL = 'QUANTITY_SELECTOR_MODAL',
    ADD_TO_BASKET_MODAL = 'ADD_TO_BASKET_MODAL',
    ERROR_MODAL = 'ERROR_MODAL',
    CONFIRM_MODAL = 'CONFIRM_MODAL',
    DIALOG_MODAL = 'DIALOGMODAL',
    RATING_MODAL = 'RATINGMODAL',
    CROSSSELL_MODAL = 'CROSSSELL_MODAL',
    UPSELL_ADDITIONS_MODAL = 'UPSELL_ADDITIONS_MODAL',
    APPLY_LOYALTY_MODAL = 'APPLY_LOYALTY_MODAL',
    APPLY_REDUCTION_MODAL = 'APPLY_REDUCTION_MODAL',
    STARNET_PAYMENT_MODAL = 'STARNET_PAYMENT_MODAL',
    VOUCHER_PAYMENT_MODAL = 'VOUCHER_PAYMENT_MODAL',
    INACTIVITY_MODAL = 'INACTIVITY_MODAL',
    VIDEO_STORY_MODAL = 'VIDEO_STORY_MODAL',
    PAY_BY_QR_MODAL = 'PAY_BY_QR_MODAL',
    PROMO_MODAL = 'PROMO_MODAL',
    PRODUCT_CONFIGURATOR_MODAL = 'PRODUCT_CONFIGURATOR_MODAL',
    CUSTOM_TIPPING_MODAL = 'CUSTOM_TIPPING_MODAL',
    COMPETITION_MODAL = 'COMPETITION_MODAL',
    LOYALTY_REGISTRATION_MODAL = 'LOYALTY_REGISTRATION_MODAL',
    LOYALTY_REWARDS_MODAL = 'LOYALTY_REWARDS_MODAL',
    AUTHENTICATION_MODAL = 'AUTHENTICATION_MODAL',
}

export default ModalType
