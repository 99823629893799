/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { openModal } from 'src/redux/actions/actionBuilders'
import ModalType from 'src/structures/Enums/ModalType'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

const QueryParameterModals = () => {
    const [searchParameters, setSearchParameters] = useSearchParams()
    const dispatch = useDispatch()
    const { modal } = useSelector((state) => state) as any

    useEffect(() => {
        const modalType = searchParameters?.get('modal')

        // check if not already open
        const isActiveModal = (type: ModalType) =>
            modal?.activeModals?.find?.((activeModal: any) => activeModal.type === type)

        // Map the search parameter to your modal types
        switch (modalType) {
            case 'error': {
                if (!isNotNullOrUndefined(isActiveModal(ModalType.ERROR_MODAL))) {
                    dispatch(
                        openModal({
                            type: ModalType.ERROR_MODAL,
                        })
                    )
                }

                break
            }
            case 'loyalty': {
                if (!isNotNullOrUndefined(isActiveModal(ModalType.LOYALTY_REGISTRATION_MODAL))) {
                    dispatch(
                        openModal({
                            type: ModalType.LOYALTY_REGISTRATION_MODAL,
                        })
                    )
                }
                break
            }
            default: {
                // No modal or invalid modal type
                break
            }
        }

        searchParameters.delete('modal')
        setSearchParameters(searchParameters, { replace: true })
    }, [searchParameters, dispatch])

    return null
}

export default QueryParameterModals
