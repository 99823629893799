import { useCallback, useContext } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { VenueContext } from 'src/context/VenueContextProvider'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

import BillySDK from '../sdk/sdk'

export interface ILoyaltyContact {
    tier: string
    loyalty_balance: string
    uuid: string
    email: string | null
}
export interface ILoyaltyOtpCredentials {
    code: string
    email: string
}
interface IUseLoyaltyReturnType {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    activateLoyaltyContact: (values: { [key: string]: string }) => Promise<any>
    validateLoyaltyContact: (credentials: ILoyaltyOtpCredentials) => Promise<void>
}

const useLoyalty = (): IUseLoyaltyReturnType => {
    const queryClient = useQueryClient()
    const { venue } = useContext(VenueContext)

    const activateContact = useMutation({
        retry: 0,
        mutationFn: async (values: { [key: string]: string }) => BillySDK.loyaltyActivateContact(venue.id, values),
        onSuccess: async (data) => data,
        onError: async (error) => error,
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey: ['loyaltyContact'] })
        },
    })

    const activateLoyaltyContact = useCallback(
        async (values: { [key: string]: string }) => activateContact.mutateAsync(values),
        [venue?.id, activateContact]
    )

    const validateContact = useMutation({
        retry: 2,
        mutationFn: async (credentials: ILoyaltyOtpCredentials) => BillySDK.loyaltyVerifyEmail(venue.id, credentials),
    })

    const validateLoyaltyContact = async (credentials: ILoyaltyOtpCredentials) => {
        if (isNotNullOrUndefined(venue?.id)) {
            return validateContact.mutateAsync(credentials, {
                onSuccess: (response) => response,
                onError: (error) => error,
            })
        }
    }

    return {
        activateLoyaltyContact,
        validateLoyaltyContact,
    }
}

export default useLoyalty
