import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import LoyaltyRegistrationOtpForm from 'src/components/02_molecules/LoyaltyRegistrationOtpForm/LoyaltyRegistrationOtpForm'
import { ILoyaltyOtpCredentials } from 'src/hooks/useLoyalty'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

interface ILoyaltyRegistrationOTPProperties {
    email: string | undefined
    handleOtp: ({ code, email }: ILoyaltyOtpCredentials) => void
}
const LoyaltyRegistrationOtp: FC<ILoyaltyRegistrationOTPProperties> = ({ email, handleOtp }) => {
    const { t } = useTranslation()

    const handleSuccessfulOtp = useCallback(
        (code: string) => {
            if (isNotNullOrUndefined(email)) {
                handleOtp?.({
                    email,
                    code,
                })
            }
        },
        [handleOtp, email]
    )

    return (
        <div className='loyaltyregistration-otp'>
            <h1 className='u-text-center'>{t('loyalty.modal.registration.otp.title')}</h1>
            <p>{t('loyalty.modal.registration.otp.body')}</p>
            <LoyaltyRegistrationOtpForm onSuccessfullOTP={handleSuccessfulOtp} />
        </div>
    )
}

export default LoyaltyRegistrationOtp
