import './progressbar.scss'

function ProgressBar({ progress = 0, transitionDuration = '0.25s', transitionTimingFunction = 'ease-in-out' }) {
    return (
        <div className='c-progress-bar'>
            <div className='c-progress-bar--track'>
                <div
                    className='c-progress-bar--progress'
                    style={{ width: `${Math.min(progress, 100)}%`, transitionDuration, transitionTimingFunction }}
                />
            </div>
        </div>
    )
}

export default ProgressBar
