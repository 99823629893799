import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import LoyaltyRegistrationForm from 'src/components/02_molecules/LoyaltyRegistrationForm/LoyaltyRegistrationForm'

interface ILoyaltyRegistrationSignupProperties {
    handleSignup: (email: string) => void
}
const LoyaltyRegistrationSignup: FC<ILoyaltyRegistrationSignupProperties> = ({ handleSignup }) => {
    const { t } = useTranslation()

    const onSignup = useCallback(
        (email: string) => {
            handleSignup?.(email)
        },
        [handleSignup]
    )

    return (
        <div className='loyaltyregistration-signup'>
            <h1 className='u-text-center'>{t('loyalty.modal.registration.emailsignup.title')}</h1>
            <LoyaltyRegistrationForm onSuccessfulSignup={onSignup} />
        </div>
    )
}

export default LoyaltyRegistrationSignup
