import { useMatch } from 'react-router-dom'
import { motion, useScroll, useTransform } from 'framer-motion'

import RoutePath from '../../structures/Enums/RoutePath.enum.ts'

import './headerbrandinglabel.scss'

function HeaderBrandingLabel({ children }) {
    const scrollRange = [60, 100]
    const { scrollY } = useScroll()
    const position = useTransform(scrollY, scrollRange, [1, 0])
    const scale = useTransform(scrollY, scrollRange, [1, 0.8])
    const top = useTransform(scrollY, scrollRange, [0, -10])

    const eventMatchPath = useMatch(`${RoutePath.EVENT}/`)
    const eventGroupEventMatchPath = useMatch(`${RoutePath.EVENT_GROUP_EVENT}/*`)

    if (eventMatchPath || eventGroupEventMatchPath) {
        return (
            <motion.div
                className='c-header-branding-label'
                style={{ opacity: position, scale, y: top }}>
                <div className='c-header-branding-label-inner'>{children}</div>
            </motion.div>
        )
    }

    return null
}

export default HeaderBrandingLabel
