import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

import { isNotNull, isNotNullOrUndefined } from '../../structures/Guards/guards.utils'
import Button from '../01_atoms/Button/Button'

import RatingButton from './RatingButton'

import './rating.scss'

interface IRatingProperties {
    onRating?: (data: { score: number | undefined; message: string | null }) => void
    onSubmit?: (data: { score: number | undefined; message: string | null }) => void
    placeId?: string | null
}

const Rating: FC<IRatingProperties> = ({ onRating = () => {}, onSubmit = () => {}, placeId = null }) => {
    const messageReference = useRef<string | null>(null)
    const { t } = useTranslation()

    const [disabled, setDisabled] = useState<boolean>(false)
    const [score, setScore] = useState<number | undefined>()

    const onScore = useCallback((givenScore: number): void => {
        setScore(givenScore)
    }, [])

    const onSubmitRating = useCallback(() => {
        setDisabled(true)
        onSubmit({
            score,
            message: messageReference.current != null ? messageReference.current : null,
        })
    }, [score])

    useEffect(() => {
        if (isNotNullOrUndefined(score)) {
            onRating({
                score,
                message: messageReference.current != null ? messageReference.current : null,
            })
        }
    }, [score])

    return (
        <div className='c-rating'>
            <motion.div
                layout='position'
                className='c-rating-buttons'>
                <RatingButton
                    mood='negative'
                    imageId='2'
                    value={1}
                    active={score === 1}
                    disabled={disabled}
                    onClick={onScore}
                />
                <RatingButton
                    mood='negative'
                    imageId='1'
                    value={2}
                    active={score === 2}
                    disabled={disabled}
                    onClick={onScore}
                />
                <RatingButton
                    mood='neutral'
                    imageId='0'
                    value={3}
                    active={score === 3}
                    disabled={disabled}
                    onClick={onScore}
                />
                <RatingButton
                    mood='positive'
                    imageId='1'
                    value={4}
                    active={score === 4}
                    disabled={disabled}
                    onClick={onScore}
                />
                <RatingButton
                    mood='positive'
                    imageId='2'
                    value={5}
                    active={score === 5}
                    disabled={disabled}
                    onClick={onScore}
                />
            </motion.div>

            <AnimatePresence>
                {isNotNullOrUndefined(score) ? (
                    <motion.div
                        initial={{ opacity: 0, y: 30 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.7, type: 'spring' }}
                        className='c-rating-input'>
                        {isNotNullOrUndefined(placeId) && score > 3 ? (
                            <div className='c-google-rating'>
                                <h3 className='c-google-rating-title'>{t('review.review_with_google.title')}</h3>
                                <p className='c-google-rating-body'>{t('review.review_with_google.body')}</p>
                                <a
                                    className={classNames('c-button c-button-google')}
                                    onClick={onSubmitRating}
                                    target='_blank'
                                    href={`https://search.google.com/local/writereview?placeid=${placeId}`}
                                    rel='noreferrer'>
                                    {t('review.button.review_with_google.cta')}
                                </a>
                            </div>
                        ) : (
                            <>
                                <textarea
                                    autoFocus
                                    maxLength={150}
                                    value={isNotNull(messageReference.current) ? messageReference.current : ''}
                                    onChange={({ target }) => (messageReference.current = target.value)}
                                    className='c-textarea'
                                    disabled={disabled}
                                />
                                <Button
                                    disabled={disabled}
                                    variant='dark'
                                    label='Confirm'
                                    onClick={onSubmitRating}
                                    className={undefined}
                                    leftChild={undefined}
                                    rightChild={undefined}
                                    initial={undefined}
                                    animate={undefined}
                                    exit={undefined}
                                    transition={undefined}
                                    style={undefined}
                                />
                            </>
                        )}
                    </motion.div>
                ) : null}
            </AnimatePresence>
        </div>
    )
}

export default Rating
