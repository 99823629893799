import { useEffect, useState } from 'react'

function useFetch(url) {
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState(null)
    const [error, setError] = useState(false)

    useEffect(() => {
        if (!url) return

        const controller = new AbortController()
        const signal = controller.signal

        fetch(url, { signal })
            .then((response) => {
                if (response.ok === true) {
                    response
                        .json()
                        .then((r) => {
                            setData(r)
                        })
                        .error(() => setData(null))
                }
                setError('Network was not OK')
            })
            .catch((error) => {
                setError(error)
            })
            .finally(() => setIsLoading(false))

        return () => controller.abort()
    }, [url])

    return {
        isLoading,
        data,
        error,
    }
}

export default useFetch
