import React, { FC } from 'react'

import './ImpulseSpinner.scss' // Import the SCSS file

// Define the props for the ImpulseSpinner component
interface IImpulseSpinnerProperties {
    size?: number
    frontColor?: string
    backColor?: string
    loading?: boolean
}

// Create the ImpulseSpinner component
const ImpulseSpinner: FC<IImpulseSpinnerProperties> = ({
    size = 50,
    frontColor = '#fff',
    backColor = '#fff',
    loading = true,
}) => {
    // Style for the spinner
    const spinnerStyle: React.CSSProperties = {
        width: `${size}px`,
        height: `${size}px`,
    }

    // Dynamic styles for the dots
    const dotStyle = (color: string, delay: string, position: string): React.CSSProperties => ({
        width: `${size / 5}px`,
        height: `${size / 5}px`,
        backgroundColor: color,
        animationDelay: delay,
        [position]: 0,
    })

    return loading ? (
        <div
            className='impulse-spinner'
            style={spinnerStyle}>
            <div
                className='dot'
                style={dotStyle(backColor, '0s', 'left')}
            />
            <div
                className='dot'
                style={dotStyle(frontColor, '0.15s', 'left')}
            />
            <div
                className='dot'
                style={dotStyle(backColor, '0.3s', 'right')}
            />
        </div>
    ) : null
}

export default ImpulseSpinner
