import { createContext, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { captureException, setTag, withScope } from '@sentry/react'
import { useQueryClient } from '@tanstack/react-query'

import { getQuerystringParameterByName } from '../constants/helpers'
import LoadingScreen from '../containers/loading/LoadingScreen'
import usePendingOrder from '../hooks/usePendingOrder'
import {
    closeAllModals,
    removeAllLoyalty,
    resetBasket,
    resetCashlessCard,
    updateTokens,
} from '../redux/actions/actionBuilders'
import BillySDK from '../sdk/sdk'
import User from '../structures/Classes/User.ts'

export const TokenContext = createContext({
    reAuthToken: undefined,
    setReAuthToken: () => undefined,
})
export const UserContext = createContext({
    user: undefined,
    fetchUser: () => undefined,
    updateUser: () => undefined,
    setUser: () => undefined,
    logOut: () => undefined,
})

function UserAndTokenContextProvider({ children }) {
    const dispatch = useDispatch()
    const queryClient = useQueryClient()
    const { kioskMode } = useSelector((state) => state.kiosk)
    const [searchParams, setSearchParams] = useSearchParams()
    const storedAccessToken = useSelector((state) => state.tokens?.access_token)
    const { removePendingOrder, cancelAndRemovePendingOrder } = usePendingOrder()

    const [isLoading, setIsLoading] = useState(true)
    let reAuthToken = getQuerystringParameterByName('token') || undefined
    const [user, setUser] = useState(undefined)

    useEffect(() => {
        if (!storedAccessToken && reAuthToken === undefined) {
            setIsLoading(false)
        }
    }, [storedAccessToken, reAuthToken])

    const fetchUser = useCallback(() => {
        if (storedAccessToken && reAuthToken === undefined) {
            BillySDK.setAndValidateToken(storedAccessToken)
                .then((response) => {
                    setUser(new User(response.data))
                })
                .catch(async (error) => {
                    if (error.status === 401) {
                        if (kioskMode) {
                            queryClient.invalidateQueries({ queryKey: ['device'] })
                            withScope(() => {
                                setTag('Failed Token', storedAccessToken)
                                captureException(new Error('Kiosk setAndValidateToken Error'))
                            })
                        }
                        removePendingOrder()
                        dispatch(
                            updateTokens({
                                access_token: undefined,
                            })
                        )
                        dispatch(removeAllLoyalty())
                        setUser(undefined)
                    }
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }, [storedAccessToken, dispatch, reAuthToken])

    useEffect(() => {
        if (storedAccessToken && reAuthToken === undefined) {
            fetchUser()
        }
    }, [storedAccessToken, fetchUser, reAuthToken])

    useEffect(() => {
        if (reAuthToken) {
            setIsLoading(true)
            BillySDK.reauth(reAuthToken)
                .then((response) => {
                    if (searchParams.has('token')) {
                        searchParams.delete('token')
                        setSearchParams(searchParams, { replace: true })
                        reAuthToken = undefined
                    }
                    dispatch(updateTokens({ access_token: response.token }))
                })
                .catch(() => {
                    // silent fail
                })
                .finally(() => {
                    // remove the reauth token from the url
                    if (searchParams.has('token')) {
                        searchParams.delete('token')
                        setSearchParams(searchParams, { replace: true })
                        reAuthToken = undefined
                    }
                })
        }
    }, [reAuthToken])

    const updateUser = (updatedUser) => {
        if (typeof updatedUser === 'undefined') {
            setUser(undefined)
        } else {
            setUser(new User(updatedUser))
        }
    }

    const logOut = async () => {
        await cancelAndRemovePendingOrder()
        await queryClient.invalidateQueries({ queryKey: ['loyaltyContact'] })
        await BillySDK.logout()
            .then(async () => {
                dispatch(
                    updateTokens({
                        access_token: undefined,
                    })
                )
                dispatch(removeAllLoyalty())
                dispatch(resetBasket())
                dispatch(closeAllModals())
                dispatch(resetCashlessCard())
                setUser(undefined)
            })
            .catch(() => {
                withScope(() => {
                    setTag('KioskMode', kioskMode ?? false)
                    setTag('userId', user?.id ?? 'Unknown')
                    captureException(new Error('logOut Error'))
                })
            })
    }

    return (
        <TokenContext.Provider value={{}}>
            <UserContext.Provider value={{ user, setUser, updateUser, fetchUser, logOut }}>
                {isLoading ? <LoadingScreen /> : <>{children}</>}
            </UserContext.Provider>
        </TokenContext.Provider>
    )
}

export default UserAndTokenContextProvider
