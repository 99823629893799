import { createContext, FC, ReactNode, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'
import IBrand from 'src/structures/Interfaces/IBrand'

import { getBrand } from '../structures/Enums/Brands.enum'
import BrandType from '../structures/Enums/BrandType.enum'

interface IBrandContextType {
    brand: IBrand
    isBilly: boolean
    isBees: boolean
    isQbh: boolean
    isTaDa: boolean
    isCroustico: boolean
    isKinepolisSingCity: boolean
}

interface IBrandContextProviderProperties {
    children: ReactNode
}

const defaultBrand = getBrand()

export const BrandContext = createContext<IBrandContextType>({
    brand: defaultBrand,
    isBilly: defaultBrand.identifier === BrandType.BILLY,
    isBees: defaultBrand.identifier === BrandType.BEES,
    isQbh: defaultBrand.identifier === BrandType.QBH,
    isTaDa: defaultBrand.identifier === BrandType.TADA,
    isCroustico: defaultBrand.identifier === BrandType.CROUSTICO,
    isKinepolisSingCity: defaultBrand.identifier === BrandType.KINEPOLIS_SING_CITY,
})

const BrandContextProvider: FC<IBrandContextProviderProperties> = ({ children }) => {
    const contextValue = useMemo(
        () => ({
            brand: defaultBrand,
            isBilly: defaultBrand.identifier === BrandType.BILLY,
            isBees: defaultBrand.identifier === BrandType.BEES,
            isQbh: defaultBrand.identifier === BrandType.QBH,
            isTaDa: defaultBrand.identifier === BrandType.TADA,
            isCroustico: defaultBrand.identifier === BrandType.CROUSTICO,
            isKinepolisSingCity: defaultBrand.identifier === BrandType.KINEPOLIS_SING_CITY,
        }),
        []
    )

    useEffect(() => {
        const importBrandStyles = async () => import(`../style/brands/${defaultBrand.styles}.scss`)
        if (isNotNullOrUndefined(defaultBrand.styles)) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            importBrandStyles()
        }
    }, [defaultBrand])

    return (
        <BrandContext.Provider value={contextValue}>
            <Helmet>
                <link
                    rel='shortcut icon'
                    href={`/brand/${defaultBrand.identifier}/favicon.png`}
                />
            </Helmet>
            {children}
        </BrandContext.Provider>
    )
}

export default BrandContextProvider
