import { createContext, ReactNode, useCallback, useContext, useLayoutEffect, useState } from 'react'
import { useMatch } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import useVenueQuery from 'src/hooks/useVenueQuery'

import RoutePath from '../structures/Enums/RoutePath.enum'
import { isNotNullOrUndefined } from '../structures/Guards/guards.utils'

import { ThemeContext } from './ThemeContextProvider'

interface IVenueContext {
    venueId: string | undefined
    setVenueId: (id: string | undefined) => void
    fetchVenueById: (id: string) => Promise<void>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    venue: any // TODO: replace `any` with a proper type
    isLoading: boolean
    isError: boolean
}

export const VenueContext = createContext<IVenueContext>({
    venueId: undefined,
    setVenueId: () => {},
    fetchVenueById: async () => {},
    venue: undefined,
    isLoading: false,
    isError: false,
})

const VenueContextProvider = ({ children }: { children: ReactNode }) => {
    const queryClient = useQueryClient()
    const { setTheme } = useContext(ThemeContext)

    const venueMatchPath = useMatch(RoutePath.VENUE)
    const voucherPurchaseDetailMatchPath = useMatch(RoutePath.VOUCHER_PURCHASES_DETAIL)
    const voucherTopUpDetailMatchPath = useMatch(RoutePath.VOUCHER_TOPUP_DETAIL)
    const creditPurchaseDetailMatchPath = useMatch(RoutePath.CREDIT_PURCHASES_DETAIL)
    const creditPurchaseMatchPath = useMatch(RoutePath.CREDIT_PURCHASES_OVERVIEW)

    const [venueId, setVenueId] = useState<string | undefined>()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { isLoading, isError, venue } = useVenueQuery(venueId) as any

    const fetchVenueById = useCallback(
        async (id: string) => {
            if (venue?.id !== id) {
                await queryClient.invalidateQueries({ queryKey: ['venue'] })
                setVenueId(id)
            }
        },
        [venue, queryClient]
    )

    useLayoutEffect(() => {
        if (
            isNotNullOrUndefined(venue) &&
            (isNotNullOrUndefined(venueMatchPath) ||
                isNotNullOrUndefined(voucherPurchaseDetailMatchPath) ||
                isNotNullOrUndefined(voucherTopUpDetailMatchPath) ||
                isNotNullOrUndefined(creditPurchaseDetailMatchPath) ||
                isNotNullOrUndefined(creditPurchaseMatchPath))
        ) {
            setTheme({
                ...venue.theming,
                placePath: isNotNullOrUndefined(venue.place?.id) ? `/places/${venue.place.id}` : undefined,
                venuePath: `/venues/${venue.id}${window.location.search}`,
                customDomain: venue.custom_domain,
                venueScreenDisabled: venue.has_multiple_events === false,
                customTags: venue.custom_tags,
            })
        }
    }, [
        venue,
        venueMatchPath,
        voucherPurchaseDetailMatchPath,
        voucherTopUpDetailMatchPath,
        creditPurchaseDetailMatchPath,
        creditPurchaseMatchPath,
    ])

    return (
        <VenueContext.Provider
            value={{
                venueId,
                setVenueId,
                fetchVenueById,
                venue,
                isLoading,
                isError,
            }}>
            {children}
        </VenueContext.Provider>
    )
}

export default VenueContextProvider
