import { FC } from 'react'
import Currency from 'src/components/currency/Currency'
import { divideByHundred, sanitizeHtml } from 'src/constants/helpers'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'
import IAdditionValue from 'src/structures/Interfaces/IAdditionValue'

import './additionvaluelabel.scss'

interface IAdditionValueLabelProperties {
    value: IAdditionValue
}

const AdditionValueLabel: FC<IAdditionValueLabelProperties> = ({ value }) => {
    if (isNotNullOrUndefined(value)) {
        return (
            <div className='c-product--addition-value-label'>
                {isNotNullOrUndefined(value.images?.small) ? (
                    <div>
                        <div className='c-product--addition-value-image'>
                            <img
                                src={value.images?.small}
                                loading='lazy'
                                alt='Addition value'
                            />
                        </div>
                    </div>
                ) : null}
                <div>
                    <div className='c-product--addition-value-title'>
                        <div className='c-product--addition-value-name'>
                            {value.name}
                            {value.price_int !== 0 ? (
                                <div className='c-product--addition-value-price'>
                                    <span style={{ marginRight: '5px' }}>{`( ${
                                        value.price_int < 0 ? '-' : '+'
                                    } `}</span>
                                    <Currency amount={Math.abs(divideByHundred(value.price_int))} />
                                    {' )'}
                                </div>
                            ) : null}
                        </div>
                        {value.is_popular === true ? <span className='is-popular'>POPULAR</span> : ''}
                    </div>
                    {isNotNullOrUndefined(value.description) && value.description !== '' ? (
                        <p
                            className='c-product--addition-value-description'
                            dangerouslySetInnerHTML={{ __html: sanitizeHtml(value.description) }}
                        />
                    ) : null}
                </div>
            </div>
        )
    }

    return null
}

export default AdditionValueLabel
