/* eslint-disable @typescript-eslint/no-explicit-any */
import { lazy } from 'react'

// Utility function to handle lazy loading with error handling
const lazyImport = (importFunction: any) =>
    lazy(async () => {
        try {
            // eslint-disable-next-line @typescript-eslint/return-await
            return await importFunction()
        } catch (error: any) {
            // In Vite, dynamic import errors are often TypeErrors
            if (error instanceof TypeError) {
                const newError = new Error('Lazy Component or Chunk failed to load (Vite)')
                // set errorName to "ChunkLoadError" so the errorboundary show the correct errorpage ('Billy is updated' Screen)
                newError.name = 'ChunkLoadError'
                throw newError
            }
            throw error // Other errors should still show the 'Something went wrong' page
        }
    })

export default lazyImport
