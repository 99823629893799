import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { t } from 'i18next'
import { ISelectedPromotionProducts } from 'src/components/03_organisms/PromotionProductSelectForm/PromotionProductSelectForm'
import { addToBasket, closeAllModals, openModal } from 'src/redux/actions/actionBuilders'
import Promotion from 'src/structures/Classes/Promotion'
import { v4 as uuidv4 } from 'uuid'

import BillySDK from '../sdk/sdk'
import ModalType from '../structures/Enums/ModalType'
import { isNotNullOrUndefined } from '../structures/Guards/guards.utils'
import IBasketProduct from '../structures/Interfaces/IBasketProduct'

import useCatalogueQuery from './useCatalogueQuery'

function usePromotions({ eventId }: { eventId: string }) {
    const {
        isLoading,
        error,
        isFetching,
        isError,
        data: promotions,
    } = useQuery({
        retry: 1,
        refetchOnWindowFocus: true,
        queryKey: ['promotions', eventId],
        enabled: isNotNullOrUndefined(eventId),
        queryFn: async () => BillySDK.getEventPromotions(eventId),
    })
    // @ts-expect-error redux root state is not typed
    const { basket } = useSelector((state) => state)

    const dispatch = useDispatch()
    const { getProductById } = useCatalogueQuery({ eventId })

    const addPromotionProductsToBasket = useCallback(
        (promotion: Promotion, chosenPromoProducts: ISelectedPromotionProducts, eventName = '') => {
            if (chosenPromoProducts.length === 0) return
            const claimId = uuidv4()
            // Find the cheapest product
            let cheapestProduct = chosenPromoProducts[0]
            for (const product of chosenPromoProducts) {
                const currentProduct = getProductById(product.id ?? promotion.products?.[0]?.id)
                const cheapestProductData = getProductById(cheapestProduct?.id ?? promotion.products?.[0]?.id)

                if (
                    isNotNullOrUndefined(currentProduct) &&
                    isNotNullOrUndefined(cheapestProductData) &&
                    currentProduct.price < cheapestProductData.price
                ) {
                    cheapestProduct = product
                }
            }

            // Loop through the products and apply promotion only to the cheapest product
            for (const chosenProduct of chosenPromoProducts) {
                const product = getProductById(chosenProduct.id ?? promotion.products?.[0]?.id)

                const promotionData =
                    chosenProduct === cheapestProduct
                        ? {
                              type: 'promotion',
                              type_reference_id: promotion.id,
                              promotion_name: promotion.name,
                              promotion: {
                                  name: promotion.name,
                                  id: promotion.id,
                                  promotionPriceReduction: promotion.promotionPriceReduction,
                                  coverImage: promotion.coverImage,
                              },
                          }
                        : {
                              promotion_reference_id: promotion.id,
                              promotion_name: promotion.name,
                              min_amount: chosenProduct.amount,
                          }

                if (isNotNullOrUndefined(product)) {
                    dispatch(
                        addToBasket({
                            ...product,
                            event_id: eventId,
                            eventName,
                            groupId: undefined,
                            additions: {},
                            has_additions: false,
                            totalAdditionsPrice: 0,
                            amount:
                                promotion.rules?.[0]?.config.requiresIdenticalProducts === true
                                    ? promotion.rules?.[0]?.config?.n ?? 1
                                    : chosenProduct.amount,
                            promotion_claim_id: claimId,
                            ...promotionData,
                        })
                    )
                }
            }
        },
        [eventId, getProductById, dispatch]
    )

    const onPromotionClick = useCallback(
        (promotion: Promotion, defaultPromoProductId = null) => {
            if (typeof promotion === 'undefined') return
            if (!promotion.available.status) return

            const promotionsInBasket = new Set()
            const keyedBasketProducts = basket?.[eventId]?.products as Array<IBasketProduct>

            if (isNotNullOrUndefined(keyedBasketProducts)) {
                for (const product of Object.values(keyedBasketProducts)) {
                    if (isNotNullOrUndefined(product.promotion) && !promotionsInBasket.has(product.id)) {
                        promotionsInBasket.add(product.id)
                    }
                }
            }

            if (promotionsInBasket.size >= promotion.promotionSource.max_per_order) {
                dispatch(
                    openModal({
                        type: ModalType.ERROR_MODAL,
                        data: {
                            title: t('modal.promotions.error.max_per_order_reached.title', 'Whoops!'),
                            message: t(
                                'modal.promotions.error.max_per_order_reached.body',
                                'Only one promotion is allowed per order!'
                            ),
                        },
                    })
                )
            } else {
                dispatch(
                    openModal({
                        type: ModalType.PROMO_MODAL,
                        data: {
                            promo: promotion,
                            defaultPromoProductId: defaultPromoProductId ?? null,
                            onSuccess: (chosenPromoProducts: ISelectedPromotionProducts) => {
                                addPromotionProductsToBasket(promotion, chosenPromoProducts)
                                dispatch(closeAllModals())
                            },
                        },
                    })
                )
            }
        },
        [eventId, dispatch, basket, addPromotionProductsToBasket]
    )

    return {
        isLoading,
        error,
        isFetching,
        isError,
        promotions: promotions?.data,
        onPromotionClick,
        addPromotionProductsToBasket,
    }
}

export default usePromotions
