import { FC, useContext } from 'react'
import classNames from 'classnames'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

import { KioskContext } from '../../../context/KioskContextProvider'
import Picture from '../Picture/Picture'

import './productmodalimage.scss'

interface IProductModalImageProperties {
    image?: string
    imageSize?: 'cover' | 'contain'
}

const ProductModalImage: FC<IProductModalImageProperties> = ({ image, imageSize }) => {
    const { kioskMode } = useContext(KioskContext)

    if (isNotNullOrUndefined(image)) {
        return (
            <div className={`productmodalimage image-${imageSize}`}>
                <div
                    className={classNames('productmodalimage-image', {
                        'productmodalimage-image--kiosk': kioskMode,
                    })}>
                    <Picture
                        src={image}
                        alt='Product'
                    />
                </div>
            </div>
        )
    }
    return null
}

export default ProductModalImage
