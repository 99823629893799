export const VENUES = (venueId) => (venueId ? `/api/venues/${venueId}` : `/api/venues`)
export const VALIDATE_STARNET_CARD = (venueId) => `/api/venues/${venueId}/verifystarnet`
export const VALIDATE_TOPUPABLE_VOUCHER = (venueId) => `/api/venues/${venueId}/vouchers/verify`
export const REDIRECT_URL_BY_SLUG = (slug) => `/api/slugs/${slug}`

export const EVENT_GROUPS = (eventGroupId) => `/api/eventgroups/${eventGroupId}`
export const EVENTS = (eventId) => (eventId ? `/api/events/${eventId}` : `/api/events`)
export const EVENT_PRODUCT = (id) => `/api/products/${id}`
export const EVENT_CATALOGUE = (eventId) => `/api/events/${eventId}/categorygroups`
export const EVENT_PSPS = (eventId) => `/api/events/${eventId}/psps`
export const EVENT_GROUP_PSPS = (eventGroupId) => `/api/eventgroups/${eventGroupId}/psps`

export const PLACES = (placeId) => `/api/places/${placeId}`

export const VENUE_NEWS = (venueId, type) => `/api/venues/${venueId}/news?type=${type}`
export const EVENT_NEWS = (eventId, type) => `/api/events/${eventId}/news?type=${type}`

export const VALIDATE_BASKET = '/api/basket/verify'
export const ORDER = (orderId) => (orderId ? `/api/orders/${orderId}` : `/api/orders`)
export const PAYMENT = (orderId) => `/api/orders/${orderId}/payment`
export const ORDER_STATUS = (orderId) => `/api/orders/${orderId}/status`
export const ORDER_LOYALTY_VALIDATION = (orderId) => `/api/orders/${orderId}/loyaltycard`
export const ORDER_REDUCTION = (orderId) => `/api/orders/${orderId}/reductions`

export const ORDER_GROUP = (orderId) => (orderId ? `/api/ordergroups/${orderId}` : `/api/ordergroups`)
export const PAYMENT_GROUP = (orderId) => `/api/ordergroups/${orderId}/payment`
export const ORDER_GROUP_STATUS = (orderId) => `/api/ordergroups/${orderId}/status`
export const ORDER_GROUP_REDUCTION = (orderGroupId) => `/api/ordergroups/${orderGroupId}/reductions`

export const USER = '/api/user'
export const LOGIN_PROVIDER = (provider) => `/api/user/login/${provider}`
export const LOGIN_FACEBOOK = '/api/user/login/facebook'
export const LOGIN_GOOGLE = '/api/user/login/google'
export const LOGIN = '/api/user/login'
export const LOGOUT = '/api/user/logout'
export const REGISTER = '/api/register'
export const GUEST_LOGIN = '/api/guest/login'
export const REAUTH = '/api/user/reauth'

export const PASSWORD_RESET_REQUEST = '/api/password/request'
export const PASSWORD_RESET = '/api/password/reset'

export const CREDITS = (venueId) => (venueId ? `/api/venues/${venueId}/credits` : `/api/credits`)
export const CREDIT_PURCHASE_CREATE = (venueId) => `/api/venues/${venueId}/creditpurchases`
export const CREDIT_PURCHASE_PSPS = (venueId) => `/api/venues/${venueId}/creditpurchasepsps`
export const CREDIT_PURCHASE_OPTIONS = (venueId) => `/api/venues/${venueId}/creditpurchaseoptions`
export const CREDIT_PURCHASE_PAYMENT = (creditPurchaseId) => `/api/creditpurchases/${creditPurchaseId}/payment`
export const CREDIT_PURCHASE_BY_ID = (creditPurchaseId) => `/api/creditpurchases/${creditPurchaseId}`

export const TOPUP_STARNET_CARD_PSPS = (venueId) => `/api/venues/${venueId}/starnettopuppsps`
export const TOPUP_STARNET_CARD_OPTIONS = (venueId) => `/api/venues/${venueId}/starnettopupoptions`
export const TOPUP_STARNET_CARD_PURCHASE_CREATE = (venueId) => `/api/venues/${venueId}/starnettopups`
export const TOPUP_STARNET_CARD_PURCHASE_BY_ID = (topupPurchaseId) => `/api/starnettopups/${topupPurchaseId}`
export const TOPUP_STARNET_CARD_PAYMENT_CREATE = (topupPurchaseId) => `/api/starnettopups/${topupPurchaseId}/payment`

export const VOUCHER = (voucher) => `/api/vouchers/${voucher}`
export const VOUCHER_PURCHASE_BY_ID = (voucherPurchaseId) => `/api/voucherpurchases/${voucherPurchaseId}`

export const TOPUP_VOUCHER_PSPS = (venueId) => `/api/venues/${venueId}/vouchertopuppsps`
export const TOPUP_VOUCHER_OPTIONS = (venueId) => `/api/venues/${venueId}/vouchertopupoptions`
export const TOPUP_VOUCHER_PURCHASE_CREATE = (venueId) => `/api/venues/${venueId}/vouchertopups`
export const TOPUP_VOUCHER_PURCHASE_BY_ID = (topupPurchaseId) => `/api/vouchertopups/${topupPurchaseId}`
export const TOPUP_VOUCHER_PAYMENT_CREATE = (topupPurchaseId) => `/api/vouchertopups/${topupPurchaseId}/payment`

export const RATING = (venueId) => `/api/venues/${venueId}/ratings`
export const OPTIN_CONSENT = (optinId) => `/api/optins/${optinId}/consent`

export const PROMOTIONS = (eventId) => `/api/events/${eventId}/promotions`
export const PROMOTIONS_OTP_GENERATE = `/api/otp/generate`
export const PROMOTIONS_OTP_VERIFY = `/api/otp/verify`

export const LOYALTY_VENUE = (venueId) => `/api/venues/${venueId}/loyalty`
export const LOYALTY_USER = (venueId) => `/api/venues/${venueId}/loyalty/me`
export const LOYALTY_VERIFY_EMAIL = (venueId) => `/api/venues/${venueId}/loyalty/verify`
export const LOYALTY_CONTACT_ACTIVATION = (venueId) => `/api/venues/${venueId}/loyalty/activation`

export const COMPETITION = (promotionClaimUuid) => `/api/competitions/${promotionClaimUuid}`

// DEVICES API
export const DEVICE = `/devices/me`
