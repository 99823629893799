import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

// import { ToastContainer } from 'react-toastify'
import ExtendedTracking from './components/ExtendedTracking/ExtendedTracking'
import Header from './components/header/Header'
import OrderCancelManager from './components/OrderCancelManager/OrderCancelManager'
import ScrollToTop from './components/scrolltotop/ScrollToTop'
import Seo from './components/Seo/Seo'
import DrawerMenu from './containers/drawerMenu/DrawerMenu'
import ForceScrollBlock from './containers/modal/ForceScrollBlock'
import Modals from './containers/modal/Modals'
import ContextProviders from './context/ContextProviders'
import Routers from './Routes/Routers'
import { isNotNullOrUndefined } from './structures/Guards/guards.utils'

import './style/app.scss'

const App = () => {
    const { i18n } = useTranslation()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const locale = useSelector((state: any) => state.locale)

    useEffect(() => {
        // eslint-disable-next-line no-void
        if (isNotNullOrUndefined(locale?.preferedLanguage)) void i18n.changeLanguage(locale.preferedLanguage)
    }, [])

    return (
        <BrowserRouter>
            <ContextProviders>
                <ForceScrollBlock />
                <OrderCancelManager />
                <Seo />
                <Header />
                <Routers />
                <Modals />
                <DrawerMenu />
                <ScrollToTop />
                <ExtendedTracking />
            </ContextProviders>
        </BrowserRouter>
    )
}

export default App
