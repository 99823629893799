import './storycontrol.scss'

function StoryControl({ onClick, icon }) {
    return (
        <button
            className='video-story-control'
            onClick={onClick}>
            {icon ?? 'button'}
        </button>
    )
}

export default StoryControl
