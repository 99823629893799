import { useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useMatch } from 'react-router-dom'
import classNames from 'classnames'

import { BrandContext } from '../../context/BrandContextProvider.tsx'
import { DrawerMenuContext } from '../../context/DrawerMenuContextProvider'
import { HeaderContext } from '../../context/HeaderContext'
import { ThemeContext } from '../../context/ThemeContextProvider'
import useNavigationBehaviour from '../../hooks/useNavigationBehaviour'
import RoutePath from '../../structures/Enums/RoutePath.enum.ts'
import HeaderCenterElement from '../02_molecules/HeaderCenterElement/HeaderCenterElement.tsx'
import HeaderBrandingLabel from '../HeaderBrandingLabel/HeaderBrandingLabel'

import Backbutton from './Backbutton'
import HeaderScrollBackground from './HeaderScrollBackground'
import MenuIcon from './menuicon'

import './header.scss'

function Header() {
    const eventMatchPath = useMatch(`${RoutePath.EVENT}/`)
    const eventsMatchPath = useMatch(`${RoutePath.EVENTS}`)
    const eventGroupEventMatchPath = useMatch(`${RoutePath.EVENT_GROUP_EVENT}/*`)
    const eventGroupMatchPath = useMatch(RoutePath.EVENT_GROUP)
    const placeMatchPath = useMatch(RoutePath.PLACE)
    const venueMatchPath = useMatch(RoutePath.VENUE)
    const selfScanMatchPath = useMatch(RoutePath.SELF_SCAN)

    const headerContext = useContext(HeaderContext)

    const headerRef = useRef()
    const location = useLocation()
    const { device } = useSelector((state) => state.kiosk)
    const { drawerMenuOpen, setDrawerMenuOpen, setHeaderRef } = useContext(DrawerMenuContext)
    const { isTaDa } = useContext(BrandContext)
    const { theme } = useContext(ThemeContext)
    const { onBackNavigation } = useNavigationBehaviour()

    const { kioskMode } = useSelector((state) => state.kiosk)
    const [withbackButton, setWithBackButton] = useState(false)

    useEffect(() => {
        document.documentElement.style.setProperty('--header-height', `${kioskMode === true ? '65px' : '50px'}`)
    }, [])

    useEffect(() => {
        setHeaderRef(headerRef)
    }, [headerRef])

    useEffect(() => {
        if (location) {
            let shouldShowBackButton = location.pathname !== RoutePath.HOMEPAGE
            if (kioskMode) {
                if (eventGroupMatchPath !== null) {
                    shouldShowBackButton = !eventGroupMatchPath === null
                }

                if (eventsMatchPath !== null) {
                    shouldShowBackButton = false
                }

                if (selfScanMatchPath !== null) {
                    shouldShowBackButton = false
                }

                if (eventMatchPath !== null && !device?.hasMultipleEvents) {
                    shouldShowBackButton = false
                }
            }
            setDrawerMenuOpen(false)
            setWithBackButton(shouldShowBackButton)
        }
    }, [
        location,
        kioskMode,
        setDrawerMenuOpen,
        setWithBackButton,
        eventGroupMatchPath,
        eventMatchPath,
        eventsMatchPath,
        selfScanMatchPath,
    ])

    if (headerContext.isHeaderHidden) {
        return null
    }

    return (
        <header
            ref={headerRef}
            id='c-header'
            className={classNames('c-header', {
                'u-transparent':
                    !drawerMenuOpen &&
                    (eventGroupMatchPath !== null ||
                        eventGroupEventMatchPath !== null ||
                        eventMatchPath !== null ||
                        venueMatchPath !== null ||
                        placeMatchPath !== null),
                'c-header--kiosk': kioskMode === true,
            })}>
            <div
                id='c-header-fixed'
                className='c-header-fixed'>
                <HeaderScrollBackground />

                <div className='c-header--content'>
                    {withbackButton && headerContext.isBackButtonHidden === false ? (
                        <Backbutton
                            disabled={headerContext.isHeaderDisabled}
                            handleClick={onBackNavigation}
                        />
                    ) : (
                        <div style={{ minWidth: kioskMode ? '150px' : '50px' }}>
                            <span className='c-header--icon' />
                        </div>
                    )}

                    <div className={classNames('c-header--venue is-center')}>
                        <HeaderCenterElement />

                        {theme?.logo && isTaDa ? (
                            <HeaderBrandingLabel>
                                <img src='/brand/tada/powered_by_tada_white.svg' />
                            </HeaderBrandingLabel>
                        ) : null}
                    </div>

                    <MenuIcon
                        isOpen={drawerMenuOpen}
                        handleClick={() => setDrawerMenuOpen(!drawerMenuOpen)}
                    />
                </div>
            </div>
        </header>
    )
}

export default Header
