import { FC } from 'react'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

import { ALLERGENS } from './AllergensData'

import './productallergens.scss'

interface IProductAllergensProperties {
    allergens: Array<string> | null
}

const ProductAllergens: FC<IProductAllergensProperties> = ({ allergens = null }) => {
    const items = ALLERGENS?.filter((allergen) => allergens?.includes(allergen.id))

    if (isNotNullOrUndefined(allergens) && items.length > 0) {
        return (
            <div className='c-product-allergens'>
                {items.map((item) => (
                    <div
                        className='c-allergen'
                        key={item.id}>
                        <div
                            className='c-allergen-inner'
                            style={{ backgroundColor: item.color ?? 'white' }}>
                            <div
                                className='c-allergen--icon'
                                style={{ backgroundColor: item.color ?? undefined }}>
                                <img
                                    src={`/allergens/${item.icon}.svg`}
                                    alt={item.display_name}
                                />
                            </div>
                            <div className='c-allergen--label'>{item.display_name}</div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    return null
}

export default ProductAllergens
