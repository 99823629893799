import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import KioskTouchToBegin from 'src/components/KioskTouchToBegin/KioskTouchToBegin'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

import KioskClosed from '../../components/KioskClosed/KioskClosed'
import useIsTouchDevice from '../../hooks/useIsTouchDevice'

import './kioskintro.scss'

interface IKioskIntroProperties {
    isKioskOpen?: boolean
    isKioskDisabled?: boolean
    onClick: () => void
}

const KioskIntro: FC<IKioskIntroProperties> = ({ isKioskOpen = true, isKioskDisabled = false, onClick = () => {} }) => {
    const { t } = useTranslation()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { device } = useSelector((state: any) => state.kiosk)
    const isTouch = useIsTouchDevice()

    const handleClick = useCallback(
        (event: React.MouseEvent | React.TouchEvent) => {
            // event.preventDefault()
            event.stopPropagation()
            if (
                (event.type === 'click' && isKioskOpen && !isKioskDisabled) ||
                (isKioskOpen &&
                    !isKioskDisabled &&
                    event.type !== 'click' &&
                    (event as React.TouchEvent).targetTouches?.length === 0)
            ) {
                onClick()
            }
        },
        [isKioskOpen, isKioskDisabled]
    )

    if (isNotNullOrUndefined(device) && isNotNullOrUndefined(device.config)) {
        return (
            <div
                className='c-kiosk-intro'
                onClick={isTouch ? undefined : handleClick}
                onTouchEnd={isTouch ? handleClick : undefined}>
                {isKioskOpen && !isKioskDisabled ? (
                    <KioskTouchToBegin
                        videoUrl={device.config.intro_video_url}
                        imageUrl={device.theme?.image}
                        logo={device.config.intro_shows_logo === true ? device.theme?.logo : null}
                    />
                ) : (
                    <KioskClosed
                        label={t(isKioskDisabled ? 'kiosk.disabled.title' : 'kiosk.closed.title')}
                        image={device.theme?.image}
                    />
                )}
            </div>
        )
    }

    return (
        <div
            onClick={isTouch ? undefined : handleClick}
            onTouchEnd={isTouch ? handleClick : undefined}
            className='c-kiosk-intro'
            style={{ background: `#${device?.theme?.color ?? 'EA5056'}` }}
        />
    )
}

export default KioskIntro
