import { BsPhone } from 'react-icons/bs'
import classNames from 'classnames'

import './qrscanhelp.scss'

function QrScanhelp({ className, label }) {
    return (
        <div className={classNames('qr-scan-help', className)}>
            <BsPhone /> <h3>{label}</h3>
        </div>
    )
}

export default QrScanhelp
