import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { matchPaths } from '../../constants/matchPaths'
import usePendingOrder from '../../hooks/usePendingOrder'
import usePreviousLocation from '../../hooks/usePreviousLocation'
import RoutePath from '../../structures/Enums/RoutePath.enum.ts'

function OrderCancelManager() {
    const location = useLocation()
    const previousLocation = usePreviousLocation(location)
    const { cancelAndRemovePendingOrder } = usePendingOrder()

    const currentPathname = location.pathname
    const previousPathname = previousLocation?.pathname ?? null

    useEffect(() => {
        if (currentPathname !== previousPathname) {
            const previousPathIsOrder = previousPathname ? matchPaths([RoutePath.ORDER], previousPathname) : null
            const previousPathIsOrderGroup = previousPathname
                ? matchPaths([RoutePath.ORDER_GROUP], previousPathname)
                : null

            const currentPathIsEvent = currentPathname
                ? matchPaths([RoutePath.EVENT, RoutePath.EVENT_GROUP, RoutePath.EVENT_GROUP_EVENT], currentPathname)
                : null

            if ((previousPathIsOrder && currentPathIsEvent) || (previousPathIsOrderGroup && currentPathIsEvent)) {
                cancelAndRemovePendingOrder()
            }
        }
    }, [currentPathname, cancelAndRemovePendingOrder])

    return null
}

export default OrderCancelManager
