const reloadOnChunkError = (retries = 1) => {
    const errorRetries: string | null = sessionStorage.getItem('refreshPageRetries')
    if (typeof errorRetries === 'string') {
        if (Number.parseInt(errorRetries, 10) >= retries) {
            // give up, stop refreshing
            return
        }
        sessionStorage.setItem('refreshPageRetries', String(Number.parseInt(errorRetries, 10) + 1))
    } else {
        sessionStorage.setItem('refreshPageRetries', '1')
    }
    window.location.reload()
}

const initVitePreloadListener = () => {
    window.addEventListener('vite:preloadError', () => {
        reloadOnChunkError(1)
    })
}

export default initVitePreloadListener
