enum OrderableType {
    CREDIT_PURCHASE = 'creditpurchase',
    VOUCHER_PURCHASE = 'voucherpurchase',
    STARNET_TOPUP = 'starnettopup',
    VOUCHER_TOPUP = 'vouchertopup',
    ORDER_GROUP = 'ordergroup',
    ORDER = 'order',
}

export default OrderableType
