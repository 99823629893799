import { getDefaultTheme } from '../../constants/themeUtils'
import { SET_THEME } from '../actions/themeActions'

export default function themeReducer(state = getDefaultTheme(), { type, payload }) {
    switch (type) {
        case SET_THEME:
            // mutate state
            state = payload

            return state

        default:
            return state
    }
}
