import { REMOVE_ALL_LOYALTY, REMOVE_LOYALTY_FOR_VENUE, SET_LOYALTY_FOR_VENUE } from '../actions/loyaltyActions'

export default function loyaltyReducer(state = {}, { type, payload }) {
    // NOSONAR
    switch (type) {
        case SET_LOYALTY_FOR_VENUE:
            state = {
                ...state,
                [payload.id]: {
                    loyaltyCardNumber: String(payload.loyaltyCardNumber),
                },
            }

            return state

        case REMOVE_LOYALTY_FOR_VENUE:
            if (state[payload.id]) {
                state = { ...state }
                delete state[payload.id]
            }

            return state

        case REMOVE_ALL_LOYALTY:
            state = {}
            return state

        default:
            return state
    }
}
