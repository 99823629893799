import { FC, ReactNode } from 'react'
import classNames from 'classnames'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

import './loyaltycardstackgraphic.scss'

interface ILoyaltyCardStackGraphicProperties {
    className?: string
    iconClassName?: string
    icon?: ReactNode
}

const LoyaltyCardStackGraphic: FC<ILoyaltyCardStackGraphicProperties> = ({
    icon,
    className,
    iconClassName,
    ...properties
}) => (
    <div
        className={classNames('cardstackgraphic', className)}
        {...properties}>
        {isNotNullOrUndefined(icon) ? (
            <div className={classNames('cardstackgraphic-icon', iconClassName)}>{icon}</div>
        ) : null}
    </div>
)

export default LoyaltyCardStackGraphic
