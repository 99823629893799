import { useCallback } from 'react'
import OrderableType from 'src/structures/Enums/OrderableType.enum'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'
import ICreatePaymentResponse from 'src/structures/Interfaces/IPaymentResponse'

import BillySDK from '../sdk/sdk'

import useIsMounted from './useIsMounted'

function usePayment(orderId: string | number) {
    const isMounted = useIsMounted()

    const cancelPayment = useCallback(
        async (type: OrderableType | undefined) =>
            new Promise((resolve, reject) => {
                if (isNotNullOrUndefined(orderId)) {
                    BillySDK.cancelPayment(orderId, type ?? OrderableType.ORDER)
                        .then(() => {
                            if (isMounted()) resolve(true)
                        })
                        .catch((error) => {
                            if (isMounted()) reject(new Error(error?.message ?? 'Payment cancellation failed'))
                        })
                } else {
                    reject(new Error('Invalid Order ID'))
                }
            }),
        [orderId, isMounted]
    )

    const createPayment = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async (type: OrderableType | undefined, values: any): Promise<ICreatePaymentResponse> =>
            new Promise((resolve, reject) => {
                if (isNotNullOrUndefined(orderId)) {
                    BillySDK.createPayment(orderId, type ?? OrderableType.ORDER, values)
                        .then((response: ICreatePaymentResponse) => {
                            if (isMounted()) resolve(response)
                        })
                        .catch(async (error) => {
                            if (isNotNullOrUndefined(error) && error.status !== 422) {
                                await cancelPayment(type ?? OrderableType.ORDER).catch((cancelpaymentError) => {
                                    console.log(cancelpaymentError)
                                })
                            }
                            if (isMounted()) reject(error)
                        })
                } else if (isMounted()) reject(new Error('Invalid Order ID'))
            }),
        [orderId, isMounted]
    )

    return {
        createPayment,
        cancelPayment,
    }
}

export default usePayment
