import { Link } from 'react-router-dom'

function DrawerMenuItem({ handleClick, icon, label, link, hidden = false }) {
    if (hidden) {
        return null
    }

    if (link) {
        if (link[0] !== '/') {
            // external link !
            return (
                <a
                    href={link}
                    className='c-settings--item'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <span className='c-settings--item-icon'>{icon}</span>
                    <span className='c-settings--item-label'>{label}</span>
                </a>
            )
        }
        return (
            <Link
                to={link}
                className='c-settings--item'
                onClick={handleClick}>
                <span className='c-settings--item-icon'>{icon}</span>
                <span className='c-settings--item-label'>{label}</span>
            </Link>
        )
    }
    return (
        <div
            className='c-settings--item'
            onClick={handleClick}>
            <span className='c-settings--item-icon'>{icon}</span>
            <span className='c-settings--item-label'>{label}</span>
        </div>
    )
}

export default DrawerMenuItem
