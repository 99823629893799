import { FC, ReactNode, useContext, useMemo } from 'react'
import classNames from 'classnames'
import { ThemeContext } from 'src/context/ThemeContextProvider'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

import LoyaltyCardStackGraphic from '../LoyaltyCardStackGraphic/LoyaltyCardStackGraphic'

import './loyaltycardgraphic.scss'

interface ILoyaltyCardGraphicProperties {
    className?: string
    points?: number | undefined
    pointsLabel?: string
    icon?: ReactNode
}

const LoyaltyCardGraphic: FC<ILoyaltyCardGraphicProperties> = ({
    icon,
    className,
    points,
    pointsLabel = 'Points',
    ...properties
}) => {
    const { theme } = useContext(ThemeContext)

    const defaultIcon = useMemo(
        () => (
            <img
                alt='icon'
                src={theme.logo}
            />
        ),
        [theme.logo]
    )

    return (
        <div className='loyaltycardgraphic-wrapper'>
            <div
                className={classNames('loyaltycardgraphic', className)}
                {...properties}>
                <div className='loyaltycardgraphic-visual'>
                    <LoyaltyCardStackGraphic
                        icon={isNotNullOrUndefined(icon) ? icon : defaultIcon}
                        iconClassName='loyaltycardgraphic-icon'
                        className='loyaltycardgraphic-cardstack'
                    />
                </div>

                <div className='loyaltycardgraphic-data'>
                    <div>
                        <p className='points-label'>{pointsLabel}</p>
                        {isNotNullOrUndefined(points) ? <p className='points'>{points}</p> : null}
                    </div>
                </div>
            </div>
            <div className='backdropfan'>
                <span className='backdrop' />
                <span className='backdrop' />
            </div>
        </div>
    )
}

export default LoyaltyCardGraphic
