import { FC, useCallback, useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { UserContext } from 'src/context/UserAndTokenContextProvider'
import useLoyalty, { ILoyaltyOtpCredentials } from 'src/hooks/useLoyalty'
import { closeAllModals, closeModal, openModal } from 'src/redux/actions/actionBuilders'
import ModalType from 'src/structures/Enums/ModalType'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

import LoyaltyRegistrationIntro from './LoyaltyRegistrationIntro'
import LoyaltyRegistrationOtp from './LoyaltyRegistrationOtp'
import LoyaltyRegistrationSignup from './LoyaltyRegistrationSignup'

import './loyaltyregistration.scss'

enum LoyaltyOnBoardingStep {
    ONBOARDING = 'ONBOARDING',
    SIGNUP = 'SIGNUP',
    OTP = 'OTP',
}

interface ILoyaltyRegistrationProperties {
    onSuccessfulRegistration: () => void
}
const LoyaltyRegistration: FC<ILoyaltyRegistrationProperties> = ({ onSuccessfulRegistration }) => {
    const { user } = useContext(UserContext)
    const [userEmail, setUserEmail] = useState<string | undefined>()
    const [step, setStep] = useState<LoyaltyOnBoardingStep>(LoyaltyOnBoardingStep.ONBOARDING)

    const { activateLoyaltyContact, validateLoyaltyContact } = useLoyalty()
    const dispatch = useDispatch()

    const handleError = useCallback(() => {
        dispatch(
            openModal({
                type: ModalType.ERROR_MODAL,
                data: {
                    onConfirm: () => {
                        dispatch(closeAllModals())
                    },
                },
            })
        )
    }, [dispatch])

    const onOnboardingComplete = useCallback(() => {
        if (isNotNullOrUndefined(user)) {
            setStep(LoyaltyOnBoardingStep.SIGNUP)
        } else {
            dispatch(closeModal())
            dispatch(
                openModal({
                    type: ModalType.AUTHENTICATION_MODAL,
                    data: {
                        searchParams: '?modal=loyalty',
                    },
                })
            )
        }
    }, [user])

    const onSignupComplete = useCallback(
        async (email: string) => {
            await activateLoyaltyContact({ email })
                .then((response) => {
                    if (!isNotNullOrUndefined(response)) {
                        handleError()
                        return
                    }
                    if (response?.is_existing_contact === true) {
                        setUserEmail(email)
                        setStep(LoyaltyOnBoardingStep.OTP)
                    } else {
                        onSuccessfulRegistration()
                    }
                })
                .catch(() => {
                    handleError()
                })
        },
        [activateLoyaltyContact]
    )

    const onOtpComplete = useCallback(
        async (credentials: ILoyaltyOtpCredentials) => {
            await validateLoyaltyContact(credentials)
                .then(() => {
                    onSuccessfulRegistration()
                })
                .catch(() => {
                    handleError()
                })
        },
        [onSuccessfulRegistration]
    )

    const steps = () => {
        switch (step) {
            case LoyaltyOnBoardingStep.SIGNUP: {
                return <LoyaltyRegistrationSignup handleSignup={onSignupComplete} />
            }

            case LoyaltyOnBoardingStep.OTP: {
                return (
                    <LoyaltyRegistrationOtp
                        email={userEmail}
                        handleOtp={onOtpComplete}
                    />
                )
            }

            default: {
                return <LoyaltyRegistrationIntro handleOnboarding={onOnboardingComplete} />
            }
        }
    }

    return <section>{steps()}</section>
}

export default LoyaltyRegistration
