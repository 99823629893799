import { SET_PREFERED_LANGUAGE } from '../actions/localeActions'

export default function localeReducer(state = {}, { type, payload }) {
    switch (type) {
        case SET_PREFERED_LANGUAGE:
            state = {
                ...state,
                preferedLanguage: payload,
            }

            return state

        default:
            return state
    }
}
