import { addTimeToCurrentDateTime } from '../../constants/helpers'
import TimeUnit from '../../structures/Enums/TimeUnit.enum.ts'
import { REMOVE_TABLE_NUMBER, SET_TABLE_NUMBER } from '../actions/tableNumberActions'

export default function tableNumberReducer(state = {}, { type, payload }) {
    switch (type) {
        case SET_TABLE_NUMBER:
            state = {
                ...state,
                [payload.id]: {
                    tableNumber: String(payload.tableNumber),
                    expiresAt:
                        payload.canExpire === false
                            ? addTimeToCurrentDateTime(2, TimeUnit.YEARS)
                            : addTimeToCurrentDateTime(1, TimeUnit.HOURS),
                },
            }

            return state

        case REMOVE_TABLE_NUMBER:
            if (state[payload.id]) {
                state = { ...state }
                delete state[payload.id]
            }

            return state

        default:
            return state
    }
}
