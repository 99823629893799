import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import useFetch from './useFetch'

function useTranslationOverride(eventId) {
    const { i18n } = useTranslation()
    const { data } = useFetch(
        import.meta.env.REACT_APP_TRANSLATIONS_OVERRIDE_URL
            ? `${import.meta.env.REACT_APP_TRANSLATIONS_OVERRIDE_URL}/translations.json`
            : undefined
    )

    const overrideTranslations = (overrides) => {
        const languageKeys = Object.keys(overrides)
        languageKeys.forEach((lang) => {
            i18n.addResourceBundle(lang, 'overrides', overrides[lang], true, true)
        })
    }

    const removeOverrides = (overrides) => {
        if (overrides) {
            const languageKeys = Object.keys(overrides)
            languageKeys.forEach((lang) => {
                i18n.removeResourceBundle(lang, 'overrides')
            })
        }
    }

    useEffect(() => {
        if (data && eventId) {
            if (data[eventId]) overrideTranslations(data[eventId])
        }

        return () => {
            if (data && eventId) removeOverrides(data[eventId])
        }
    }, [eventId, data])
}

export default useTranslationOverride
