import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { FaTimes } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

import { useScreenHeight } from '../../../components/common/withScreenHeight'
import TopupableVoucher from '../../../components/TopupableVoucher/TopupableVoucher'
import { slideInBottom } from '../../../constants/Animations.ts'
import { closeModal } from '../../../redux/actions/actionBuilders'
import RoutePath from '../../../structures/Enums/RoutePath.enum.ts'

import './modal.scss'

function VoucherPaymentModal({ data }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const screenHeight = useScreenHeight()

    const onSuccess = useCallback((code) => {
        data.onSuccess?.(code)
        dispatch(closeModal())
    }, [])

    const onAbort = useCallback(() => {
        dispatch(closeModal())
    }, [])

    const onTopUpVoucher = useCallback((code) => {
        if (data.order?.id) {
            dispatch(closeModal())
            navigate(
                `${RoutePath.VOUCHER_TOPUP_OVERVIEW}?orderref=${data.order.id}&ordertype=${data.order.type}&code=${code}`
            )
        }
    }, [])

    return (
        <motion.div
            layout='position'
            style={{ maxHeight: `${parseInt(screenHeight * 0.9, 10)}px` }}>
            <motion.div
                className='c-modal--item c-modal--cashless'
                variants={slideInBottom}
                initial='hidden'
                animate='visible'
                exit='exit'>
                <div className='c-fake-modal-backdrop'>
                    <div className='c-close-modal-header'>
                        <button
                            aria-label={t('general.button.close.label')}
                            onClick={() => dispatch(closeModal())}
                            className='c-button-close-modal-small'>
                            <FaTimes />
                        </button>
                    </div>
                    <motion.div className='c-modal--item-content'>
                        <TopupableVoucher
                            order={data?.order}
                            onSuccess={onSuccess}
                            onAbort={onAbort}
                            onTopUpVoucher={onTopUpVoucher}
                        />
                    </motion.div>
                </div>
            </motion.div>
        </motion.div>
    )
}

export default VoucherPaymentModal
