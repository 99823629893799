import { useContext } from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'

import { HERO_TYPE_MINIMAL, HERO_TYPE_NO_OVERLAY } from '../../constants/themeUtils'
import { ThemeContext } from '../../context/ThemeContextProvider'

import './header.scss'

function HeaderScrollBackground() {
    const { scrollY } = useScroll()
    const { theme } = useContext(ThemeContext)

    let scrollTravel = 210
    if (theme.heroStyle === HERO_TYPE_MINIMAL || theme.heroStyle === HERO_TYPE_NO_OVERLAY) scrollTravel = 0

    const opacity = useTransform(scrollY, [0, scrollTravel], [0, 1])

    return (
        <motion.div
            style={{ opacity }}
            className='c-header--scroll-bg'
        />
    )
}

export default HeaderScrollBackground
