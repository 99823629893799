import { useEffect, useRef } from 'react'

const usePreviousLocation = (location) => {
    const prevLocRef = useRef(location)

    useEffect(() => {
        prevLocRef.current = location
    }, [location])

    return prevLocRef.current
}

export default usePreviousLocation
