import { useContext } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'

import { ThemeContext } from '../../context/ThemeContextProvider'
import ImpulseSpinner from '../01_atoms/ImpulseSpinner/ImpulseSpinner.tsx'

import './spinner.scss'

function Spinner({ staticPosition = false, style, color }) {
    const { theme } = useContext(ThemeContext)

    const spinnerColor = color ?? `#${theme.color}`

    return (
        <motion.div
            initial={{
                scale: 0,
                opacity: 0,
            }}
            animate={{
                scale: 1,
                opacity: 1,
            }}
            style={style}
            className={classNames('c-spinner', staticPosition && 'c-spinner--static')}>
            <ImpulseSpinner
                size={50}
                frontColor={spinnerColor}
                backColor={spinnerColor}
                loading
            />
        </motion.div>
    )
}

export default Spinner
