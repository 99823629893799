import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaTimes } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import Button from 'src/components/01_atoms/Button/Button'
import { ExpandableText } from 'src/components/01_atoms/ExpandableText/ExpandableText'
import Modal from 'src/components/01_atoms/Modal/Modal'
import ModalControls from 'src/components/01_atoms/ModalControls/ModalControls'
import ProductAllergens from 'src/components/01_atoms/ProductAllergens/ProductAllergens'
import ProductModalImage from 'src/components/01_atoms/ProductModalImage/ProductModalImage'
import ProductTags from 'src/components/01_atoms/ProductTags/ProductTags'
import QuantitySelector from 'src/components/QuantitySelector/QuantitySelector'
import { closeModal, removeProduct, updateBasket } from 'src/redux/actions/actionBuilders'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'
import IModalDataProperties from 'src/structures/Interfaces/IModalDataProperties'
import IProduct from 'src/structures/Interfaces/IProduct'

import './productquantitymodal.scss'

const ProductQuantityModal: FC<IModalDataProperties> = ({ data }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const product: IProduct = data?.product ?? null
    const isOrderable = data?.isOrderable ?? true

    const [amount, setAmount] = useState<number>(data.amount)

    const onUpdateAmount = useCallback((adjuster: number) => {
        setAmount((previousState) => Math.max(previousState + adjuster, 0))
    }, [])

    const handleClose = useCallback(() => {
        data.onDeny?.()
        dispatch(closeModal())
    }, [dispatch, data])

    const handleConfirm = useCallback(() => {
        if (amount === 0) {
            dispatch(removeProduct(data.product))
        } else {
            dispatch(
                updateBasket({
                    ...data.product,
                    amount,
                })
            )
        }

        data.onConfirm?.()
        dispatch(closeModal())
    }, [data, amount, dispatch])

    if (isNotNullOrUndefined(product)) {
        return (
            <Modal
                containerClassName='productquantitymodal-container'
                contentClassName='productquantitymodal-content'>
                <div className='inner'>
                    <div className='productquantitymodal-close'>
                        <button
                            aria-label={t('general.button.close.label')}
                            onClick={handleClose}
                            className='c-button-close-modal-small'>
                            <FaTimes />
                        </button>
                    </div>

                    <ProductModalImage
                        image={product.images?.medium ?? undefined}
                        imageSize={isNotNullOrUndefined(product.image_size) ? product.image_size : 'cover'}
                    />

                    <div className='productquantitymodal-title'>
                        <h2 className='productquantitymodal-title-product-name'>{product.name}</h2>
                    </div>

                    {isNotNullOrUndefined(product.description) && product.description.length > 0 ? (
                        <div className='productquantitymodal-description'>
                            <ExpandableText
                                id={String(product.id)}
                                text={product.description}
                            />
                        </div>
                    ) : null}

                    <ProductAllergens allergens={product.allergens} />
                    <ProductTags tags={product.tags} />

                    <ModalControls>
                        {isOrderable === false || data.maxOrderableAmount === 0 ? (
                            <div className='productquantitymodal-controls'>
                                <Button
                                    className='add-button'
                                    label={t('button.order.status.back_to_event.cta')}
                                    onClick={handleClose}
                                    variant='dark'
                                />
                            </div>
                        ) : (
                            <div className='productquantitymodal-controls'>
                                {isNotNullOrUndefined(product.loyalty_reward_credits_price) ? null : (
                                    <div className='c-quantityselector-wrapper'>
                                        <QuantitySelector
                                            type='small'
                                            amount={amount}
                                            range={{
                                                min: product.min_amount ?? 0,
                                                max: data.maxOrderableAmount,
                                            }}
                                            onQuantityChange={onUpdateAmount}
                                        />
                                        {data.maxOrderableAmount <= 20 ? (
                                            <p className='c-quantityselector-maxorderablelabel'>
                                                {t('product.maximum_allowed_in_basket.label', {
                                                    amount: data.maxOrderableAmount,
                                                })}
                                            </p>
                                        ) : null}
                                    </div>
                                )}

                                <Button
                                    className='add-button'
                                    label={t('button.confirm.cta')}
                                    onClick={handleConfirm}
                                    variant='dark'
                                />
                            </div>
                        )}
                    </ModalControls>
                </div>
            </Modal>
        )
    }

    return null
}

export default ProductQuantityModal
