import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import { motion } from 'framer-motion'
import * as Yup from 'yup'

import Input from '../../../components/forms/elements/Input'
import { popIn } from '../../../constants/Animations.ts'
import { closeModal } from '../../../redux/actions/actionBuilders'
import BillySDK from '../../../sdk/sdk'
import OrderableType from '../../../structures/Enums/OrderableType.enum.ts'

import '../../../components/forms/elements/formelements.scss'
import './modal.scss'

function ApplyReductionModal({ data = {} }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const handleOnConfirm = () => {
        if (data.onConfirm) data.onConfirm()
        dispatch(closeModal())
    }

    const handleOnReject = () => {
        if (data.onReject) data.onReject()
        dispatch(closeModal())
    }

    return (
        <motion.div
            className='c-modal--item'
            variants={popIn}
            initial='hidden'
            animate='visible'
            exit='exit'>
            <div className='c-modal--item-content c-modal--loyalty'>
                <Formik
                    initialValues={{
                        discountCode: '',
                    }}
                    validationSchema={Yup.object().shape({
                        discountCode: Yup.string().required(t('validation.email.required')),
                    })}
                    onSubmit={(values, actions) => {
                        BillySDK.applyReduction(
                            data.orderType ?? OrderableType.ORDER,
                            data?.orderId,
                            values.discountCode
                        )
                            .then(() => handleOnConfirm())
                            .catch((error) => {
                                if (error.status === 404) {
                                    actions.setFieldError('discountCode', t('validation.discountcode.notfound'))
                                } else {
                                    actions.setFieldError(
                                        'discountCode',
                                        error.message ? error.message : t('error.fatal.default.title')
                                    )
                                }
                            })
                            .finally(() => {
                                actions.setSubmitting(false)
                            })
                    }}>
                    {({ handleChange, handleBlur, isSubmitting }) => (
                        <Form>
                            <Field
                                component={Input}
                                name='discountCode'
                                id='discountCode'
                                type='text'
                                label={data.loyaltyFieldData?.label || t('input.discountcode.label')}
                                placeHolder={data.loyaltyFieldData?.label || t('input.discountcode.placeholder')}
                                onChange={handleChange}
                                disabled={isSubmitting}
                                onBlur={handleBlur}
                            />
                            <div className='c-modal--confirm-buttons'>
                                <button
                                    disabled={isSubmitting}
                                    type='button'
                                    className='c-button c-button-dark'
                                    onClick={() => handleOnReject()}>
                                    {t('loyalty.modal.cancel', 'Cancel')}
                                </button>
                                <button
                                    disabled={isSubmitting}
                                    type='submit'
                                    className='c-button c-button-dark'>
                                    {t('loyalty.modal.submit', 'Submit')}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </motion.div>
    )
}

export default ApplyReductionModal
