import { FC, ReactNode } from 'react'
import classNames from 'classnames'

import './modal.scss'

interface IModalProperties {
    children: ReactNode | null
    containerClassName?: string
    contentClassName?: string
}

const Modal: FC<IModalProperties> = ({ children, containerClassName, contentClassName }) => (
    <section className={classNames('modal-content-container', containerClassName)}>
        <div className={classNames('modal-content', contentClassName)}>{children}</div>
    </section>
)

export default Modal
