import { useContext, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import { BrandContext } from '../../context/BrandContextProvider.tsx'
import { ThemeContext } from '../../context/ThemeContextProvider'

function Seo() {
    const { i18n } = useTranslation()
    const { brand, isBees, isKinepolisSingCity } = useContext(BrandContext)
    const { theme } = useContext(ThemeContext)

    const themeColor = useMemo(() => {
        if (isKinepolisSingCity) {
            return '#231F20'
        }
        return theme.color
    }, [theme?.color, isKinepolisSingCity])

    useEffect(() => {
        if (isBees) {
            try {
                switch (i18n.languages[0]) {
                    case 'nl':
                        window.OneTrust?.changeLanguage('nl-BE')
                        break
                    case 'fr':
                        window.OneTrust?.changeLanguage('fr-BE')
                        break

                    default:
                        window.OneTrust?.changeLanguage('en')
                        break
                }
            } catch {
                // fail silently
            }
        }
    }, [i18n.languages[0]])

    return (
        <Helmet>
            <title>{brand.brandName}</title>

            <meta
                name='msapplication-TileColor'
                content={themeColor}
            />
            <meta
                name='theme-color'
                content={themeColor}
            />
        </Helmet>
    )
}

export default Seo
