import { useQuery } from '@tanstack/react-query'

import { getQuerystringParameterByName } from '../constants/helpers'
import BillySDK from '../sdk/sdk'

function useVenueQuery(venueId, params = null) {
    const tags = getQuerystringParameterByName('tags')

    const {
        isLoading,
        error,
        isError,
        data: venue,
    } = useQuery({
        retry: 2,
        refetchOnWindowFocus: false,
        refetchInterval: 60 * 3 * 1000,
        queryKey: ['venue', { venueId }],
        enabled: !!venueId,
        queryFn: () => BillySDK.getVenueById(venueId, { ...tags, ...params }),
    })

    return {
        isLoading,
        error,
        isError,
        venue: venue?.data,
    }
}

export default useVenueQuery
