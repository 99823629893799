import { useContext } from 'react'

import { BrandContext } from '../../context/BrandContextProvider.tsx'

import './loadingscreen.scss'

function LoadingScreen() {
    const { brand } = useContext(BrandContext)

    return (
        <div className='app-loading'>
            <img
                style={{ width: '60px', height: '60px' }}
                alt='Loading'
                src={brand?.theme.logo.small}
            />
        </div>
    )
}

export default LoadingScreen
