/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { IAdditionValue, ISelectedAddition, ISelectedAdditionValue } from 'src/hooks/useValidateAdditions'
import AdditionType from 'src/structures/Enums/AdditionType'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'
import IAddition from 'src/structures/Interfaces/IAddition'

import { EventContext } from '../../../context/EventContextProvider'
import CheckoutMethod from '../../../structures/Enums/CheckoutMethod.enum'
// eslint-disable-next-line import/no-cycle
import AdditionValue from '../AdditionValue/AdditionValue'

import './addition.scss'

interface IAdditionProperties {
    addition: IAddition
    initialValues?: Array<ISelectedAdditionValue>
    isOrderable: boolean
    onValues?: (values: IAdditionValue) => void
}

const Addition: FC<IAdditionProperties> = ({ addition, isOrderable, initialValues, onValues }) => {
    const { t } = useTranslation()
    const { event } = useContext(EventContext) as any

    const defaultValues: Array<ISelectedAdditionValue> = useMemo(() => {
        if (isNotNullOrUndefined(initialValues)) {
            return initialValues
        }

        const itemsWithDefault = addition.values
            .filter((item) => item.default)
            .map((defaultItem) => ({ id: defaultItem.id, amount: 1 }))

        if (!addition.multiselect && itemsWithDefault.length === 0) {
            return isNotNullOrUndefined(addition.values[0]) ? [{ id: addition.values[0].id, amount: 1 }] : []
        }

        return itemsWithDefault.map((value) => ({ id: value.id, amount: 1 } as ISelectedAdditionValue))
    }, [addition.values, addition.multiselect, initialValues])

    const [values, setValues] = useState<ISelectedAddition>({ id: addition.id.toString(), values: defaultValues })
    const [maxAdditionsAmountReached, setMaxAdditionsAmountReached] = useState(false)

    const totalSelectedAmounts = useMemo(
        () => values.values.reduce((totalAmount, value) => (totalAmount += value.amount), 0),
        [values, addition]
    )

    const isValid = useMemo(
        () => totalSelectedAmounts >= addition.selected_amount_min,
        [totalSelectedAmounts, addition.selected_amount_min]
    )

    const handleAdditionChange = useCallback(
        (valueId: number, amount: number) => {
            setValues((previousState) => {
                if (!addition.multiselect) {
                    previousState.values = [{ id: valueId, amount }]
                } else {
                    const optionIndex = previousState.values.findIndex((value) => value.id === valueId)
                    if (optionIndex === -1 && !maxAdditionsAmountReached) {
                        previousState.values.push({ id: valueId, amount })
                    } else {
                        previousState.values = previousState.values.filter((value) => value.id !== valueId)
                    }
                }

                return { ...previousState }
            })
        },
        [addition, maxAdditionsAmountReached]
    )

    const handleAdditionAmountChange = useCallback(
        (valueId: number, amount: number) => {
            setValues((previousState) => {
                const update = previousState.values.find((value) => value.id === valueId)
                if (isNotNullOrUndefined(update)) {
                    update.amount = amount
                }

                return { ...previousState }
            })
        },
        [addition]
    )

    useEffect(() => {
        onValues?.({ ...values, type: AdditionType.ADDITION, isValid })
    }, [values, onValues, totalSelectedAmounts, addition, isValid])

    useEffect(() => {
        setMaxAdditionsAmountReached(
            totalSelectedAmounts >= (addition.selected_amount_max < 0 ? 500 : addition.selected_amount_max)
        )
    }, [totalSelectedAmounts, addition])

    return (
        <div className={classNames('c-product--addition')}>
            <div className='c-product--addition-header'>
                <h3 className='c-product--addition-title'>{addition.name}</h3>
                {isNotNullOrUndefined(addition.description) ? (
                    <p className='c-product--addition-description'>{addition.description}</p>
                ) : null}
                {isValid ? null : (
                    <p className='c-product--addition-error'>
                        {t('addition.minimum.error', { minimum: addition.selected_amount_min })}
                    </p>
                )}
            </div>
            <div className={classNames('c-product--addition-items', { 'has-error': !isValid })}>
                {addition.values.map((additionValue) => (
                    <AdditionValue
                        key={additionValue.id}
                        value={additionValue}
                        amount={values.values.find((item) => item.id === additionValue.id)?.amount ?? 1}
                        active={
                            isNotNullOrUndefined(values.values.find((item) => item.id === additionValue.id)) ?? false
                        }
                        maxAdditionsAmountReached={maxAdditionsAmountReached}
                        additionId={addition.id}
                        isMultiSelect={addition.multiselect}
                        multiMax={addition.multimax}
                        isOrderable={event?.checkout_method.id !== CheckoutMethod.MENU_ONLY && isOrderable}
                        onChange={handleAdditionChange}
                        onAmountChange={handleAdditionAmountChange}
                    />
                ))}
            </div>
        </div>
    )
}

export default Addition
