import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { motion } from 'framer-motion'

import { popIn } from '../../../constants/Animations.ts'
import * as actionBuilders from '../../../redux/actions/actionBuilders'
import withRedux from '../../../redux/withRedux'

import './modal.scss'

const mapStateToProps = () => ({})

class DialogModal extends Component {
    constructor(props) {
        super(props)
        this.onConfirm = this.onConfirm.bind(this)
    }

    onConfirm() {
        if (this.props.data.onConfirm) this.props.data.onConfirm()
        this.props.closeModal()
    }

    render() {
        return (
            <motion.div
                className='c-modal--item'
                variants={popIn}
                initial='hidden'
                animate='visible'
                exit='exit'>
                <div className='c-modal--item-content c-modal--error'>
                    <h3 className='c-modal--item-name'>
                        {this.props.data.title ? this.props.data.title : this.props.t('modal.confirm.title')}
                    </h3>
                    {this.props.data.message && this.props.data.message()}

                    <button
                        className='c-button c-button-dark'
                        style={{ marginBottom: 0 }}
                        onClick={this.onConfirm}>
                        {this.props.t('button.confirm.cta')}
                    </button>
                </div>
            </motion.div>
        )
    }
}

export default withTranslation()(withRedux(DialogModal, mapStateToProps, actionBuilders))
