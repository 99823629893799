import './smalldivider.scss'

function SmallDivider(props) {
    return (
        <div className='c-smalldivider'>
            <span />
            {props.dividerText && <p>{props.dividerText}</p>}
            <span />
        </div>
    )
}

export default SmallDivider
