import { createContext, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

import usePlaceQuery from '../hooks/usePlaceQuery'

export const PlaceContext = createContext()

function PlaceContextProvider({ children }) {
    const queryClient = useQueryClient()
    const [id, setId] = useState(undefined)
    const { isLoading, error, place } = usePlaceQuery(id)

    const fetchPlaceById = (id) => {
        queryClient.invalidateQueries({ queryKey: ['place'] })

        setId(id)
    }

    const setPlace = ({ id }) => fetchPlaceById(id)

    return (
        <PlaceContext.Provider value={{ isLoading, fetchPlaceById, place, setPlace, error }}>
            {children}
        </PlaceContext.Provider>
    )
}

export default PlaceContextProvider
