import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { ILoyaltyContact } from 'src/hooks/useLoyalty'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'
import ILoyaltyProgramConfig from 'src/structures/Interfaces/ILoyaltyProgramConfig'

import BillySDK from '../sdk/sdk'

import { KioskContext } from './KioskContextProvider'
import { VenueContext } from './VenueContextProvider'

interface ILoyaltyContext {
    venueId: string | undefined
    loyaltyContact: ILoyaltyContact | undefined
    invalidateLoyaltyQuery: () => Promise<void>
    showLoyaltyActivationTrigger: boolean
    loyaltyProgramConfig: ILoyaltyProgramConfig | undefined
}

export const LoyaltyContext = createContext<ILoyaltyContext>({
    venueId: undefined,
    loyaltyContact: undefined,
    invalidateLoyaltyQuery: async () => {},
    showLoyaltyActivationTrigger: false,
    loyaltyProgramConfig: undefined,
})

const LoyaltyContextProvider = ({ children }: { children: ReactNode }) => {
    const { isHandHeld } = useContext(KioskContext)
    const { venue } = useContext(VenueContext)
    const queryClient = useQueryClient()

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { tokens } = useSelector((state) => state) as any
    const storedAccessToken = tokens?.access_token

    const [showLoyaltyActivationTrigger, setShowLoyaltyActivationTrigger] = useState<boolean>(false)

    const loyaltyProgramConfig = useMemo(() => venue?.leat_loyalty_program ?? undefined, [venue])

    const { data: loyaltyContact } = useQuery({
        retry: 0,
        staleTime: 0,
        refetchOnWindowFocus: true,
        queryKey: ['loyaltyContact', { id: venue?.id, token: storedAccessToken }],
        enabled:
            isNotNullOrUndefined(venue?.id) &&
            isNotNullOrUndefined(loyaltyProgramConfig) &&
            isNotNullOrUndefined(storedAccessToken),
        queryFn: async () => BillySDK.loyaltyGetContact(venue.id),
    })

    const invalidateLoyaltyQuery = useCallback(async () => {
        await queryClient.invalidateQueries({ queryKey: ['loyaltyContact'] })
    }, [])

    useEffect(() => {
        const shouldShow =
            !isNotNullOrUndefined(loyaltyContact) && isNotNullOrUndefined(loyaltyProgramConfig) && !isHandHeld
        setShowLoyaltyActivationTrigger(shouldShow)
    }, [loyaltyContact, loyaltyProgramConfig])

    return (
        <LoyaltyContext.Provider
            value={{
                venueId: venue?.id ?? undefined,
                loyaltyContact: loyaltyContact?.data,
                showLoyaltyActivationTrigger,
                invalidateLoyaltyQuery,
                loyaltyProgramConfig,
            }}>
            {children}
        </LoyaltyContext.Provider>
    )
}

export default LoyaltyContextProvider
