import { createContext, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getDefaultTheme, setRootCssVariable } from '../constants/themeUtils'
import { storeTheme } from '../redux/actions/actionBuilders'

export const ThemeContext = createContext()

function ThemeContextProvider({ children }) {
    const dispatch = useDispatch()
    const { theme } = useSelector((state) => state)
    const setTheme = useCallback(
        (nextTheme) => {
            const update = nextTheme ?? {}
            const defaultTheme = getDefaultTheme()
            dispatch(storeTheme({ ...defaultTheme, ...update }))
        },
        [theme]
    )

    useEffect(() => {
        Object.keys(theme).forEach((key) => {
            setRootCssVariable(key, theme[key])
        })
    }, [theme])

    return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>
}

export default ThemeContextProvider
