import classNames from 'classnames'
// Input feedback
function InputFeedback({ error, className }) {
    return error && typeof error === 'string' ? (
        <div>
            <p className={classNames('u-error', className)}>{error}</p>
        </div>
    ) : null
}

export default InputFeedback
