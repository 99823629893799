import { FC } from 'react'
import { motion } from 'framer-motion'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

import './kioskclosed.scss'

interface IKioskClosedProperties {
    label: string
    image: string | null
}

const KioskClosed: FC<IKioskClosedProperties> = ({ image, label }) => {
    const imageAnimationVariant = {
        initial: {
            scale: 1.3,
        },
        animate: {
            scale: 1.1,
        },
        exit: {
            opacity: 0,
            y: '-50vh',
        },
    }

    return (
        <div className='c-kiosk-closed-container'>
            {isNotNullOrUndefined(image) ? (
                <motion.div
                    className='c-kiosk-closed-image-container'
                    variants={imageAnimationVariant}
                    initial='initial'
                    animate='animate'
                    exit='exit'
                    transition={{
                        type: 'spring',
                        duration: 0.35,
                    }}>
                    <img
                        width='100%'
                        height='100%'
                        loading='eager'
                        className='c-kiosk-closed-image'
                        src={image}
                    />
                </motion.div>
            ) : null}

            <motion.div
                exit={{ y: '50vh' }}
                className='c-kiosk-closed--info'>
                <motion.div
                    initial={{
                        opacity: 0,
                        scale: 0.5,
                    }}
                    animate={{
                        opacity: 1,
                        scale: 1,
                        transition: {
                            delay: 0.5,
                        },
                    }}>
                    <h1>{label}</h1>
                </motion.div>
            </motion.div>
        </div>
    )
}

export default KioskClosed
