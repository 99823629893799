import { matchPath } from 'react-router-dom'

import RoutePath from '../structures/Enums/RoutePath.enum.ts'

const pathAtlas = {
    event: [RoutePath.EVENT],
    eventGroup: [RoutePath.EVENT_GROUP],
    eventGroupEvent: [RoutePath.EVENT_GROUP_EVENT],
    venue: [RoutePath.VENUE],
    place: [RoutePath.PLACE],
    category: [`${RoutePath.EVENT}/c/:categoryId`, `${RoutePath.EVENT_GROUP_EVENT}/c/:categoryId`],
    purchase: [
        RoutePath.CREDIT_PURCHASES_OVERVIEW,
        RoutePath.CREDIT_PURCHASES_DETAIL,
        RoutePath.STARNET_TOPUP_OVERVIEW,
        RoutePath.STARNET_TOPUP_DETAIL,
    ],
}

let pathAtlasMerged = []
for (const [, paths] of Object.entries(pathAtlas)) {
    if (paths && paths.length > 0) pathAtlasMerged = pathAtlasMerged.concat(paths)
}

export const matchPaths = (pathArray, locationPath) => {
    let hasMatch = false

    pathArray.forEach((path) => {
        const match = matchPath({ path, exact: true }, locationPath)
        if (match) hasMatch = true
    })

    return hasMatch
}

export const matchPathsByType = (locationPath) => {
    let result
    let matchedPath

    pathAtlasMerged.every((path) => {
        const match = matchPath({ path, exact: true }, locationPath)

        if (match !== null) {
            matchedPath = path
            return false
        }
        return true
    })

    for (const [type, paths] of Object.entries(pathAtlas)) {
        if (paths.includes(matchedPath)) {
            result = type
        }
    }

    return result
}
