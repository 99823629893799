import classNames from 'classnames'

// Input feedback
function InputHelp({ content, className }) {
    return (
        <div className={classNames('input-help', className)}>
            <span>{content}</span>
        </div>
    )
}

export default InputHelp
