enum CheckoutMethod {
    PICKUP_WHEN_READY_WITH_SMS = 1,
    DIRECT_PICKUP = 2,
    TABLE_SERVICE = 3,
    MENU_ONLY = 4,
    TAKE_OUT = 5,
    TABLE_SERVICE_POS = 6,
    PICKUP_WHEN_READY_WITHOUT_SMS = 7,
    DELIVERY = 8,
    DIRECT_PICKUP_ON_GKS_TABLES = 9,
    DIRECT_PICKUP_WITH_INSTANT_SMS = 10,
    PICKUP_WHEN_READY_WITH_CALL = 11,
    SCAN_AND_SERVE = 12,
    QR_TICKET = 13,
    WEBSHOP = 14,
    ROOMSERVICE = 15,
}

export const checkoutMethodStrings = {
    [CheckoutMethod.PICKUP_WHEN_READY_WITH_SMS]: 'pickup_when_ready',
    [CheckoutMethod.DIRECT_PICKUP]: 'direct_pickup',
    [CheckoutMethod.TABLE_SERVICE]: 'table_service',
    [CheckoutMethod.MENU_ONLY]: 'pickup_when_ready',
    [CheckoutMethod.TAKE_OUT]: 'pickup_when_ready',
    [CheckoutMethod.TABLE_SERVICE_POS]: 'table_service',
    [CheckoutMethod.PICKUP_WHEN_READY_WITHOUT_SMS]: 'pickup_when_ready_without_sms',
    [CheckoutMethod.DELIVERY]: 'delivery',
    [CheckoutMethod.DIRECT_PICKUP_ON_GKS_TABLES]: 'direct_pickup',
    [CheckoutMethod.DIRECT_PICKUP_WITH_INSTANT_SMS]: 'direct_pickup',
    [CheckoutMethod.PICKUP_WHEN_READY_WITH_CALL]: 'pickup_when_ready',
    [CheckoutMethod.SCAN_AND_SERVE]: 'scan_and_serve',
    [CheckoutMethod.QR_TICKET]: 'qr_ticket',
    [CheckoutMethod.WEBSHOP]: 'webshop',
    [CheckoutMethod.ROOMSERVICE]: 'ROOMSERVICE',
}

export default CheckoutMethod
