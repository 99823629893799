import { FC, useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import { KioskContext } from 'src/context/KioskContextProvider'
import { ThemeContext } from 'src/context/ThemeContextProvider'
import { openModal } from 'src/redux/actions/actionBuilders'
import ModalType from 'src/structures/Enums/ModalType'

import LoyaltyCardStackGraphic from '../LoyaltyCardStackGraphic/LoyaltyCardStackGraphic'

import './headerloyalty.scss'

interface IHeaderLoyaltyProperties {
    points: string
    pointsLabel: string
}

const HeaderLoyalty: FC<IHeaderLoyaltyProperties> = ({ points, pointsLabel }) => {
    const { theme } = useContext(ThemeContext)
    const { kioskMode } = useContext(KioskContext)
    const dispatch = useDispatch()

    const handleClick = useCallback(() => {
        dispatch(
            openModal({
                type: ModalType.LOYALTY_REWARDS_MODAL,
            })
        )
    }, [])

    return (
        <motion.div
            key={points}
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            className={classNames('loyalty-contact--container', { kioskMode })}>
            <button
                type='button'
                onClick={handleClick}
                className='loyalty-contact--button'>
                <LoyaltyCardStackGraphic
                    icon={
                        <img
                            alt='Logo'
                            src={theme.logo}
                        />
                    }
                    iconClassName='loyalty-contact--button-icon'
                    className='loyalty-contact--button-cardstack'
                />
                <div className='loyalty-contact--button-label'>
                    <span className='loyalty-pointslabel'>{pointsLabel}</span>
                    <span className='loyalty-points'>{points}</span>
                </div>
            </button>
        </motion.div>
    )
}

export default HeaderLoyalty
