import { useContext, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { DrawerMenuContext } from '../../context/DrawerMenuContextProvider'

function ForceScrollBlock() {
    // We don't allow page scroll for a better experience
    // This component adds styling to html/body to prevent scrolling when basket or drawer menu is open.
    const scrollY = useRef(0)
    const location = useLocation()

    const doc = document.documentElement
    const basketState = useSelector((state) => state.basket)
    const modals = useSelector((state) => state.modal)
    const { drawerMenuOpen } = useContext(DrawerMenuContext)

    useEffect(() => {
        if (drawerMenuOpen || basketState.isOpen || modals.activeModals.length > 0) {
            if (
                basketState.isOpen &&
                !location.pathname.includes('/events') &&
                !location.pathname.includes('/eventgroups')
            ) {
                return
            }
            if (doc.classList.contains('scroll_blocked')) return

            scrollY.current = window.scrollY
            doc.style.setProperty('--scroll-y', `-${scrollY.current}px`)
            doc.classList.add('scroll_blocked')
        } else {
            doc.classList.remove('scroll_blocked')
            window.scroll(0, scrollY.current)
            doc.style.setProperty('--scroll-y', `0`)
            scrollY.current = 0
        }
    }, [drawerMenuOpen, basketState.isOpen, modals])

    useEffect(() => {
        scrollY.current = 0
        doc.classList.remove('scroll_blocked')
        window.scroll(0, scrollY.current)
        doc.style.setProperty('--scroll-y', `0`)
    }, [location])

    return null
}

export default ForceScrollBlock
