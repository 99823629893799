import { KeyboardEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import './expandabletext.scss'

interface IExpandableTextProperties {
    id: string
    text: string
    amountOfWords?: number
}

export const ExpandableText = ({ id, text, amountOfWords = 20 }: IExpandableTextProperties) => {
    const { t } = useTranslation()
    const [isExpanded, setIsExpanded] = useState(false)
    const splittedText = text.split(' ')
    const itCanOverflow = splittedText.length > amountOfWords
    const beginText = itCanOverflow ? splittedText.slice(0, amountOfWords - 1).join(' ') : text
    const endText = splittedText.slice(amountOfWords - 1).join(' ')

    const handleKeyboard = (keybordEvent: KeyboardEvent) => {
        if (keybordEvent.code === 'Space' || keybordEvent.code === 'Enter') {
            setIsExpanded(!isExpanded)
        }
    }

    return (
        <p
            className='expandabletext-text'
            id={id}>
            {beginText}
            {itCanOverflow && (
                <>
                    {!isExpanded && <span>... </span>}
                    <span
                        className={`${!isExpanded && 'is-hidden'}`}
                        aria-hidden={!isExpanded}>
                        {' '}
                        {endText}
                    </span>
                    <span
                        className='expandabletext-button'
                        role='button'
                        tabIndex={0}
                        aria-expanded={isExpanded}
                        aria-controls={id}
                        onKeyDown={handleKeyboard}
                        onClick={() => {
                            setIsExpanded(!isExpanded)
                        }}>
                        {isExpanded ? t('button.show_less.cta', 'show less') : t('button.show_more.cta', 'show more')}
                    </span>
                </>
            )}
        </p>
    )
}
