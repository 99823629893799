import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { motion } from 'framer-motion'

import Confetti from '../../../components/01_atoms/Confetti/Confetti.tsx'
import Rating from '../../../components/Rating/Rating.tsx'
import { popIn } from '../../../constants/Animations.ts'
import { closeModal } from '../../../redux/actions/actionBuilders'
import BillySDK from '../../../sdk/sdk'

import './modal.scss'

function RatingModal({ data }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [vote, setVote] = useState({ score: -1, message: null })

    // we have a rating, not submitted message
    const onRating = useCallback((score) => {
        setVote(score)
    }, [])

    // we have a rating and a message after submit
    const onSubmit = useCallback(
        (score) => {
            setVote(score)
            dispatch(closeModal())
        },
        [dispatch, closeModal]
    )

    const postVote = useCallback(() => {
        BillySDK.rating(data.venueId, vote)
    }, [vote])

    // set default value and post user review on unmount
    useEffect(() => () => postVote(), [])

    return (
        <motion.div
            className='c-modal--item'
            variants={popIn}
            initial='hidden'
            animate='visible'
            exit='exit'>
            <div
                style={{ position: 'relative' }}
                className='c-modal--item-content c-modal--rating'>
                <h3 className='c-modal--item-name'>{t('modal.rating.title', { venue: data?.venueName })}</h3>
                {data?.message && data.message()}

                <Rating
                    placeId={data.placeId}
                    onRating={onRating}
                    onSubmit={onSubmit}
                />
                <div className='success-explosion'>
                    {vote?.score > 2 ? (
                        <Confetti
                            particleCount={Math.abs(vote.score) * 50}
                            launchSpeed={Math.abs(vote.score) / 2}
                            key={vote.score}
                        />
                    ) : null}
                </div>
            </div>
        </motion.div>
    )
}

export default RatingModal
