import { Component, createRef } from 'react'
import classNames from 'classnames'

import './common.scss'

export default class ScrollPane extends Component {
    constructor(props) {
        super(props)
        this.ref = createRef()
    }

    componentDidMount() {
        setTimeout(() => {
            if (this.ref && this.ref.current) {
                this.ref.current.scrollTop = 0
            }
        }, 300)
    }

    render() {
        return (
            <div
                style={this.props.style}
                ref={this.ref}
                className={classNames('c-scrollpane', this.props.className)}>
                {this.props.children}
            </div>
        )
    }
}
