import { useContext, useEffect } from 'react'
import { use100vh } from 'react-div-100vh'

import { KioskContext } from '../../context/KioskContextProvider'

export const useScreenHeight = () => {
    const screenHeight = use100vh()
    const { kioskMode } = useContext(KioskContext)
    useEffect(() => {
        document.documentElement.style.setProperty('--screen-height', `${screenHeight * (kioskMode ? 0.65 : 1)}px`)
    }, [screenHeight])
    return use100vh()
}

export const withScreenHeight = (Component) =>
    // eslint-disable-next-line
    function (props) {
        const screenHeight = use100vh()
        const { kioskMode } = useContext(KioskContext)
        document.documentElement.style.setProperty('--screen-height', `${screenHeight * (kioskMode ? 0.65 : 1)}px`)
        return (
            <Component
                screenHeight={screenHeight * (kioskMode ? 0.65 : 1)}
                {...props}
            />
        )
    }
