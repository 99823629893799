import { FC, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { FaTimes } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import LoyaltyCardGraphic from 'src/components/01_atoms/LoyaltyCardGraphic/LoyaltyCardGraphic'
import Modal from 'src/components/01_atoms/Modal/Modal'
import LoyaltyRewards from 'src/components/02_molecules/LoyaltyRewards/LoyaltyRewards'
import { useScreenHeight } from 'src/components/common/withScreenHeight'
import { LoyaltyContext } from 'src/context/LoyaltyContextProvider'
import { closeModal } from 'src/redux/actions/actionBuilders'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'
import IModalDataProperties from 'src/structures/Interfaces/IModalDataProperties'

import './loyaltyrewardsmodal.scss'

const LoyaltyRewardsModal: FC<IModalDataProperties> = () => {
    const screenHeight = useScreenHeight()
    const { loyaltyProgramConfig, loyaltyContact, venueId } = useContext(LoyaltyContext)
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const handleClose = useCallback(() => {
        dispatch(closeModal())
    }, [dispatch])

    return (
        <div style={{ maxHeight: `${(screenHeight ?? 400) * 0.9}px` }}>
            <Modal
                containerClassName='loyaltyrewards-container'
                contentClassName='loyaltyrewards-content'>
                <div className='inner'>
                    <div className='loyaltyrewards-close'>
                        <button
                            aria-label={t('general.button.close.label')}
                            onClick={handleClose}
                            className='c-button-close-modal-small'>
                            <FaTimes />
                        </button>
                    </div>
                    {isNotNullOrUndefined(loyaltyContact?.email) ? (
                        <div className='loyaltyrewards-contact'>{loyaltyContact?.email}</div>
                    ) : null}
                    <div className='loyaltyrewards-card-container'>
                        <div className='loyaltyrewards-card'>
                            <LoyaltyCardGraphic
                                pointsLabel={loyaltyProgramConfig?.custom_credit_name}
                                points={Number.parseInt(loyaltyContact?.loyalty_balance ?? '0', 10) ?? 0}
                            />
                        </div>
                    </div>

                    <LoyaltyRewards venueId={venueId} />
                </div>
            </Modal>
        </div>
    )
}

export default LoyaltyRewardsModal
