import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

import BillySDK from '../sdk/sdk'

function useCatalogueQuery({
    eventId,
    categoryGroupId,
}: {
    eventId?: string | null | undefined
    categoryGroupId?: string | null | undefined
}) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { locale } = useSelector((state: any) => state)

    const [catalogueMapped, setCatalogueMapped] = useState({
        products: new Map(),
        categories: new Map(),
        categoryGroups: new Map(),
    })

    const {
        isLoading,
        isError,
        data: catalogue,
    } = useQuery({
        refetchOnWindowFocus: true,
        staleTime: 0,
        select: (catalogueData) => catalogueData?.data ?? undefined,
        queryKey: ['products', eventId],
        enabled: isNotNullOrUndefined(eventId),
        queryFn: async () => BillySDK.getCatalogueByEventId(eventId, null),
    })

    useEffect(() => {
        if (!isLoading && isNotNullOrUndefined(catalogue)) {
            const products = new Map()
            const categories = new Map()
            const categoryGroups = new Map()

            if (catalogue.length > 0) {
                for (const group of catalogue) {
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    categoryGroups.set(group.id || 0, group)
                    for (const category of group.categories) {
                        categories.set(category.id, category)
                        for (const product of category.products) {
                            products.set(product.id, { ...product, is_orderable: category.is_orderable ?? true })
                        }
                    }
                }
            }

            setCatalogueMapped({
                categoryGroups,
                categories,
                products,
            })
        }
    }, [isLoading, catalogue, eventId, categoryGroupId, locale])

    const allCategoryGroupsInCatalogue = useMemo(
        () => [...catalogueMapped.categoryGroups?.values()],
        [catalogueMapped.categoryGroups, locale]
    )
    const allCategoriesInCatalogue = useMemo(
        () => [...catalogueMapped.categories?.values()],
        [catalogueMapped.categories, locale]
    )
    const allProductsInCatalogue = useMemo(
        () => [...catalogueMapped.products?.values()],
        [catalogueMapped.products, locale]
    )

    const getCategoryGroupById = useCallback(
        (id: number) => catalogueMapped.categoryGroups?.get(id),
        [catalogueMapped.categoryGroups, locale]
    )
    const getCategoryById = useCallback(
        (id: number) => catalogueMapped.categories?.get(id),
        [catalogueMapped.categories, locale]
    )
    const getProductById = useCallback(
        (id: number) => catalogueMapped.products?.get(id),
        [catalogueMapped.products, locale]
    )

    return {
        catalogue,
        catalogueMapped,
        isError,
        isLoading,
        getCategoryGroupById,
        getCategoryById,
        getProductById,
        allCategoryGroupsInCatalogue,
        allCategoriesInCatalogue,
        allProductsInCatalogue,
    }
}

export default useCatalogueQuery
