import { FC, useContext } from 'react'
import { useSelector } from 'react-redux'
import { Link, useMatch } from 'react-router-dom'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import { HeaderContext } from 'src/context/HeaderContext'
import { ThemeContext } from 'src/context/ThemeContextProvider'
import RoutePath from 'src/structures/Enums/RoutePath.enum'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

import './headerlogo.scss'

const HeaderLogo: FC<Record<string, null>> = () => {
    const eventGroupEventMatchPath = useMatch(`${RoutePath.EVENT_GROUP_EVENT}/*`)
    const eventGroupMatchPath = useMatch(RoutePath.EVENT_GROUP)
    const placeMatchPath = useMatch(RoutePath.PLACE)
    const orderGroupMatchPath = useMatch(RoutePath.ORDER_GROUP)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { kioskMode } = useSelector((state: any) => state.kiosk)
    const headerContext = useContext(HeaderContext)
    const { theme } = useContext(ThemeContext)

    let logoLink =
        theme?.venueScreenDisabled === false && isNotNullOrUndefined(theme?.venuePath) ? theme.venuePath : '#'

    if (isNotNullOrUndefined(orderGroupMatchPath) && isNotNullOrUndefined(theme.eventGroupPath))
        logoLink = theme.eventGroupPath
    if (isNotNullOrUndefined(placeMatchPath)) logoLink = `/places/${placeMatchPath.params.placeId}`
    if (isNotNullOrUndefined(eventGroupMatchPath)) logoLink = `/eventgroups/${eventGroupMatchPath.params.eventGroupId}`
    if (isNotNullOrUndefined(eventGroupEventMatchPath))
        logoLink = `/eventgroups/${eventGroupEventMatchPath.params.eventGroupId}`

    const headerLogo = (
        <img
            className={classNames('c-header--logo', { kioskMode })}
            alt='Logo'
            src={theme.logo}
        />
    )

    if (isNotNullOrUndefined(kioskMode) && kioskMode === true) {
        return <span>{headerLogo}</span>
    }

    return (
        <motion.div
            initial={{ scale: 0.6 }}
            animate={{ scale: 1 }}>
            <Link
                style={
                    isNotNullOrUndefined(headerContext?.isHeaderDisabled) && headerContext.isHeaderDisabled === true
                        ? { pointerEvents: 'none' }
                        : {}
                }
                to={logoLink}>
                {headerLogo}
            </Link>
        </motion.div>
    )
}

export default HeaderLogo
