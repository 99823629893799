import { FC, ReactNode } from 'react'
import classNames from 'classnames'

import './kioskinstructionsicon.scss'

interface IKioskInstructionsIconProperties {
    children?: ReactNode | null
    className?: string
}

const KioskInstructionsIcon: FC<IKioskInstructionsIconProperties> = ({ children, className }) => (
    <div className={classNames('c-kiosk-instructions-icon', className)}>{children}</div>
)

export default KioskInstructionsIcon
