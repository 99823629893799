import { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

export default function (ComposedComponent, mapStateToProps, actionCreators) {
    if (!mapStateToProps) {
        mapStateToProps = () => ({})
    }

    class ReduxContainer extends PureComponent {
        constructor(props) {
            super(props)
            const { dispatch } = props
            this.boundActionCreators = actionCreators ? bindActionCreators(actionCreators, dispatch) : {}
        }

        render() {
            return (
                <ComposedComponent
                    {...this.props}
                    {...this.boundActionCreators}
                />
            )
        }
    }

    ReduxContainer.propTypes = {
        dispatch: PropTypes.func,
    }

    ReduxContainer.defaultProps = {
        dispatch: () => {},
    }
    return connect(mapStateToProps)(ReduxContainer)
}
