export const ADD_PRODUCT = '@basket/ADD_PRODUCT'
export const UPDATE_BASKET = '@basket/UPDATE_BASKET'
export const REMOVE_FROM_BASKET = '@basket/REMOVE_FROM_BASKET'
export const RESET_BASKET = '@basket/RESET_BASKET'
export const UPSELL_PRODUCT = '@basket/UPSELL_PRODUCT'
export const REMOVE_PRODUCT = '@basket/REMOVE_PRODUCT'
export const OPEN_BASKET = '@basket/OPEN_BASKET'
export const CLOSE_BASKET = '@basket/CLOSE_BASKET'
export const ADD_FEES_TO_BASKET = '@basket/ADD_FEES_TO_BASKET'
export const APPEND_ADDITIONS_TO_PRODUCT = '@basket/APPEND_ADDITIONS_TO_PRODUCT'
export const ADD_TIP = '@basket/ADD_TIP'
