import { createContext, useCallback, useContext, useLayoutEffect, useState } from 'react'
import { useMatch } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

import useEventGroupQuery from '../hooks/useEventGroupQuery'
import RoutePath from '../structures/Enums/RoutePath.enum.ts'
import { isNotNullOrUndefined } from '../structures/Guards/guards.utils.ts'

import { ThemeContext } from './ThemeContextProvider'
import { VenueContext } from './VenueContextProvider.tsx'

export const EventGroupContext = createContext({
    eventGroupId: undefined,
    setEventGroupId: undefined,
    // eslint-disable-next-line no-unused-vars
    fetchEventGroupById: (id) => undefined,
    eventGroup: undefined,
    isEventGroupLoading: undefined,
    isEventGroupError: undefined,
})

function EventGroupContextProvider({ children }) {
    const queryClient = useQueryClient()
    const { setTheme } = useContext(ThemeContext)

    const eventGroupMatchPath = useMatch(RoutePath.EVENT_GROUP)
    const orderGroupMatchPath = useMatch(RoutePath.ORDER_GROUP)

    const [eventGroupId, setEventGroupId] = useState(undefined)
    const { isLoading, isError, eventGroup } = useEventGroupQuery(eventGroupId)

    const { fetchVenueById } = useContext(VenueContext)
    useLayoutEffect(() => {
        async function getVenue() {
            if (isNotNullOrUndefined(eventGroup?.master_venue?.id) && isNotNullOrUndefined(eventGroupMatchPath)) {
                await fetchVenueById(eventGroup.master_venue.id)
            }
        }
        // eslint-disable-next-line no-void
        void getVenue()
    }, [fetchVenueById, eventGroupMatchPath, eventGroup?.master_venue?.id])

    const fetchEventGroupById = useCallback(
        (id) => {
            if (eventGroup?.id !== id) {
                queryClient.invalidateQueries({ queryKey: ['eventGroup'] })
                setEventGroupId(id)
            }
        },
        [eventGroup, queryClient]
    )

    useLayoutEffect(() => {
        if (isNotNullOrUndefined(eventGroup?.master_venue)) {
            if (isNotNullOrUndefined(orderGroupMatchPath) || isNotNullOrUndefined(eventGroupMatchPath)) {
                setTheme({
                    ...eventGroup.master_venue.theming,
                    ...{
                        eventGroupPath: `/eventgroups/${eventGroup.id}`,
                    },
                })
            }
        }
    }, [eventGroup, orderGroupMatchPath, eventGroupMatchPath])

    return (
        <EventGroupContext.Provider
            value={{
                eventGroupId,
                setEventGroupId,
                fetchEventGroupById,
                eventGroup,
                isEventGroupLoading: isLoading,
                isEventGroupError: isError,
            }}>
            {children}
        </EventGroupContext.Provider>
    )
}

export default EventGroupContextProvider
