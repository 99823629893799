import { FC, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useMatch } from 'react-router-dom'
import HeaderLogo from 'src/components/01_atoms/HeaderLogo/HeaderLogo'
import HeaderLoyalty from 'src/components/01_atoms/HeaderLoyalty/HeaderLoyalty'
import { DrawerMenuContext } from 'src/context/DrawerMenuContextProvider'
import { LoyaltyContext } from 'src/context/LoyaltyContextProvider'
import RoutePath from 'src/structures/Enums/RoutePath.enum'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

import './headercenterelement.scss'

const HeaderCenterElement: FC<Record<string, null>> = () => {
    const { loyaltyContact, loyaltyProgramConfig } = useContext(LoyaltyContext)
    const { drawerMenuOpen } = useContext(DrawerMenuContext)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { tokens } = useSelector((state) => state) as any
    const storedAccessToken = tokens?.access_token

    const venueMatchPath = useMatch(RoutePath.VENUE)
    const selfScanMatchPath = useMatch(RoutePath.SELF_SCAN)
    const eventMatchPath = useMatch(RoutePath.EVENT)
    const orderMatchPath = useMatch(RoutePath.ORDER)

    const showBadge =
        isNotNullOrUndefined(venueMatchPath) ||
        isNotNullOrUndefined(selfScanMatchPath) ||
        isNotNullOrUndefined(eventMatchPath) ||
        isNotNullOrUndefined(orderMatchPath)

    return (
        <div className='header-center-element'>
            {isNotNullOrUndefined(loyaltyContact) &&
            isNotNullOrUndefined(storedAccessToken) &&
            showBadge &&
            drawerMenuOpen === false ? (
                <HeaderLoyalty
                    points={loyaltyContact.loyalty_balance}
                    pointsLabel={loyaltyProgramConfig?.custom_credit_name ?? 'Points'}
                />
            ) : (
                <HeaderLogo />
            )}
        </div>
    )
}

export default HeaderCenterElement
