import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { BiErrorCircle } from 'react-icons/bi'
import classNames from 'classnames'

import './productconfigurator.scss'

function ProductConfiguratorItem({
    name,
    index,
    addition,
    disabled,
    currentValues,
    isValid = true,
    onAdditionChangeRequest = () => undefined,
}) {
    const { t } = useTranslation()
    const onChangeRequest = useCallback(() => onAdditionChangeRequest(index), [index, currentValues])

    return (
        <div className={classNames('c-product-configurator-item', { 'c-product-configurator-item--error': !isValid })}>
            <div className='c-product-configurator-item--inner'>
                <div className='c-product-configurator-item--content'>
                    <p
                        className='c-product-configurator-item--name'
                        style={currentValues?.length > 0 ? { marginBottom: '5px' } : {}}>
                        {isValid ? null : (
                            <span>
                                <BiErrorCircle />
                            </span>
                        )}{' '}
                        {name}
                    </p>

                    {currentValues?.values?.map((value) => (
                        <p
                            className='c-product-configurator-item--value'
                            key={value.id}>
                            <span>{`${value.amount}x`}</span> {`${value.name}`}
                        </p>
                    ))}
                    {currentValues?.values?.length === 0 && isValid ? (
                        <p className='c-product-configurator-item--value'>
                            {t('product_configurator.no_choice.description')}
                        </p>
                    ) : null}
                    {isValid ? null : (
                        <p className='has-error-label'>
                            {t('addition.minimum.error', { minimum: addition.selected_amount_min })}
                        </p>
                    )}
                </div>
                <div className='c-product-configurator-item--actions'>
                    {disabled === false ? (
                        <button onClick={onChangeRequest}>
                            {isValid ? t('button.change.cta') : t('button.add_to_basket.cta')}
                        </button>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default ProductConfiguratorItem
