import { FC, useCallback } from 'react'
import classNames from 'classnames'
import AdditionValueQuantitySelector from 'src/components/01_atoms/AdditionValueQuantitySelector/AdditionValueQuantitySelector'

import './promotionproductinput.scss'

interface IPromotionProductInputProperties {
    promotionId: number
    value: {
        id: number
        name: string | null
    }
    amount: number
    isMultiSelect: boolean
    multiMax: number
    maxAmountReached: boolean
    active: boolean
    onChange: (valueId: number) => void
    onAmountChange?: (valueId: number, amount: number) => void
}

const PromotionProductInput: FC<IPromotionProductInputProperties> = ({
    promotionId,
    value,
    maxAmountReached,
    multiMax,
    isMultiSelect = false,
    active,
    onChange,
    onAmountChange,
    amount = 1,
}) => {
    const disabled = !active && maxAmountReached && isMultiSelect

    const handleChange = useCallback(() => {
        onChange(value.id)
    }, [onChange, value.id])

    const handleAmountChange = useCallback(
        (newAmount: number) => {
            onAmountChange?.(value.id, Number.isNaN(newAmount) ? 1 : newAmount)
        },
        [onAmountChange, value.id]
    )

    return (
        <div
            key={value.id}
            className={classNames('promotion-product-input-value', {
                'is-active': active,
                'is-disabled': disabled,
            })}>
            <div>
                <div className='promotion-product-input-value-container'>
                    <div className='promotion-product-input-value-inner'>
                        <label htmlFor={`${promotionId?.toString()}-${value.id.toString()}`}>
                            <span className='promotion-product-input-name'>{value.name}</span>

                            <input
                                id={`${promotionId.toString()}-${value.id.toString()}`}
                                type={isMultiSelect ? 'checkbox' : 'radio'}
                                name={isMultiSelect ? value.id.toString() : promotionId?.toString()}
                                onChange={handleChange}
                                checked={active}
                                disabled={disabled}
                            />
                            <label className={isMultiSelect ? 'checkbox' : 'radio'} />
                        </label>
                    </div>
                    {multiMax > 1 && active ? (
                        <AdditionValueQuantitySelector
                            range={{
                                min: 1,
                                max: maxAmountReached ? 1 : multiMax,
                            }}
                            value={amount}
                            onChange={handleAmountChange}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default PromotionProductInput
