import { KIOSK_RESET_STORE, KIOSK_SET_DEVICE, KIOSK_SET_KIOSK_MODE, KIOSK_UPDATE_TOKEN } from '../actions/kioskActions'

export default function kioskReducer(
    state = {
        kioskMode: false,
        device: undefined,
    },
    { type, payload }
) {
    switch (type) {
        case KIOSK_UPDATE_TOKEN:
            return { ...state, ...{ token: payload } }

        case KIOSK_SET_KIOSK_MODE:
            return { ...state, ...{ kioskMode: payload } }

        case KIOSK_SET_DEVICE:
            return { ...state, ...{ device: payload } }

        case KIOSK_RESET_STORE:
            return {}

        default:
            return state
    }
}
