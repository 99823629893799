import { FC, ReactNode, useCallback } from 'react'
import { HiOutlineGift } from 'react-icons/hi'
import classNames from 'classnames'

import LoyaltyCardStackGraphic from '../LoyaltyCardStackGraphic/LoyaltyCardStackGraphic'

import './loyaltyscantrigger.scss'

interface ILoyaltyScanTriggerProperties {
    type?: 'button' | 'float'
    label: ReactNode
    onClick?: () => void
}

const LoyaltyScanTrigger: FC<ILoyaltyScanTriggerProperties> = ({ label = 'button', onClick, type = 'float' }) => {
    const handleClick = useCallback(() => {
        onClick?.()
    }, [onClick])

    return (
        <div className={classNames(`loyaltyscantrigger-container loyaltyscantrigger-button-type-${type}`)}>
            <button
                className='loyaltyscantrigger-button animation-float'
                type='button'
                onClick={handleClick}>
                <LoyaltyCardStackGraphic
                    icon={<HiOutlineGift />}
                    iconClassName='loyaltyscantrigger-icon'
                    className='loyaltyscantrigger-cardstack'
                />
                <div className='loyaltyscantrigger-label'>{label}</div>
            </button>
        </div>
    )
}

export default LoyaltyScanTrigger
