import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { FaStar } from 'react-icons/fa'

import { EventContext } from '../../context/EventContextProvider'
import usePromotions from '../../hooks/usePromotions.ts'

import './promorecommendation.scss'

function PromoRecommendation({ promotion, product }) {
    const { t } = useTranslation()
    const { event } = useContext(EventContext)
    const { onPromotionClick } = usePromotions({ eventId: event.id })

    if (promotion) {
        return (
            <div className='c-promo-recommendation'>
                <div className='c-promo-recommendation-image'>
                    {promotion.coverImage ? (
                        <img
                            loading='lazy'
                            src={promotion.coverImage}
                        />
                    ) : (
                        <div className='c-promo-img-fallback'>
                            <FaStar />
                        </div>
                    )}
                </div>

                <div className='c-promo-recommendation-content'>
                    <p className='product-name u-text-bold'>{product?.name ?? ''}</p>
                    <p className='promo-name u-text-black'>{promotion.name ?? ''}</p>
                </div>

                <div className='c-promo-recommendation-controls'>
                    <button
                        style={promotion.color ? { background: promotion.color } : {}}
                        className='c-promo-recommendation-button u-text-bold'
                        onClick={() => onPromotionClick(promotion, product.id)}>
                        {t('discountcode.add.cta')}
                    </button>
                </div>
            </div>
        )
    }

    return null
}

export default PromoRecommendation
