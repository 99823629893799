import { captureException, setTag } from '@sentry/react'
import axios from 'axios'

import { divideByHundred } from '../constants/helpers'
import i18n from '../i18next-config'
import OrderableType from '../structures/Enums/OrderableType.enum.ts'
import UserType from '../structures/Enums/UserType.ts'

import * as Endpoints from './api/endpoints'
import * as AuthMethods from './auth/authMethods'
import * as InitialFormValues from './forms/InitialFormValues'
import * as ValidationSchemas from './forms/ValidationSchemas'

const API = import.meta.env.REACT_APP_API_URL
class BILLYSDK {
    constructor() {
        this.axiosInstance = axios.create()

        this.axiosInstance.defaults.timeout = 60000

        this.axiosInstance.defaults.headers.common = Object.assign(this.axiosInstance.defaults.headers.common, {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        })

        i18n.on('initialized languageChanged', () => {
            this.axiosInstance.defaults.headers.common = Object.assign(this.axiosInstance.defaults.headers.common, {
                'Accept-Language': i18n.languages[0],
            })
        })

        this.token = null
        this.baseURI = API
    }

    getAxiosInstance() {
        return this.axiosInstance
    }

    setAndValidateToken(token) {
        this.axiosInstance.defaults.headers.common = Object.assign(this.axiosInstance.defaults.headers.common, {
            Authorization: `Bearer ${token}`,
        })

        return this.doGet(API + Endpoints.USER)
    }

    logout() {
        return this.doGet(API + Endpoints.LOGOUT)
    }

    loginFacebook(accessToken) {
        return this.doPost(API + Endpoints.LOGIN_FACEBOOK, {
            accessToken,
        })
    }

    loginProviderWithCode(provider, code) {
        return this.doPost(API + Endpoints.LOGIN_PROVIDER(provider), {
            code,
        })
    }

    loginGoogle(accessToken, profile) {
        return this.doPost(API + Endpoints.LOGIN_GOOGLE, {
            accessToken,
            profile,
        })
    }

    login(email, password) {
        return this.doPost(API + Endpoints.LOGIN, {
            email,
            password,
        })
    }

    guestLogin() {
        return this.doPost(API + Endpoints.GUEST_LOGIN, {
            type: UserType.GUEST,
        })
    }

    kioskLogin() {
        return this.doPost(API + Endpoints.GUEST_LOGIN, {
            type: UserType.KIOSK,
        })
    }

    passwordResetRequest(email) {
        return this.doPost(API + Endpoints.PASSWORD_RESET_REQUEST, {
            email,
        })
    }

    passwordReset(password, token) {
        return this.doPost(API + Endpoints.PASSWORD_RESET, {
            password,
            token,
        })
    }

    register(firstname, name, email, password, hasAgreed) {
        return this.doPost(API + Endpoints.REGISTER, {
            firstname,
            name,
            email,
            password,
            has_agreed: hasAgreed,
        })
    }

    reauth(token) {
        return this.doPost(API + Endpoints.REAUTH, {
            token,
        })
    }

    updateUser(data) {
        return this.doPut(API + Endpoints.USER, data)
    }

    getUser() {
        return this.doGet(API + Endpoints.USER)
    }

    getPlaceById(id, params) {
        return this.doGet(API + Endpoints.PLACES(id), params)
    }

    getVenues(params) {
        return new Promise((resolve, reject) => {
            this.axiosInstance
                .get(API + Endpoints.VENUES(), {
                    params: { ...params, ensurePlacesComplete: true },
                })
                .then((response) => {
                    resolve({ ...response.data, ...{ statusCode: response.status } })
                })
                .catch((error) => {
                    console.log(error) // eslint-disable-line

                    if (error.response && error.response.status) {
                        captureException(error)
                    }

                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx

                        reject({
                            message: error.response.data?.message || 'An error occurred',
                            status: error.response.status,
                            response: error.response,
                        })
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest

                        reject(error.message)
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        reject(error.message)
                    }
                })
        })
    }

    getVenueById(id, params) {
        return this.doGet(API + Endpoints.VENUES(id), params)
    }

    getRedirectUrlBySlug(slug) {
        return this.doGet(API + Endpoints.REDIRECT_URL_BY_SLUG(slug))
    }

    getEventGroupById(eventGroupId, params) {
        return this.doGet(API + Endpoints.EVENT_GROUPS(eventGroupId), params)
    }

    getVenueNews(venueId, type) {
        return this.doGet(API + Endpoints.VENUE_NEWS(venueId, type))
    }

    getEventNews(eventId, type) {
        return this.doGet(API + Endpoints.EVENT_NEWS(eventId, type))
    }

    getEventPromotions(eventId) {
        return this.doGet(API + Endpoints.PROMOTIONS(eventId))
    }

    promotionsOtpGenerate(values) {
        return this.doPost(API + Endpoints.PROMOTIONS_OTP_GENERATE, values)
    }

    promotionsOtpVerify(values) {
        return this.doPost(API + Endpoints.PROMOTIONS_OTP_VERIFY, values)
    }

    getEventById(eventId, params) {
        return this.doGet(API + Endpoints.EVENTS(eventId), params)
    }

    getCatalogueByEventId(eventId, params) {
        return this.doGet(API + Endpoints.EVENT_CATALOGUE(eventId), params)
    }

    getProductById(id, params) {
        return this.doGet(API + Endpoints.EVENT_PRODUCT(id), params)
    }

    getEventPSPsById(eventId, params) {
        return this.doGet(API + Endpoints.EVENT_PSPS(eventId), params)
    }

    getEventGroupPSPsById(eventGroupId, params) {
        return this.doGet(API + Endpoints.EVENT_GROUP_PSPS(eventGroupId), params)
    }

    getOrders(params) {
        return this.doGet(API + Endpoints.ORDER(false), params)
    }

    getOrderById(orderId, params) {
        return this.doGet(API + Endpoints.ORDER(orderId), params)
    }

    getOrderGroupById(orderGroupId, params) {
        return this.doGet(API + Endpoints.ORDER_GROUP(orderGroupId), params)
    }

    getOrderStatusById(orderId, params) {
        return this.doGet(API + Endpoints.ORDER_STATUS(orderId), params)
    }

    getOrderGroupStatusById(orderGroupId, params) {
        return this.doGet(API + Endpoints.ORDER_GROUP_STATUS(orderGroupId), params)
    }

    deleteOrderById(orderId) {
        return this.doDelete(API + Endpoints.ORDER(orderId))
    }

    patchOrderById(id, values) {
        return this.doPatch(API + Endpoints.ORDER(id), values)
    }

    deleteOrderGroupById(orderGroupId) {
        return this.doDelete(API + Endpoints.ORDER_GROUP(orderGroupId))
    }

    applyLoyalty(orderId, loyaltyCard) {
        return this.doPost(API + Endpoints.ORDER_LOYALTY_VALIDATION(orderId), {
            loyalty_card: loyaltyCard,
        })
    }

    applyReduction(orderType, orderId, discountCode) {
        if (orderType === OrderableType.ORDER_GROUP) {
            return this.doPost(API + Endpoints.ORDER_GROUP_REDUCTION(orderId), {
                code: discountCode,
            })
        }
        return this.doPost(API + Endpoints.ORDER_REDUCTION(orderId), {
            code: discountCode,
        })
    }

    validateStarnetCard(venueId, data) {
        return this.doPost(API + Endpoints.VALIDATE_STARNET_CARD(venueId), data)
    }

    validateTopupableVoucher(venueId, data) {
        return this.doPost(API + Endpoints.VALIDATE_TOPUPABLE_VOUCHER(venueId), data)
    }

    validateBasketAndUpsell(basketEntries) {
        return this.doPost(API + Endpoints.VALIDATE_BASKET, { order_baskets: basketEntries })
    }

    createOrder(eventId, products, tip = 0, message = null, tableNumber = null) {
        return this.doPost(API + Endpoints.ORDER(), {
            event_id: eventId,
            products,
            tip: divideByHundred(tip),
            message,
            table_number: tableNumber,
        })
    }

    createGroupOrder(groupId, orders, tip = 0) {
        return this.doPost(API + Endpoints.ORDER_GROUP(), {
            event_group_id: groupId,
            orders,
            tip,
        })
    }

    createPayment(orderId, type, values) {
        if (type === OrderableType.ORDER_GROUP) {
            return this.doPost(API + Endpoints.PAYMENT_GROUP(orderId), values)
        }
        return this.doPost(API + Endpoints.PAYMENT(orderId), values)
    }

    cancelPayment(orderId, type) {
        if (type === OrderableType.ORDER_GROUP) {
            return this.doDelete(API + Endpoints.PAYMENT_GROUP(orderId))
        }
        return this.doDelete(API + Endpoints.PAYMENT(orderId))
    }

    getCredits() {
        return this.doGet(API + Endpoints.CREDITS())
    }

    getCreditsForVenue(venueId, params) {
        return this.doGet(API + Endpoints.CREDITS(venueId), params)
    }

    getCreditPurchaseOptionsForVenue(venueId) {
        return this.doGet(API + Endpoints.CREDIT_PURCHASE_OPTIONS(venueId))
    }

    getCreditPurchasePspsForVenue(venueId) {
        return this.doGet(API + Endpoints.CREDIT_PURCHASE_PSPS(venueId))
    }

    getCardTopupOptions(venueId, cardType) {
        return this.doGet(API + Endpoints.TOPUP_STARNET_CARD_OPTIONS(venueId, cardType))
    }

    createStarnetTopup(venueId, values) {
        return this.doPost(API + Endpoints.TOPUP_STARNET_CARD_PURCHASE_CREATE(venueId), values)
    }

    getStarnetTopupPurchaseById(purchaseId) {
        return this.doGet(API + Endpoints.TOPUP_STARNET_CARD_PURCHASE_BY_ID(purchaseId))
    }

    getCardTopupPsps(venueId) {
        return this.doGet(API + Endpoints.TOPUP_STARNET_CARD_PSPS(venueId))
    }

    createStarnetTopupPurchasePayment(topupPurchaseId, values) {
        return this.doPost(API + Endpoints.TOPUP_STARNET_CARD_PAYMENT_CREATE(topupPurchaseId), values)
    }

    getCreditPurchaseById(purchaseId) {
        return this.doGet(API + Endpoints.CREDIT_PURCHASE_BY_ID(purchaseId))
    }

    createCreditPurchaseForVenue(venueId, values) {
        return this.doPost(API + Endpoints.CREDIT_PURCHASE_CREATE(venueId), values)
    }

    createCreditPurchasePayment(creditPurchaseId, values) {
        return this.doPost(API + Endpoints.CREDIT_PURCHASE_PAYMENT(creditPurchaseId), values)
    }

    getVoucherPurchaseById(purchaseId) {
        return this.doGet(API + Endpoints.VOUCHER_PURCHASE_BY_ID(purchaseId))
    }

    getVoucherTopupOptions(venueId) {
        return this.doGet(API + Endpoints.TOPUP_VOUCHER_OPTIONS(venueId))
    }

    getVoucherTopupPsps(venueId) {
        return this.doGet(API + Endpoints.TOPUP_VOUCHER_PSPS(venueId))
    }

    createVoucherTopup(venueId, values) {
        return this.doPost(API + Endpoints.TOPUP_VOUCHER_PURCHASE_CREATE(venueId), values)
    }

    createVoucherTopupPurchasePayment(topupPurchaseId, values) {
        return this.doPost(API + Endpoints.TOPUP_VOUCHER_PAYMENT_CREATE(topupPurchaseId), values)
    }

    getVoucherTopupPurchaseById(purchaseId) {
        return this.doGet(API + Endpoints.TOPUP_VOUCHER_PURCHASE_BY_ID(purchaseId))
    }

    claimVoucher(voucher, data) {
        return this.doPut(API + Endpoints.VOUCHER(voucher), data)
    }

    rating(venueId, values) {
        return this.doPost(API + Endpoints.RATING(venueId), values)
    }

    optinConsent(optinId, values) {
        return this.doPost(API + Endpoints.OPTIN_CONSENT(optinId), values)
    }

    getCompetitionById(id) {
        return this.doGet(API + Endpoints.COMPETITION(id))
    }

    submitCompetition(id, values) {
        return this.doPatch(API + Endpoints.COMPETITION(id), values)
    }

    loyaltyGetProgram(venueId) {
        return this.doGet(API + Endpoints.LOYALTY_VENUE(venueId))
    }

    loyaltyGetContact(venueId) {
        return this.doGet(API + Endpoints.LOYALTY_USER(venueId))
    }

    loyaltyActivateContact(venueId, values) {
        return this.doPost(API + Endpoints.LOYALTY_CONTACT_ACTIVATION(venueId), values)
    }

    loyaltyVerifyEmail(venueId, values) {
        return this.doPost(API + Endpoints.LOYALTY_VERIFY_EMAIL(venueId), values)
    }

    doGet(endpoint, params = null) {
        return new Promise((resolve, reject) => {
            this.axiosInstance
                .get(endpoint, { params })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    if (error?.response) {
                        setTag('response-code', error.response.status)
                        setTag('response-message', error.response.statusText)
                    }
                    setTag('error-code', error.code)
                    setTag('error-message', error.message)
                    captureException(error)

                    if (error?.response) {
                        reject({
                            message: error.response.data?.message || 'An error occurred',
                            status: error.response.status,
                            response: error.response,
                        })
                    } else {
                        reject({
                            message: 'An error occurred',
                            response: false,
                        })
                    }
                })
        })
    }

    doPost(endpoint, data) {
        return new Promise((resolve, reject) => {
            this.axiosInstance
                .post(endpoint, data)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    if (error?.response) {
                        setTag('response-code', error.response.status)
                        setTag('response-message', error.response.statusText)
                    }
                    setTag('error-code', error.code)
                    setTag('error-message', error.message)
                    captureException(error)

                    if (error?.response) {
                        reject({
                            message: error.response.data?.message || error.response.message || 'An error occurred',
                            status: error.response.status,
                            response: error.response,
                        })
                    } else {
                        reject({
                            message: 'An error occurred',
                            response: false,
                        })
                    }
                })
        })
    }

    doPut(endpoint, data) {
        return new Promise((resolve, reject) => {
            this.axiosInstance({
                url: endpoint,
                method: 'put',
                data,
            })
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        resolve(response.data)
                    } else {
                        reject()
                    }
                })
                .catch((error) => {
                    if (error?.response) {
                        setTag('response-code', error.response.status)
                        setTag('response-message', error.response.statusText)
                    }
                    setTag('error-code', error.code)
                    setTag('error-message', error.message)
                    captureException(error)

                    if (error?.response) {
                        reject({
                            message: error.response.data?.message || 'An error occurred',
                            status: error.response.status,
                            response: error.response,
                        })
                    } else {
                        reject({
                            message: 'An error occurred',
                            response: false,
                        })
                    }
                })
        })
    }

    doPatch(endpoint, data) {
        return new Promise((resolve, reject) => {
            this.axiosInstance({
                url: endpoint,
                method: 'patch',
                data,
            })
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        resolve(response.data)
                    } else {
                        reject()
                    }
                })
                .catch((error) => {
                    if (error?.response) {
                        setTag('response-code', error.response.status)
                        setTag('response-message', error.response.statusText)
                    }
                    setTag('error-code', error.code)
                    setTag('error-message', error.message)
                    captureException(error)

                    if (error?.response) {
                        reject({
                            message: error.response.data?.message || 'An error occurred',
                            status: error.response.status,
                            response: error.response,
                        })
                    } else {
                        reject({
                            message: 'An error occurred',
                            response: false,
                        })
                    }
                })
        })
    }

    doDelete(endpoint) {
        return new Promise((resolve, reject) => {
            this.axiosInstance
                .delete(endpoint)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    if (error?.response?.status !== 412) {
                        if (error.response) {
                            setTag('response-code', error.response.status)
                            setTag('response-message', error.response.statusText)
                        }
                        setTag('error-code', error.code)
                        setTag('error-message', error.message)
                        captureException(error)
                    }

                    if (error?.response) {
                        reject({
                            message: error.response.data?.message || 'An error occurred',
                            status: error.response.status,
                            response: error.response,
                        })
                    } else {
                        reject({
                            message: 'An error occurred',
                            response: false,
                        })
                    }
                })
        })
    }
}

const billySDK = new BILLYSDK()

export { AuthMethods, billySDK as default, InitialFormValues, ValidationSchemas }
