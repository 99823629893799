import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import './multiswitch.scss'

interface IMultiSwitchProperties {
    items: Array<{ id: string | number; name?: ReactNode }>
    active: number
    onChange: (index: number) => void
}

const MultiSwitch = ({ items = [], active = 0, onChange }: IMultiSwitchProperties) => {
    const { t } = useTranslation()

    if (items.length === 0) return null

    return (
        <div className='c-multiswitch--wrapper'>
            <div className='inner'>
                <span
                    className='c-multiswitch--pill'
                    style={{ width: `${100 / items.length}%`, left: `${active * (100 / items.length)}%` }}
                />
                {items.map((item, index) => (
                    <button
                        key={item.id}
                        className={classNames('c-multiswitch--button', { active: index === active })}
                        onClick={() => {
                            onChange?.(index)
                        }}>
                        <span>{item.name ?? t('category.default.label')}</span>
                    </button>
                ))}
            </div>
        </div>
    )
}

export default MultiSwitch
