enum BrandType {
    BILLY = 'billy',
    BEES = 'bees',
    TADA = 'tada',
    QBH = 'qbh',
    CROUSTICO = 'croustico',
    KINEPOLIS_SING_CITY = 'kinepolis-singcity',
}

export default BrandType
