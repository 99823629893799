import { REMOVE_PENDING_ORDER, SET_PENDING_ORDER } from '../actions/ordersActions'

export default function ordersReducer(state = {}, { type, payload }) {
    // NOSONAR
    switch (type) {
        case SET_PENDING_ORDER:
            state = {
                ...state,
                pendingOrder: {
                    id: payload.id,
                    path: payload.path,
                    eventId: payload.eventId,
                    type: payload.type,
                },
            }

            return state

        case REMOVE_PENDING_ORDER:
            if (state.pendingOrder) {
                state = {
                    ...state,
                    pendingOrder: undefined,
                }
            }

            return state

        default:
            return state
    }
}
