import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'

import { ThemeContext } from '../../context/ThemeContextProvider'

function ExtendedTracking() {
    const { theme } = useContext(ThemeContext)

    if (theme?.customTags) {
        return (
            <Helmet>
                <script>{theme.customTags}</script>
            </Helmet>
        )
    }
    return null
}

export default ExtendedTracking
