import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import { motion } from 'framer-motion'
import * as Yup from 'yup'

import { divideByHundred } from '../../constants/helpers'
import { setCashlessCard } from '../../redux/actions/actionBuilders'
import BillySDK from '../../sdk/sdk'
import Currency from '../currency/Currency.tsx'
import Input from '../forms/elements/Input'

import './starnetcard.scss'

function StarnetCard({ order, venueId, onSuccess, onTopUpCard }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const savedCashlessCards = useSelector((state) => state.cashlessCard)
    const [hasSufficientFunds, setHasSufficientFunds] = useState(undefined)
    const [cardNumber, setCardnumber] = useState(undefined)
    const [cardBalance, setCardbalance] = useState(undefined)

    const calculateRemainingBalance = () => divideByHundred(cardBalance - order.totalPriceInt)
    const calculateShortage = () => divideByHundred(order.totalPriceInt - cardBalance)

    useEffect(() => {
        if (typeof cardBalance !== 'undefined') {
            setHasSufficientFunds(cardBalance >= order.totalPriceInt)
        }
    }, [cardBalance])

    if (hasSufficientFunds === true && cardNumber) {
        return (
            <motion.div
                layout='position'
                className='c-starnet-card'>
                <motion.div
                    initial={{ y: 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}>
                    <h3>{t('cashlesscard.modal.title.sufficient_funds')}</h3>
                    <div>
                        <Trans
                            i18nKey='cashlesscard.modal.body.sufficient_funds'
                            values={{ balance: '<0></0>', remaining: '<1></1>' }}
                            components={[
                                <Currency amount={divideByHundred(cardBalance)} />,
                                <Currency amount={calculateRemainingBalance()} />,
                            ]}
                        />
                    </div>
                    <button
                        type='button'
                        className='c-button c-button-dark'
                        onClick={() => onSuccess(cardNumber)}>
                        {t('cashlesscard.modal.cta.pay')}
                    </button>
                </motion.div>
            </motion.div>
        )
    }

    if (hasSufficientFunds === false) {
        return (
            <motion.div
                layout='position'
                className='c-starnet-card'>
                <motion.div
                    initial={{ y: 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}>
                    <h3>{t('cashlesscard.modal.title.insufficient_funds')}</h3>
                    <div>
                        <Trans
                            i18nKey='cashlesscard.modal.body.insufficient_funds'
                            values={{ shortage: '<0></0>' }}
                            components={[<Currency amount={calculateShortage()} />]}
                        />
                    </div>
                    <button
                        type='button'
                        className='c-button c-button-dark'
                        onClick={() => onTopUpCard(cardNumber)}>
                        {t('cashlesscard.modal.cta.topup_card')}
                    </button>
                </motion.div>
            </motion.div>
        )
    }

    return (
        <motion.div className='c-starnet-card'>
            <h3>{t('cashlesscard.modal.title')}</h3>
            <Formik
                initialValues={{
                    card: savedCashlessCards.starnet?.cardNumber || '',
                }}
                validationSchema={Yup.object().shape({
                    card: Yup.string().required(t('validation.email.required')),
                })}
                onSubmit={(values, actions) => {
                    BillySDK.validateStarnetCard(venueId, { card: values.card })
                        .then((response) => {
                            setCardbalance(response.balance)
                            setCardnumber(values.card)
                            actions.setSubmitting(false)
                            dispatch(
                                setCashlessCard({
                                    cardType: 'starnet',
                                    cardNumber: values.card,
                                })
                            )
                        })
                        .catch((error) => {
                            actions.setSubmitting(false)
                            if (error.status === 422) {
                                for (const key in error.response.data.errors) {
                                    if (error.response.data.errors.hasOwnProperty(key)) {
                                        const value = error.response.data.errors[key]
                                        actions.setFieldError(key, value[0], false)
                                    }
                                }
                            } else if (error.status === 404) {
                                actions.setFieldError(
                                    'card',
                                    error.message
                                        ? error.message
                                        : `${t('error.fatal.default.title')} ${t('error.fatal.default.body')}`,
                                    false
                                )
                            } else {
                                actions.setFieldError(
                                    'card',
                                    `${t('error.fatal.default.title')} ${t('error.fatal.default.body')}`,
                                    false
                                )
                            }
                        })
                }}>
                {({ handleChange, handleBlur, isSubmitting }) => (
                    <Form>
                        <Field
                            component={Input}
                            name='card'
                            id='card'
                            type='text'
                            label={t('cashlesscard.modal.input.label')}
                            placeHolder={t('cashlesscard.modal.input.placeholder')}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            onBlur={handleBlur}
                            maxLength='50'
                        />
                        <div>
                            <button
                                disabled={isSubmitting}
                                type='submit'
                                className='c-button c-button-dark'>
                                {t('cashlesscard.modal.cta.validate_card_number')}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </motion.div>
    )
}

export default StarnetCard
