import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaPlus } from 'react-icons/fa'
import classNames from 'classnames'

import { divideByHundred } from '../../constants/helpers'
import ProductPrice from '../02_molecules/ProductPrice/ProductPrice.tsx'
import CircularButton from '../CircularButton/CircularButton.tsx'
import QuantitySelector from '../QuantitySelector/QuantitySelector.tsx'

function Recommendation({ product, onRecommendationClick }) {
    const [isActive, setIsActive] = useState(false)
    const [amount, setAmount] = useState(1)
    const { t } = useTranslation()

    const onActivateRecommendation = useCallback(() => setIsActive(true), [setIsActive])
    const onQuantityChange = useCallback((e) => setAmount((prev) => Math.max(prev + e, 0)), [setAmount])

    useEffect(() => {
        if (isActive) {
            if (amount === 0) {
                setIsActive(false)
                setAmount(1)
            }

            onRecommendationClick({ product, amount })
        }
    }, [amount, isActive])

    return (
        <div className={classNames('c-recommendation', { 'is-active': isActive })}>
            {product.images?.small && (
                <div className='c-recommendation-image'>
                    <img
                        loading='lazy'
                        src={product.images.small}
                    />
                </div>
            )}

            <div className='c-recommendation-content'>
                <p className='title u-text-bold'>{product.name}</p>

                <ProductPrice price={divideByHundred(product.price_int)} />
            </div>

            <div className={classNames('c-recommendation-controls', { 'c-recommendation-controls--active': isActive })}>
                {isActive ? (
                    <QuantitySelector
                        type='mini'
                        className='recommendation-stepper'
                        amount={amount}
                        onQuantityChange={onQuantityChange}
                    />
                ) : (
                    <CircularButton
                        aria-label={t('general.button.add.label')}
                        className='c-recommendation-button'
                        onClick={onActivateRecommendation}>
                        <FaPlus />
                    </CircularButton>
                )}
            </div>
        </div>
    )
}

export default Recommendation
