import { CSSProperties, FC, ReactNode, useCallback } from 'react'
import classNames from 'classnames'
import { HTMLMotionProps, motion, Transition } from 'framer-motion'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

import '../../../style/components/buttons.scss'
import './button.scss'

// Common props
export interface IBaseButtonProperties extends HTMLMotionProps<'button'> {
    onClick?: () => void
    className?: string
    type?: 'button' | 'submit' | 'reset'
    variant?: 'default' | 'ghost' | 'link' | 'dark' | 'progress'
    colorVariant?: string
    disableChildSpacing?: boolean
    leftChild?: ReactNode
    rightChild?: ReactNode
    transition?: Transition
    disabled?: boolean
    style?: CSSProperties
}

// only label
interface ILabelButton extends Omit<IBaseButtonProperties, 'children'> {
    label: string
    children?: never
}
// only children
interface IChildrenButton extends Omit<IBaseButtonProperties, 'label'> {
    label?: never
    children: ReactNode
}

type IButtonProperties = ILabelButton | IChildrenButton

const Button: FC<IButtonProperties> = ({
    onClick = () => {},
    className,
    type = 'button',
    label = 'button',
    variant = 'default',
    colorVariant = 'white',
    disableChildSpacing = false,
    leftChild,
    rightChild,
    initial,
    animate,
    exit,
    transition,
    disabled = false,
    children,
    style,
    ...properties
}) => {
    const classes = classNames('c-button', className, `c-button-color-variant--${colorVariant}`, {
        'c-button--ghost': variant === 'ghost',
        'c-button-link': variant === 'link',
        'c-button-dark': variant === 'dark',
        'c-button-progress': variant === 'progress',
        'c-button--has-children': leftChild !== undefined || rightChild !== undefined,
    })

    const handleClick = useCallback(() => {
        onClick()
    }, [onClick])

    let itemLeft: ReactNode | null = <span>{leftChild}</span>
    let itemRight: ReactNode | null = <span>{rightChild}</span>

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (leftChild && !rightChild && disableChildSpacing) itemRight = null
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!leftChild && rightChild && disableChildSpacing) itemLeft = null

    return (
        <motion.button
            type={type}
            className={classes}
            onClick={handleClick}
            onMouseDown={(event) => {
                event.preventDefault()
            }}
            initial={initial}
            animate={animate}
            exit={exit}
            transition={transition}
            disabled={disabled}
            style={style}
            {...properties}>
            {itemLeft}
            {isNotNullOrUndefined(children) ? children : <span className='label'>{label}</span>}
            {itemRight}
        </motion.button>
    )
}

export default Button
