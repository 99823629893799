/* eslint-disable @typescript-eslint/no-explicit-any */
import { Middleware } from 'redux'
import { isDateExpired } from 'src/constants/helpers'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

// TODO: create basket interface & convert redux implementation to TS

interface IBasketEntry {
    [key: string]: any
}

// eslint-disable-next-line unicorn/consistent-function-scoping
const filterExpiredBasketsMiddleware: Middleware = () => (next) => (action) => {
    if (action.type === 'persist/REHYDRATE' && isNotNullOrUndefined(action.payload)) {
        const state: any = action.payload

        const filteredBasket = Object.entries(state.basket).reduce(
            (accumulator: IBasketEntry, [key, value]: [string, any]) => {
                if (
                    isNotNullOrUndefined(value) &&
                    isNotNullOrUndefined(value.expiresAt) &&
                    isNotNullOrUndefined(value.eventId)
                ) {
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    if (!isDateExpired(value.expiresAt ?? '')) {
                        accumulator[key] = value
                    }
                } else {
                    accumulator[key] = value
                }
                return accumulator
            },
            {}
        )

        action.payload.basket = filteredBasket
    }
    return next(action)
}

export default filterExpiredBasketsMiddleware
