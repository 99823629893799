import { useCallback } from 'react'
import classNames from 'classnames'

import './rating.scss'

interface IRatingButtonProperties {
    active?: boolean
    value?: number
    mood?: 'negative' | 'neutral' | 'positive'
    imageId?: string
    disabled?: boolean
    onClick?: (value: number) => void
}

const RatingButton = ({
    active = false,
    value = 1,
    mood = 'positive',
    imageId = '1',
    disabled = false,
    onClick = () => {},
}: IRatingButtonProperties) => {
    const handleClick = useCallback(() => {
        if (!disabled) {
            onClick?.(value)
        }
    }, [disabled, value, onClick])

    return (
        <button
            className={classNames('c-rating-button', {
                'c-rating-button--active': active,
                'c-rating-button--disabled': disabled,
            })}
            onClick={handleClick}>
            <img
                src={`/rating/rating_${mood}_${imageId}.png`}
                alt='mood'
            />
        </button>
    )
}

export default RatingButton
