import { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { MotionConfig } from 'framer-motion'
import { PersistGate } from 'redux-persist/lib/integration/react'

import './i18next-config'

import initSentry from './components/00_fundament/Sentry.ts'
import initVitePreloadListener from './components/00_fundament/VitePreloadListener.ts'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import LoadingScreen from './containers/loading/LoadingScreen'
import BrandContextProvider from './context/BrandContextProvider.tsx'
import { persistor, store } from './redux/store'
import App from './App.tsx'

import './style/index.scss'
import './style/animations.scss'
import './style/fontface.scss'
import './style/normalize.scss'

const queryClient = new QueryClient()
const container = document.getElementById('root')
const root = createRoot(container)

initSentry()
initVitePreloadListener()

root.render(
    <QueryClientProvider client={queryClient}>
        <Provider store={store}>
            <MotionConfig reducedMotion='never'>
                <HelmetProvider>
                    <BrandContextProvider>
                        <PersistGate
                            loading={<LoadingScreen />}
                            persistor={persistor}>
                            <ErrorBoundary>
                                <Suspense fallback={<LoadingScreen />}>
                                    <App />
                                </Suspense>
                            </ErrorBoundary>
                        </PersistGate>
                    </BrandContextProvider>
                </HelmetProvider>
            </MotionConfig>
        </Provider>
    </QueryClientProvider>
)
