import { useContext } from 'react'
import classNames from 'classnames'

import { KioskContext } from '../../context/KioskContextProvider'

import './rubberband.scss'

function RubberBand({ transparent, small, setheight, children }) {
    const { kioskMode } = useContext(KioskContext)

    if (kioskMode === true) {
        return children
    }

    return (
        <section
            className={classNames('c-rubberband', {
                transparent,
                small,
                setheight,
            })}>
            {children}
        </section>
    )
}

export default RubberBand
