import { useCallback, useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { Field, Form, Formik } from 'formik'
import { motion } from 'framer-motion'
import * as Yup from 'yup'

import { divideByHundred } from '../../constants/helpers'
import { KioskContext } from '../../context/KioskContextProvider'
import BillySDK from '../../sdk/sdk'
import Currency from '../currency/Currency.tsx'
import Input from '../forms/elements/Input'

import './topupablevoucher.scss'

function TopupableVoucher({ order, onSuccess, onAbort, onTopUpVoucher }) {
    const { t } = useTranslation()
    const { kioskMode } = useContext(KioskContext)
    const [voucherCode, setVoucherCode] = useState(undefined)
    const [voucherBalance, setVoucherBalance] = useState(undefined)
    const [isVoucherTopupAble, setIsVoucherTopupAble] = useState(undefined)
    const [hasSufficientBalance, setHasSufficientBalance] = useState(undefined)

    const calculateRemainingBalance = () => divideByHundred(voucherBalance - order.totalPriceInt)
    const calculateShortage = () => divideByHundred(order.totalPriceInt - voucherBalance)

    const voucherValidationMutation = useMutation({
        retry: 1,
        refetchOnWindowFocus: false,
        mutationFn: (code) => BillySDK.validateTopupableVoucher(order?.venue?.id, code),
    })

    useEffect(() => {
        if (typeof voucherBalance !== 'undefined') {
            setHasSufficientBalance(voucherBalance >= order.totalPriceInt)
        }
    }, [voucherBalance])

    const onSubmit = useCallback((values, actions) => {
        voucherValidationMutation.mutate(values, {
            onSuccess: (response) => {
                setVoucherCode(values.code)
                setVoucherBalance(response.balance)
                // disable vouchertopup for kiosk
                setIsVoucherTopupAble(kioskMode === true ? false : response.is_topupable)
            },
            onError: (error) => {
                if (error.status === 422) {
                    for (const key in error.response.data.errors) {
                        if (error.response.data.errors.hasOwnProperty(key)) {
                            const value = error.response.data.errors[key]
                            actions.setFieldError(key, value[0], false)
                        }
                    }
                } else if (error.status === 404 || error.status === 403) {
                    actions.setFieldError(
                        'code',
                        error.message
                            ? error.message
                            : `${t('error.fatal.default.title')} ${t('error.fatal.default.body')}`,
                        false
                    )
                } else {
                    actions.setFieldError(
                        'code',
                        `${t('error.fatal.default.title')} ${t('error.fatal.default.body')}`,
                        false
                    )
                }
            },
            onSettled: () => actions.setSubmitting(false),
        })
    }, [])

    if (hasSufficientBalance === true) {
        return (
            <motion.div
                layout='position'
                className='c-starnet-card'>
                <motion.div
                    initial={{ y: 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}>
                    <h3>{t('topupablevoucher.modal.title')}</h3>
                    <div>
                        <Trans
                            i18nKey='topupablevoucher.modal.body.sufficient_funds'
                            values={{ balance: '<0></0>', remaining: '<1></1>' }}
                            components={[
                                <Currency amount={divideByHundred(voucherBalance)} />,
                                <Currency amount={calculateRemainingBalance()} />,
                            ]}
                        />
                    </div>
                    <button
                        type='button'
                        className='c-button c-button-dark'
                        onClick={() => onSuccess(voucherCode)}>
                        {t('cashlesscard.modal.cta.pay')}
                    </button>
                </motion.div>
            </motion.div>
        )
    }

    if (hasSufficientBalance === false) {
        return (
            <motion.div
                layout='position'
                className='c-starnet-card'>
                <motion.div
                    initial={{ y: 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}>
                    <h3>{t('topupablevoucher.modal.title.insufficient_funds')}</h3>
                    <div>
                        {isVoucherTopupAble ? (
                            <Trans
                                i18nKey='topupablevoucher.modal.body.insufficient_funds_topupable'
                                values={{ shortage: '<0></0>' }}
                                components={[<Currency amount={calculateShortage()} />]}
                            />
                        ) : (
                            <Trans
                                i18nKey='topupablevoucher.modal.body.insufficient_funds'
                                values={{ shortage: '<0></0>' }}
                                components={[<Currency amount={calculateShortage()} />]}
                            />
                        )}
                    </div>
                    {isVoucherTopupAble ? (
                        <button
                            type='button'
                            className='c-button c-button-dark'
                            onClick={() => onTopUpVoucher(voucherCode)}>
                            {t('topupablevoucher.modal.cta.topup_voucher')}
                        </button>
                    ) : (
                        <button
                            type='button'
                            className='c-button c-button-dark'
                            onClick={() => onAbort()}>
                            {t('button.close.cta')}
                        </button>
                    )}
                </motion.div>
            </motion.div>
        )
    }

    return (
        <motion.div className='c-topupablevoucher'>
            <h3>{t('topupablevoucher.modal.title')}</h3>
            <Formik
                initialValues={{
                    code: '',
                }}
                validationSchema={Yup.object().shape({
                    code: Yup.string().required(t('validation.email.required')),
                })}
                onSubmit={(values, actions) => onSubmit(values, actions)}>
                {({ handleChange, handleBlur, isSubmitting }) => (
                    <Form>
                        <Field
                            component={Input}
                            name='code'
                            id='code'
                            type='text'
                            label={t('topupablevoucher.modal.input.label')}
                            placeHolder={t('topupablevoucher.modal.input.placeholder')}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            onBlur={handleBlur}
                            maxLength='50'
                        />
                        <div>
                            <button
                                disabled={isSubmitting}
                                type='submit'
                                className='c-button c-button-dark'>
                                {t('topupablevoucher.modal.cta.validate_voucher_code')}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </motion.div>
    )
}

export default TopupableVoucher
